import * as ImagePicker from "expo-image-picker";
import Constants from "expo-constants";
import { Camera } from "expo-camera";
import React, { Component } from "react";
import {
  ActivityIndicator,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Text,
  View
} from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import { Avatar, CheckBox, Input } from "react-native-elements";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SafeAreaView from "react-native-safe-area-view";
import {
  fetchGetUser,
  fetchUpdateProfile,
  fetchCheckPseudo
} from "../actions/profile";
import { fetchDeleteUser } from "../actions/login";
import {
  CLEAR_PHOTO,
  CLEAR_PROFILE_VALIDATE,
  SET_BIRTHDATE,
  SET_DESCRIPTION,
  SET_FIRST_NAME,
  SET_GENDER,
  SET_LAST_NAME,
  SET_LOCATION_ID,
  SET_PHONE,
  SET_PHOTO_URI,
  SET_USER_HOBBIES,
  VALIDATE_PROFILE_INPUTS,
  SET_VALIDATE_FALSE,
  LOGOUT,
  SET_DROP_DOWN_ALERT_INFO,
  EDIT_PROFILE,
  SET_DATE_INVALID,
  VALIDATE_PSEUDO,
  CLEAR_VALIDATE_FIRST_NAME,
  VALIDATE_FIRST_NAME,
  CLEAR_VALIDATE_LAST_NAME,
  VALIDATE_LAST_NAME,
  CLEAR_VALIDATE_BIRTHDATE,
  VALIDATE_BIRTHDATE,
  CLEAR_VALIDATE_PHONE,
  VALIDATE_PHONE,
  SET_FLOOR,
  SET_APARTMENT_NUMBER,
  TOGGLE_CONFIRM_ADDRESS,
  SET_JOB,
  REGISTER_FOR_PUSH_NOTIF,
  SET_DROP_DOWN_ALERT_WARN,
  UPDATE_AUTOMATICALLY_HOBBIES,
  NOT_UPDATE_AUTOMATICALLY_HOBBIES,
  SET_BUILDING_ACCESS,
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_CONFIRMATION,
  VALIDATE_NEW_PASSWORD,
  VALIDATE_NEW_PASSWORD_CONFIRMATION,
  CLEAR_VALIDATE_NEW_PASSWORD,
  CLEAR_VALIDATE_NEW_PASSWORD_CONFIRMATION,
  RESET_DATA
} from "../actions/types";
import { ROLE_NEIGHBOR } from "../config/constants";
import Hobbies from "../components/Hobbies";
import ConfirmationModal from "../components/ConfirmationModal";
import { t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  PINK_COLOR,
  BLUE_COLOR,
  COLOR5
} from "../styles/commonStyles";
import ActionButton from "../components/actionButton/ActionButton";
import { TextInputMask } from "react-native-masked-text";
import { getImage } from "../assets/Images";
import {
  MaterialCommunityIcons,
  FontAwesome,
  MaterialIcons
} from "@expo/vector-icons";
const IMAGE_SIZE = 200;

class ProfileUpdate extends Component {
  static navigationOptions = {
    gestureEnabled: false
  };

  state = {
    needPushNotif: false,
    menuOpen: false,
    showConfirmationModal: false
  };

  componentDidMount() {
    const { dispatch, needUpdate = false } = this.props;

    dispatch({
      type: EDIT_PROFILE
    });

    if (needUpdate) {
      this.setState({
        needPushNotif: true
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      dispatch,
      fetchCheckPseudo,
      navigation,
      files,
      user,
      isValid,
      pseudoErrorMsg,
      firstnameErrorMsg,
      lastnameErrorMsg,
      birthdayErrorMsg,
      clearProfileValidate,
      isPseudoClearValidate,
      needPseudoCheck,
      pseudo,
      isFirstnameClearValidate,
      isLastnameClearValidate,
      isBirthdateClearValidate,
      isPhoneClearValidate,
      phoneErrorMsg,
      confirmAddressErrorMsg,
      needUpdate,
      deleteUserResult,
      passwordErrorMsg,
      isPasswordClearValidate,
      isPasswordConfirmationClearValidate
    } = this.props;
    const { needPushNotif } = this.state;

    const { navigate } = navigation;

    if (needUpdate && !prevProps.needUpdate) {
      this.setState({
        needPushNotif: true
      });
    }

    if (deleteUserResult && !prevProps.deleteUserResult) {
      await AsyncStorage.removeItem("emailOrPseudo");
      await AsyncStorage.removeItem("strategy");
      dispatch({
        type: RESET_DATA
      });
      navigation.navigate("LoadingScreen");
    }

    if (isPseudoClearValidate && !prevProps.isPseudoClearValidate) {
      dispatch({
        type: VALIDATE_PSEUDO
      });
    }
    if (!isPseudoClearValidate && prevProps.isPseudoClearValidate) {
      if (!pseudoErrorMsg && needPseudoCheck) {
        fetchCheckPseudo({ pseudo });
      } else if (pseudoErrorMsg) {
        this.pseudoInput && this.pseudoInput.shake();
        this.pseudoInput && this.pseudoInput.focus();
      }
    } else if (pseudoErrorMsg && !prevProps.pseudoErrorMsg) {
      this.pseudoInput && this.pseudoInput.shake();
      this.pseudoInput && this.pseudoInput.focus();
    }

    if (isFirstnameClearValidate && !prevProps.isFirstnameClearValidate) {
      dispatch({
        type: VALIDATE_FIRST_NAME
      });
    }

    if (!isFirstnameClearValidate && prevProps.isFirstnameClearValidate) {
      if (firstnameErrorMsg) {
        this.firstNameInput && this.firstNameInput.shake();
        this.firstNameInput && this.firstNameInput.focus();
      }
    }

    if (isLastnameClearValidate && !prevProps.isLastnameClearValidate) {
      dispatch({
        type: VALIDATE_LAST_NAME
      });
    }
    if (!isLastnameClearValidate && prevProps.isLastnameClearValidate) {
      if (lastnameErrorMsg) {
        this.lastNameInput && this.lastNameInput.shake();
        this.lastNameInput && this.lastNameInput.focus();
      }
    }

    if (isBirthdateClearValidate && !prevProps.isBirthdateClearValidate) {
      dispatch({
        type: VALIDATE_BIRTHDATE
      });
    }
    if (!isBirthdateClearValidate && prevProps.isBirthdateClearValidate) {
      if (birthdayErrorMsg) {
        this.birthdayInput && this.birthdayInput.getElement().focus();
      } else {
        if (this.birthdayInput.isValid()) {
          this.buildingAccessInput && this.buildingAccessInput.focus();
        } else {
          dispatch({
            type: SET_DATE_INVALID
          });
          this.birthdayInput && this.birthdayInput.getElement().focus();
        }
      }
    }

    if (isPhoneClearValidate && !prevProps.isPhoneClearValidate) {
      dispatch({
        type: VALIDATE_PHONE
      });
    }
    if (!isPhoneClearValidate && prevProps.isPhoneClearValidate) {
      if (phoneErrorMsg) {
        this.phoneInput && this.phoneInput.getElement().focus();
      } else {
        this.jobInput && this.jobInput.focus();
      }
    }

    if (!prevProps.confirmAddressErrorMsg && confirmAddressErrorMsg) {
      dispatch({
        type: SET_DROP_DOWN_ALERT_WARN,
        warn: t("profile:confirmaddresserror")
      });
    }

    if (files && prevProps.files !== files) {
      if (needPseudoCheck) {
        this.pseudoInput && this.pseudoInput.focus();
      } else {
        this.firstNameInput && this.firstNameInput.focus();
      }
    }

    if (isPasswordClearValidate && !prevProps.isPasswordClearValidate) {
      dispatch({
        type: VALIDATE_NEW_PASSWORD
      });
    }
    if (
      !isPasswordClearValidate &&
      prevProps.isPasswordClearValidate &&
      passwordErrorMsg
    ) {
      this.passwordInput?.shake();
      this.passwordInput?.focus();
    }
    if (
      isPasswordConfirmationClearValidate &&
      !prevProps.isPasswordConfirmationClearValidate
    ) {
      dispatch({
        type: VALIDATE_NEW_PASSWORD_CONFIRMATION
      });
    }
    // if (
    //   !isPasswordConfirmationClearValidate &&
    //   prevProps.isPasswordConfirmationClearValidate &&
    //   passwordConfirmationErrorMsg
    // ) {
    //   this.passwordConfirmationInput?.shake();
    //   this.passwordConfirmationInput?.focus();
    // }

    // Validate inputs
    if (clearProfileValidate && !prevProps.clearProfileValidate) {
      dispatch({
        type: VALIDATE_PROFILE_INPUTS
      });
    }

    if (isValid && !prevProps.isValid) {
      this.updateProfile();
    }

    if (isValid && user && prevProps.user !== user) {
      navigate("TabScreen");
      dispatch({
        type: SET_VALIDATE_FALSE
      });
      if (needPushNotif) {
        this.setState({
          needPushNotif: false
        });
        dispatch({
          type: REGISTER_FOR_PUSH_NOTIF
        });
      }
    }
  }

  showConfirmationModal = () => {
    this.setState({ showConfirmationModal: true });
  };

  onConfirmationModalClosed = () => {
    this.setState({ showConfirmationModal: false });
  };

  deleteUser = () => {
    this.props.fetchDeleteUser();
  };

  updateProfile = () => {
    const {
      fetchUpdateProfile,
      firstname,
      lastname,
      gender,
      address,
      zipCode,
      city,
      photoUri,
      phone,
      description,
      hobbiesIds,
      birthdate,
      files,
      floor,
      apartmentNumber,
      job,
      updateHobbies,
      buildingAccess,
      newpassword
    } = this.props;

    fetchUpdateProfile({
      photoUri: photoUri && !files.length ? photoUri : null,
      firstname,
      lastname,
      gender,
      address,
      zipCode,
      files,
      city,
      phone,
      description,
      birthdate,
      floor,
      apartmentNumber,
      job,
      updateHobbies,
      hobbiesIds: [...hobbiesIds],
      buildingAccess,
      newpassword
    });
  };

  clearProfileValidate = () => {
    this.props.dispatch({
      type: CLEAR_PROFILE_VALIDATE
    });
  };

  _pickImage = async () => {
    if (Constants.platform.ios) {
      const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
      if (status !== "granted") {
        this.props.dispatch({
          type: SET_DROP_DOWN_ALERT_INFO,
          info: "photosinfo"
        });
        return;
      }
    }
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: "Images",
      // allowsEditing: true,
      aspect: [1, 1],
      quality: 0.1,
      base64: true
    });

    if (!result.cancelled) {
      if (Platform.OS === "web") {
        const files = [];
        const name = "" + Date.now();
        files.push({
          name,
          base64: result.uri
        });
        this.props.dispatch({
          type: SET_PHOTO_URI,
          value: result.uri,
          files
        });
      } else {
        const files = [];
        const filename = result.uri.split("/").pop();
        const name = filename
          ? filename.substring(0, filename.lastIndexOf("."))
          : null;
        files.push({
          name,
          base64: "data:image/jpeg;base64," + result.base64
        });
        this.props.dispatch({
          type: SET_PHOTO_URI,
          value: result.uri,
          files
        });
      }
    }
  };

  onChangeLocation = value => {
    this.props.dispatch({
      type: SET_LOCATION_ID,
      value
    });
  };

  cancel = () => {
    const { dispatch, navigation } = this.props;
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      dispatch({
        type: LOGOUT
      });
    }
  };

  render() {
    const {
      red,
      bgColor1,
      bgWhite,
      color2,
      flex1,
      darkgrey,
      alignItemsCenter,
      justifyContentCenter,
      fs12,
      fs16,
      fs18,
      fs20,
      row,
      p10,
      p20,
      ml10,
      mt10,
      mb10,
      mb20,
      mv10,
      mv20,
      font,
      fontBold,
      shadowGrey,
      w100p,
      ml5,
      mt5
    } = commonStyles;

    const {
      dispatch,
      navigation,
      firstname,
      lastname,
      birthdate,
      address,
      zipCode,
      city,
      countryCode,
      phone,
      description,
      hobbiesIds,
      // pseudoErrorMsg,
      firstnameErrorMsg,
      lastnameErrorMsg,
      birthdayErrorMsg,
      isFetching,
      photoUri,
      role,
      firstLogin,
      gender,
      phoneErrorMsg,
      floor,
      apartmentNumber,
      job,
      building,
      residence,
      district,
      confirmAddress,
      confirmAddressErrorMsg,
      updateHobbies,
      buildingAccess,
      strategy,
      newpassword,
      newpasswordconfirmation,
      passwordErrorMsg,
      passwordConfirmationErrorMsg
    } = this.props;

    const { menuOpen } = this.state;

    return (
      <KeyboardAvoidingView
        {...(Platform.OS === "ios" && { behavior: "padding" })}
        style={[flex1, bgColor1]}
      >
        <ConfirmationModal
          description={t("settings:confirmdelete")}
          onClosed={this.onConfirmationModalClosed}
          title={t("menu:confirmation")}
          showConfirmationModal={this.state.showConfirmationModal}
          onConfirmation={this.deleteUser}
          isFetching={isFetching}
        />
        {isFetching ? (
          <ActivityIndicator
            style={[flex1, justifyContentCenter]}
            size="large"
            color={COLOR2}
          />
        ) : (
          <SafeAreaView style={[flex1]}>
            <ScrollView
              style={[flex1]}
              contentContainerStyle={{ paddingBottom: 90 }}
              showsVerticalScrollIndicator={false}
            >
              <View
                style={[
                  w100p,
                  p10,
                  bgWhite,
                  mt10,
                  mb20,
                  shadowGrey,
                  alignItemsCenter
                ]}
              >
                <Text style={[fontBold, color2, fs20, mv20]}>
                  {t("profile:identity")}
                </Text>

                <View style={[row, mb20, justifyContentCenter, w100p]}>
                  <Avatar
                    rounded
                    size={IMAGE_SIZE}
                    title={
                      firstname ? firstname.toUpperCase().substr(0, 2) : "?"
                    }
                    source={
                      Boolean(photoUri)
                        ? {
                            uri: photoUri
                          }
                        : firstname
                        ? undefined
                        : gender === "M"
                        ? getImage("avatar")
                        : getImage("avatarF")
                    }
                    onPress={() => {
                      Keyboard.dismiss();
                      navigation.navigate("CameraScreen", {
                        showGridParam: true,
                        type: SET_PHOTO_URI,
                        cameraTypeParam: Camera.Constants.Type.front,
                        origin: "ProfileUpdateScreen"
                      });
                    }}
                  />
                  <ActionButton
                    buttonColor={COLOR2}
                    renderIcon={() => (
                      <MaterialCommunityIcons
                        name="dots-horizontal"
                        size={34}
                        color="white"
                      />
                    )}
                    verticalOrientation="down"
                    size={44}
                    offsetX={10}
                    offsetY={0}
                    degrees={90}
                    spacing={10}
                  >
                    {Boolean(photoUri) && (
                      <ActionButton.Item
                        buttonColor={"red"}
                        title={t("button:delete")}
                        onPress={() => dispatch({ type: CLEAR_PHOTO })}
                        textStyle={font}
                        textHeight={24}
                      >
                        <MaterialCommunityIcons
                          name={"delete-forever"}
                          size={34}
                          color="white"
                        />
                      </ActionButton.Item>
                    )}
                    <ActionButton.Item
                      buttonColor={COLOR2}
                      title={t("button:albums")}
                      onPress={this._pickImage}
                      textStyle={font}
                      textHeight={24}
                    >
                      <FontAwesome name="image" size={28} color="white" />
                    </ActionButton.Item>
                    <ActionButton.Item
                      buttonColor={COLOR2}
                      title={t("button:camera")}
                      onPress={() => {
                        Keyboard.dismiss();
                        navigation.navigate("CameraScreen", {
                          showGridParam: true,
                          type: SET_PHOTO_URI,
                          cameraTypeParam: Camera.Constants.Type.front,
                          origin: "ProfileUpdateScreen"
                        });
                      }}
                      textStyle={font}
                      textHeight={24}
                    >
                      <MaterialIcons
                        name="camera-alt"
                        size={34}
                        color="white"
                      />
                    </ActionButton.Item>
                  </ActionButton>
                </View>
                {/* {role === ROLE_NEIGHBOR && (
                <Input
                  inputStyle={[
                    Platform.OS === "ios" ? ml10 : null,
                    darkgrey,
                    font
                  ]}
                  onChangeText={value => dispatch({ type: SET_PSEUDO, value })}
                  onBlur={() => {
                    dispatch({
                      type: CLEAR_VALIDATE_PSEUDO
                    });
                  }}
                  placeholder={t("profile:pseudo")}
                  keyboardType="default"
                  autoCorrect={false}
                  autoCapitalize="none"
                  keyboardAppearance="light"
                  returnKeyType="next"
                  value={pseudo}
                  // Strange input is null once in 2
                  ref={input => (this.pseudoInput = input)}
                  errorMessage={pseudoErrorMsg}
                />
              )} */}
                <View style={[mv10, row, alignItemsCenter]}>
                  <CheckBox
                    title={t("profile:male")}
                    textStyle={[{ color: BLUE_COLOR }, fontBold]}
                    checkedColor={BLUE_COLOR}
                    uncheckedColor={BLUE_COLOR}
                    checkedIcon="dot-circle-o"
                    uncheckedIcon="circle-o"
                    checked={gender === "M"}
                    onPress={() => dispatch({ type: SET_GENDER, value: "M" })}
                  />
                  <CheckBox
                    title={t("profile:female")}
                    textStyle={[{ color: PINK_COLOR }, fontBold]}
                    checkedColor={PINK_COLOR}
                    uncheckedColor={PINK_COLOR}
                    checkedIcon="dot-circle-o"
                    uncheckedIcon="circle-o"
                    checked={gender === "F"}
                    onPress={() => dispatch({ type: SET_GENDER, value: "F" })}
                  />
                </View>
                <Input
                  inputStyle={[
                    Platform.OS === "ios" ? ml10 : null,
                    darkgrey,
                    font
                  ]}
                  containerStyle={mb10}
                  onChangeText={value =>
                    dispatch({ type: SET_FIRST_NAME, value })
                  }
                  onBlur={() => {
                    dispatch({
                      type: CLEAR_VALIDATE_FIRST_NAME
                    });
                  }}
                  onSubmitEditing={() => this.lastNameInput.focus()}
                  placeholder={t("profile:firstname")}
                  keyboardType="default"
                  autoCorrect={false}
                  autoCapitalize="words"
                  keyboardAppearance="light"
                  returnKeyType="next"
                  value={firstname}
                  ref={input => (this.firstNameInput = input)}
                  errorMessage={firstnameErrorMsg}
                />
                <Input
                  inputStyle={[
                    Platform.OS === "ios" ? ml10 : null,
                    darkgrey,
                    font
                  ]}
                  onChangeText={value =>
                    dispatch({ type: SET_LAST_NAME, value })
                  }
                  onBlur={() => {
                    dispatch({
                      type: CLEAR_VALIDATE_LAST_NAME
                    });
                  }}
                  onSubmitEditing={() => {
                    if (role === ROLE_NEIGHBOR) {
                      this.birthdayInput.getElement().focus();
                    } else {
                      this.phoneInput.getElement().focus();
                    }
                  }}
                  containerStyle={mb20}
                  placeholder={t("profile:lastname")}
                  autoCorrect={false}
                  autoCapitalize="words"
                  value={lastname}
                  keyboardType="default"
                  returnKeyType="next"
                  ref={input => (this.lastNameInput = input)}
                  errorMessage={lastnameErrorMsg}
                />
                {role === ROLE_NEIGHBOR && (
                  <View style={[{ paddingHorizontal: 10 }, w100p]}>
                    <TextInputMask
                      ref={input => (this.birthdayInput = input)}
                      type={Platform.OS === "web" ? "custom" : "datetime"}
                      options={
                        Platform.OS === "web"
                          ? {
                              mask: "99/99/9999"
                            }
                          : {
                              format: "DD/MM/YYYY"
                            }
                      }
                      placeholder={t("profile:birthdate")}
                      value={birthdate}
                      style={[
                        font,
                        darkgrey,
                        fs18,
                        w100p,
                        !birthdayErrorMsg && mb20,
                        {
                          paddingLeft: Platform.OS === "ios" ? 10 : 0,
                          paddingBottom: 10,
                          height: 30,
                          borderColor: "gray",
                          borderBottomWidth: 1
                        }
                      ]}
                      onChangeText={value =>
                        dispatch({ type: SET_BIRTHDATE, value })
                      }
                      onBlur={() =>
                        dispatch({
                          type: CLEAR_VALIDATE_BIRTHDATE
                        })
                      }
                    />
                    {birthdayErrorMsg && (
                      <Text style={[ml5, mt5, red, fs12, mb20]}>
                        {birthdayErrorMsg}
                      </Text>
                    )}
                  </View>
                )}
              </View>
              {role === ROLE_NEIGHBOR && (
                <View
                  style={[
                    w100p,
                    p10,
                    bgWhite,
                    mt10,
                    mb20,
                    shadowGrey,
                    alignItemsCenter
                  ]}
                >
                  <Text style={[fontBold, color2, fs20, mv20]}>
                    {t("profile:address")}
                  </Text>
                  <Text style={[font, darkgrey, fs18, mb10]}>{address}</Text>
                  <Text
                    style={[font, darkgrey, fs18, mb10]}
                  >{`${zipCode} ${city}`}</Text>
                  {Boolean(building) && (
                    <Text
                      style={[
                        font,
                        darkgrey,
                        fs18,
                        Boolean(building || district) ? mb10 : mb20
                      ]}
                    >{`${t("infos:building")} ${building}`}</Text>
                  )}
                  {Boolean(residence) && (
                    <Text
                      style={[
                        font,
                        darkgrey,
                        fs18,
                        Boolean(district) ? mb10 : mb20
                      ]}
                    >{`${t("infos:residence")} ${residence}`}</Text>
                  )}
                  {Boolean(district) && (
                    <Text style={[font, darkgrey, fs18, mb20]}>{`${t(
                      "infos:district"
                    )} ${district}`}</Text>
                  )}
                  <CheckBox
                    title={t("profile:confirmaddress")}
                    textStyle={[{ color: darkgrey }, fs18, fontBold]}
                    checkedColor={COLOR2}
                    containerStyle={!confirmAddressErrorMsg && mb20}
                    checked={confirmAddress}
                    onPress={() => dispatch({ type: TOGGLE_CONFIRM_ADDRESS })}
                  />
                  {confirmAddressErrorMsg && (
                    <Text style={[ml5, mt5, red, fs12, mb20]}>
                      {confirmAddressErrorMsg}
                    </Text>
                  )}
                </View>
              )}

              {role === ROLE_NEIGHBOR && (
                <View
                  style={[
                    w100p,
                    p10,
                    bgWhite,
                    mt10,
                    mb20,
                    shadowGrey,
                    alignItemsCenter
                  ]}
                >
                  <Text style={[fontBold, color2, fs20, mv20]}>
                    {t("profile:moreinfos")}
                  </Text>
                  <Input
                    inputStyle={[
                      Platform.OS === "ios" ? ml10 : null,
                      darkgrey,
                      font
                    ]}
                    containerStyle={mb10}
                    onChangeText={value =>
                      dispatch({ type: SET_BUILDING_ACCESS, value })
                    }
                    placeholder={t("profile:buildingaccess")}
                    keyboardAppearance="light"
                    returnKeyType="next"
                    value={buildingAccess}
                    ref={input => (this.buildingAccessInput = input)}
                    onSubmitEditing={() => {
                      this.apartmentNumberInput &&
                        this.apartmentNumberInput.focus();
                    }}
                  />
                  <Input
                    inputStyle={[
                      Platform.OS === "ios" ? ml10 : null,
                      darkgrey,
                      font
                    ]}
                    containerStyle={mb10}
                    onChangeText={value =>
                      dispatch({ type: SET_APARTMENT_NUMBER, value })
                    }
                    placeholder={t("profile:apartmentnumber")}
                    keyboardType="decimal-pad"
                    keyboardAppearance="light"
                    returnKeyType="next"
                    value={apartmentNumber}
                    ref={input => (this.apartmentNumberInput = input)}
                    onSubmitEditing={() => {
                      this.floorInput && this.floorInput.focus();
                    }}
                  />
                  <Input
                    inputStyle={[
                      Platform.OS === "ios" ? ml10 : null,
                      darkgrey,
                      font
                    ]}
                    containerStyle={mb20}
                    onChangeText={value => dispatch({ type: SET_FLOOR, value })}
                    placeholder={t("profile:floor")}
                    keyboardType="decimal-pad"
                    keyboardAppearance="light"
                    returnKeyType="next"
                    value={floor}
                    ref={input => (this.floorInput = input)}
                    onSubmitEditing={() => {
                      this.phoneInput && this.phoneInput.getElement().focus();
                    }}
                  />
                  {!countryCode || countryCode === "FR" ? (
                    <View style={[{ paddingHorizontal: 10 }, w100p, mb10]}>
                      <TextInputMask
                        ref={input => (this.phoneInput = input)}
                        type={"custom"}
                        options={{
                          mask: "+33 (0)9 99 99 99 99"
                        }}
                        keyboardType="phone-pad"
                        placeholder={t("profile:phone")}
                        value={phone}
                        style={[
                          font,
                          darkgrey,
                          {
                            paddingLeft: Platform.OS === "ios" ? 10 : 0,
                            paddingBottom: 10,
                            fontSize: 18,
                            height: 40,
                            width: "100%",
                            borderColor: "gray",
                            borderBottomWidth: 1
                          },
                          !birthdayErrorMsg && mb20
                        ]}
                        onChangeText={value =>
                          dispatch({ type: SET_PHONE, value })
                        }
                        onBlur={() =>
                          dispatch({
                            type: CLEAR_VALIDATE_PHONE
                          })
                        }
                      />
                      {phoneErrorMsg && (
                        <Text style={[ml5, mt5, red, fs12]}>
                          {phoneErrorMsg}
                        </Text>
                      )}
                    </View>
                  ) : (
                    <View
                      style={[
                        { paddingHorizontal: 10 },
                        w100p,
                        role === ROLE_NEIGHBOR && mb20
                      ]}
                    >
                      <TextInputMask
                        ref={input => (this.phoneInput = input)}
                        type={"cel-phone"}
                        options={{
                          maskType: "INTERNATIONAL"
                        }}
                        placeholder={t("profile:phone")}
                        value={phone}
                        style={[
                          font,
                          {
                            paddingLeft: Platform.OS === "ios" ? 10 : 0,
                            paddingBottom: 10,
                            fontSize: 18,
                            height: 30,
                            width: "100%",
                            borderColor: "gray",
                            borderBottomWidth: 1
                          }
                        ]}
                        onChangeText={value =>
                          dispatch({ type: SET_PHONE, value })
                        }
                      />
                    </View>
                  )}
                  <Input
                    inputStyle={[
                      Platform.OS === "ios" ? ml10 : null,
                      darkgrey,
                      font
                    ]}
                    containerStyle={mb10}
                    onChangeText={value => dispatch({ type: SET_JOB, value })}
                    placeholder={t("profile:job")}
                    keyboardType="default"
                    keyboardAppearance="light"
                    returnKeyType="next"
                    value={job}
                    ref={input => (this.jobInput = input)}
                  />
                  <Input
                    inputStyle={[
                      Platform.OS === "ios" ? ml10 : null,
                      darkgrey,
                      font
                    ]}
                    containerStyle={mb20}
                    onChangeText={value =>
                      dispatch({ type: SET_DESCRIPTION, value })
                    }
                    placeholder={t("profile:description")}
                    keyboardType="default"
                    keyboardAppearance="light"
                    returnKeyType="next"
                    value={description}
                    ref={input => (this.descriptionInput = input)}
                  />
                </View>
              )}
              {role === ROLE_NEIGHBOR && (
                <View
                  style={[
                    w100p,
                    p20,
                    bgWhite,
                    mt10,
                    mb20,
                    shadowGrey,
                    alignItemsCenter
                  ]}
                >
                  <Text style={[fontBold, color2, fs20, mv20]}>
                    {t("profile:hobbies")}
                  </Text>
                  <Hobbies
                    hobbiesIds={new Set(hobbiesIds)}
                    onHobbiesChange={value =>
                      dispatch({ type: SET_USER_HOBBIES, value })
                    }
                  />
                  <CheckBox
                    title={t("profile:updatehobbies")}
                    textStyle={[{ paddingRight: 10 }, darkgrey, font]}
                    containerStyle={[w100p]}
                    checkedColor={COLOR2}
                    uncheckedColor={COLOR2}
                    checkedIcon="dot-circle-o"
                    uncheckedIcon="circle-o"
                    checked={updateHobbies}
                    onPress={() =>
                      dispatch({ type: UPDATE_AUTOMATICALLY_HOBBIES })
                    }
                  />
                  <CheckBox
                    title={t("profile:noupdatehobbies")}
                    textStyle={[{ paddingRight: 10 }, darkgrey, font]}
                    containerStyle={[w100p]}
                    checkedColor={COLOR2}
                    uncheckedColor={COLOR2}
                    checkedIcon="dot-circle-o"
                    uncheckedIcon="circle-o"
                    checked={!updateHobbies}
                    onPress={() =>
                      dispatch({ type: NOT_UPDATE_AUTOMATICALLY_HOBBIES })
                    }
                  />
                </View>
              )}
              {strategy === "jwt" && (
                <View
                  style={[
                    w100p,
                    p20,
                    bgWhite,
                    mt10,
                    mb20,
                    shadowGrey,
                    alignItemsCenter
                  ]}
                >
                  <Text style={[fontBold, color2, fs20, mv20]}>
                    {t("profile:password")}
                  </Text>
                  <Input
                    inputStyle={[
                      Platform.OS === "ios" ? ml10 : null,
                      darkgrey,
                      font
                    ]}
                    containerStyle={mb10}
                    onChangeText={value =>
                      dispatch({ type: SET_NEW_PASSWORD, value })
                    }
                    onBlur={() => {
                      dispatch({
                        type: CLEAR_VALIDATE_NEW_PASSWORD
                      });
                    }}
                    placeholder={t("profile:newpassword")}
                    keyboardType="default"
                    keyboardAppearance="light"
                    autoCapitalize="none"
                    autoCorrect={false}
                    secureTextEntry={true}
                    returnKeyType="next"
                    value={newpassword}
                    ref={input => (this.passwordInput = input)}
                    errorMessage={passwordErrorMsg}
                  />
                  <Input
                    inputStyle={[
                      Platform.OS === "ios" ? ml10 : null,
                      darkgrey,
                      font
                    ]}
                    containerStyle={mb10}
                    onChangeText={value =>
                      dispatch({ type: SET_NEW_PASSWORD_CONFIRMATION, value })
                    }
                    onBlur={() => {
                      dispatch({
                        type: CLEAR_VALIDATE_NEW_PASSWORD_CONFIRMATION
                      });
                    }}
                    placeholder={t("profile:newpasswordconfirmation")}
                    keyboardType="default"
                    keyboardAppearance="light"
                    autoCapitalize="none"
                    autoCorrect={false}
                    secureTextEntry={true}
                    returnKeyType="done"
                    value={newpasswordconfirmation}
                    ref={input => (this.passwordConfirmationInput = input)}
                    errorMessage={passwordConfirmationErrorMsg}
                  />
                </View>
              )}
            </ScrollView>
            <ActionButton
              buttonColor={menuOpen ? COLOR5 : COLOR2}
              degrees={90}
              renderIcon={() => (
                <MaterialCommunityIcons
                  name="dots-horizontal"
                  size={40}
                  color="white"
                />
              )}
              onPressIn={() => this.setState({ menuOpen: true })}
              onReset={() => this.setState({ menuOpen: false })}
              disabled={isFetching}
            >
              <ActionButton.Item
                buttonColor={"red"}
                title={t("button:deleteaccount")}
                onPress={this.showConfirmationModal}
                textStyle={[fontBold, fs16]}
              >
                <MaterialCommunityIcons
                  name="account-remove"
                  size={36}
                  color="white"
                />
              </ActionButton.Item>
              <ActionButton.Item
                buttonColor={"red"}
                title={t("button:cancel")}
                onPress={this.cancel}
                textStyle={[fontBold, fs16]}
              >
                <MaterialCommunityIcons name="cancel" size={36} color="white" />
              </ActionButton.Item>
              <ActionButton.Item
                buttonColor={COLOR2}
                title={firstLogin ? t("button:signup") : t("button:update")}
                onPress={this.clearProfileValidate}
                textStyle={[fontBold, fs16]}
              >
                <MaterialCommunityIcons
                  name="account-check"
                  size={36}
                  color="white"
                />
              </ActionButton.Item>
            </ActionButton>
          </SafeAreaView>
        )}
      </KeyboardAvoidingView>
    );
  }
}

const mapStateToProps = state => {
  const {
    files,
    id,
    pseudo,
    firstname,
    lastname,
    gender,
    birthdate,
    address,
    zipCode,
    city,
    phone,
    description,
    photoUri,
    hobbiesIds,
    pseudoErrorMsg,
    firstnameErrorMsg,
    lastnameErrorMsg,
    birthdayErrorMsg,
    user,
    isValid,
    isFetching,
    firstLogin,
    needPseudoCheck,
    clearProfileValidate,
    isPseudoClearValidate,
    isFirstnameClearValidate,
    isLastnameClearValidate,
    isBirthdateClearValidate,
    isPhoneClearValidate,
    phoneErrorMsg,
    floor,
    apartmentNumber,
    job,
    building,
    residence,
    district,
    confirmAddress,
    confirmAddressErrorMsg,
    needUpdate,
    updateHobbies,
    buildingAccess,
    strategy,
    newpassword,
    newpasswordconfirmation,
    passwordErrorMsg,
    passwordConfirmationErrorMsg,
    isPasswordClearValidate,
    isPasswordConfirmationClearValidate
  } = state.profileReducer;

  return {
    files,
    id,
    pseudo,
    firstname,
    lastname,
    gender,
    birthdate,
    address,
    zipCode,
    city,
    phone,
    description,
    photoUri,
    hobbiesIds,
    pseudoErrorMsg,
    firstnameErrorMsg,
    lastnameErrorMsg,
    birthdayErrorMsg,
    user,
    clearProfileValidate,
    isValid,
    isFetching,
    role: user?.role,
    firstLogin,
    needPseudoCheck,
    isPseudoClearValidate,
    isFirstnameClearValidate,
    isLastnameClearValidate,
    isBirthdateClearValidate,
    isPhoneClearValidate,
    phoneErrorMsg,
    floor,
    apartmentNumber,
    job,
    building,
    residence,
    district,
    confirmAddress,
    confirmAddressErrorMsg,
    needUpdate,
    updateHobbies,
    buildingAccess,
    deleteUserResult: state.loginReducer.deleteUserResult,
    strategy,
    newpassword,
    newpasswordconfirmation,
    passwordErrorMsg,
    passwordConfirmationErrorMsg,
    isPasswordClearValidate,
    isPasswordConfirmationClearValidate
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    { fetchUpdateProfile, fetchCheckPseudo, fetchGetUser, fetchDeleteUser },
    dispatch
  );
  return { ...actions, dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUpdate);
