import {
  API_START,
  API_END,
  FETCH_GET_COMMUNITY_DETAIL,
  SET_COMMUNITY_DETAIL,
  SET_CITY_ID,
  SET_COMMUNITY_ID,
  RESET_DATA,
  SET_CITY_COMMUNITIES,
  SET_CITIES,
  SET_QUERY_CITY_ID
} from "../actions/types";

import AsyncStorage from "@react-native-community/async-storage";

const initialState = {
  isFetching: true,
  communities: null,
  communityDetails: null,
  computedCommunities: [],
  cities: null,
  currentCommunity: null,
  queryCityId: null,
  cityId: null,
  communityId: null,
  needRegisterKey: false
};

export default function communityReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_GET_COMMUNITY_DETAIL) {
        return {
          ...state,
          isFetching: true,
          communityDetails: null
        };
      }
      break;

    case RESET_DATA:
      return {
        ...initialState,
        cityId: state.cityId,
        communityId: state.communityId
      };

    case SET_COMMUNITY_DETAIL:
      if (action.entities.community) {
        const communityDetails = Object.values(action.entities.community)[0];
        const communityId = communityDetails && communityDetails._id;
        // case connexion with jwt: communityId can change
        if (communityId && state.communityId != communityId) {
          AsyncStorage.setItem("communityId", communityId);
        }

        let cityId;
        if (
          communityDetails &&
          communityDetails.citiesIds &&
          communityDetails.citiesIds[0] !== state.cityId
        ) {
          cityId = communityDetails.citiesIds[0];
          AsyncStorage.setItem("cityId", cityId);
        }
        return {
          ...state,
          communityDetails,
          communityId,
          cityId
        };
      }
      break;

    case SET_CITIES: {
      const cities = action.entities.cities;
      let cityId = state.cityId;
      if (!cityId || !cities[cityId]) {
        const citiesIds = Object.keys(cities);
        if (citiesIds.length > 0) {
          cityId = Object.keys(cities)[0];
          AsyncStorage.setItem("cityId", cityId);
        }
      }
      return {
        ...state,
        cities,
        cityId
      };
    }

    case SET_CITY_ID: {
      AsyncStorage.setItem("cityId", action.value);
      return {
        ...state,
        cityId: action.value
      };
    }

    case SET_QUERY_CITY_ID: {
      return {
        ...state,
        queryCityId: action.value
      };
    }

    case SET_COMMUNITY_ID: {
      AsyncStorage.setItem("communityId", action.value);
      return {
        ...state,
        communityId: action.value,
        currentCommunity: state.communities
          ? state.communities[action.value]
          : null,
        needRegisterKey: state.communities
          ? Boolean(state.communities[action.value]) &&
            Boolean(state.communities[action.value].registerkey)
          : false,
        computedCommunities:
          state.computedCommunities.filter(obj => obj.value === action.value)
            .length > 0 || !state.communities
            ? state.computedCommunities
            : [
                ...state.computedCommunities,
                ...Object.values(state.communities)
                  .filter(obj => obj._id === action.value)
                  .map(obj => ({
                    label: obj.name,
                    value: obj._id,
                    longitude: obj.longitude,
                    latitude: obj.latitude
                  }))
              ]
      };
    }

    case SET_CITY_COMMUNITIES:
      if (action.entities.communities) {
        const values = Object.values(action.entities.communities);
        let communityId = state.communityId;
        if (values.length > 0) {
          if (state.queryCityId) {
            AsyncStorage.setItem("cityId", state.queryCityId);
          }
          if (!communityId || !action.entities.communities[communityId]) {
            communityId = values[0]._id;
            AsyncStorage.setItem("communityId", communityId);
          }
          return {
            ...state,
            queryCityId: null,
            cityId: state.queryCityId ? state.queryCityId : state.cityId,
            communities: action.entities.communities,
            communityId: communityId,
            currentCommunity: action.entities.communities[communityId],
            needRegisterKey: Boolean(
              action.entities.communities[communityId].registerkey
            ),
            computedCommunities: Object.values(action.entities.communities)
              // .filter(obj => obj.subscribe)
              .map(obj => ({
                label: obj.name,
                value: obj._id,
                longitude: obj.longitude,
                latitude: obj.latitude
              }))
          };
        }
      }
      // no data => state unchanged except queryCityId
      return {
        ...state,
        queryCityId: null,
        communities: state.queryCityId ? state.communities : {}
      };

    case API_END:
      if (action.payload === FETCH_GET_COMMUNITY_DETAIL) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;

    default:
      return state;
  }
  return state;
}
