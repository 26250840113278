import React, { useEffect, Fragment } from "react";
import AsyncStorage from "@react-native-community/async-storage";
import { useLinkTo } from "@react-navigation/native";

export default function LinkRedirection() {
  const linkTo = useLinkTo();

  useEffect(() => {
    AsyncStorage.getItem("REDIRECT_LINK").then(link => {
      if (
        link &&
        link !== "/" &&
        link !== "/accueil" &&
        !link.startsWith("/?") &&
        !link.startsWith("/accueil?")
      ) {
        linkTo(link);
        AsyncStorage.removeItem("REDIRECT_LINK");
      }
    });
  }, []);

  return <Fragment />;
}
