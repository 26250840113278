import {
  API_START,
  API_END,
  FETCH_GET_GOOD_DEAL_DETAILS,
  SET_GOOD_DEAL_DETAILS_RESULT,
  SHARE,
  CLEAR_SHARE
} from "../actions/types";

const initialState = {
  isFetching: false,
  goodDeal: null,
  share: false
};

export default function goodDealDetailsReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_GET_GOOD_DEAL_DETAILS) {
        return {
          ...state,
          isFetching: true,
          goodDeal: null,
          share: false
        };
      }
      break;

    case CLEAR_SHARE:
      return {
        ...state,
        share: false
      };

    case SHARE:
      return {
        ...state,
        share: true
      };

    case SET_GOOD_DEAL_DETAILS_RESULT:
      return {
        ...state,
        goodDeal: action.payload
      };

    case API_END:
      if (action.payload === FETCH_GET_GOOD_DEAL_DETAILS) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
}
