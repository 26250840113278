import React, { useEffect, useRef } from "react";
import { Text, TouchableOpacity, Platform } from "react-native";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { fetchLoginWithFB, fetchSignUpWithFB } from "../actions/login";
import {
  SET_LOGIN_WITH_FB_RESULT,
  VALIDATE_FB_INPUTS,
  SET_DROP_DOWN_ALERT_ERROR,
  SET_TOOLTIP6_VISIBLE
} from "../actions/types";
import commonStyles, {
  FB_COLOR,
  DARKGREY_COLOR,
  isiOS
} from "../styles/commonStyles";
import { FontAwesome5 } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-native-elements";
import { t } from "../services/i18n";
import config from "../../app.json";
import { PWA_DEEPLINK_APP_URL } from "../config/constants";
import Modal from "react-native-modalbox";

let majorVersionIOS = 0;
if (isiOS) {
  majorVersionIOS = parseInt(Platform.Version, 10);
}

let _isMounted = false;
export default function FBLogin() {
  const dispatch = useDispatch();

  const tooltip5Ref = useRef();

  const {
    registerkey,
    clearValidateFB,
    validFB,
    selectedCategory,
    isTooltip5Visible
  } = useSelector(state => state.loginReducer);
  const { lang } = useSelector(state => state.globalInformationsReducer);

  const { communityId } = useSelector(state => state.communityReducer);

  useEffect(() => {
    _isMounted = true;
    return () => (_isMounted = false);
  }, []);

  useEffect(() => {
    if (clearValidateFB) {
      dispatch({
        type: VALIDATE_FB_INPUTS
      });
    }
  }, [clearValidateFB]);

  useEffect(() => {
    if (validFB) {
      logInWithFB();
    }
  }, [validFB]);

  useEffect(() => {
    if (isTooltip5Visible && _isMounted) {
      tooltip5Ref?.current?.toggleTooltip();
    }
  }, [isTooltip5Visible]);

  const responseFacebook = response => {
    if (!response || !response.accessToken) {
      dispatch({
        type: SET_DROP_DOWN_ALERT_ERROR,
        error: "facebookerror"
      });
      return;
    }
    dispatch({
      type: SET_LOGIN_WITH_FB_RESULT,
      data: response
    });
    if (selectedCategory) {
      dispatch(
        fetchSignUpWithFB(
          communityId,
          response.accessToken,
          registerkey,
          response.email,
          lang
        )
      );
    } else {
      dispatch(fetchLoginWithFB(communityId, response.accessToken));
    }
  };

  const { font, white, justifyContentCenter, alignItemsCenter } = commonStyles;

  return (
    <Tooltip
      ref={tooltip5Ref}
      ModalComponent={Modal}
      popover={
        <Text style={[font, white]}>
          {majorVersionIOS >= 13 ? t("login:tooltip5") : t("login:tooltip5bis")}
        </Text>
      }
      backgroundColor={DARKGREY_COLOR}
      toggleOnPress={false}
      withOverlay={false}
      height={100}
      width={180}
      onClose={() =>
        setTimeout(
          () =>
            dispatch({
              type: SET_TOOLTIP6_VISIBLE,
              payload: true
            }),
          10
        )
      }
    >
      <FacebookLogin
        appId={config?.expo?.facebookAppId}
        callback={responseFacebook}
        redirectUri={PWA_DEEPLINK_APP_URL}
        fields="first_name,last_name,email,picture.type(large)"
        render={renderProps => (
          <TouchableOpacity
            style={[
              {
                width: 54,
                height: 54,
                borderRadius: 27,
                backgroundColor: FB_COLOR
              },
              alignItemsCenter,
              justifyContentCenter
            ]}
            onPress={renderProps.onClick}
          >
            <FontAwesome5 name="facebook-f" size={26} color="white" />
          </TouchableOpacity>
        )}
        disableMobileRedirect={true}
      />
    </Tooltip>
  );
}
