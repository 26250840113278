import React, { useState } from "react";
import { Linking } from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import { Notifications } from "expo";
import DropdownAlert from "react-native-dropdownalert";
import { NavigationContainer } from "@react-navigation/native";
import { ThemeProvider } from "react-native-elements";
import { COLOR2, GREEN_COLOR, isWeb } from "../styles/commonStyles";
// Services
// import CheckUpdates from "../services/CheckUpdates";
import ErrorManagement from "../services/ErrorManagement";
import DeepLinkManagement from "../services/DeepLinkManagement";
// import PushNotifications from "../services/PushNotifications";
import { createRootAppStackNavigator } from "./AppNavigator";
import { useSelector, useDispatch } from "react-redux";
import {
  NAVIGATION,
  SET_CITY_ID,
  SET_COMMUNITY_ID,
  SET_DEEP_LINK_TOKEN,
  SET_REGISTER_KEY,
  SET_SELECTED_CATEGORY,
  SHOW_LOGIN_MODAL
} from "../actions/types";
import { fetchGetCityCommunities } from "../actions";
import {
  PWA_DEEPLINK_APP_URL,
  DEEPLINK_URL_SCHEME,
  DEEPLINK_APP_URL
} from "../config/constants";
import { Branch } from "../../BranchIO";
import { parse } from "search-params";
import { isAndroid } from "../components/actionButton/shared";

const theme = {
  colors: {
    primary: COLOR2
  }
};

// Gets the current screen from navigation state
const getActiveRouteName = state => {
  const route = state.routes[state.index];

  if (route.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }

  return route.name;
};

const getActiveRoute = state => {
  const route = state.routes[state.index];

  if (route.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }

  return route;
};

const getRedirectLink = url => {
  if (__DEV__ && url?.startsWith("https://192.168.1.61:19006")) {
    return url.substring("https://192.168.1.61:19006".length);
  } else if (__DEV__ && url?.startsWith("http://192.168.1.61:19006")) {
    return url.substring("http://192.168.1.61:19006".length);
  } else if (__DEV__ && url?.startsWith("https://localhost:19006")) {
    return url.substring("https://localhost:19006".length);
  } else if (__DEV__ && url?.startsWith("http://localhost:19006")) {
    return url.substring("http://localhost:19006".length);
  } else if (url?.startsWith(PWA_DEEPLINK_APP_URL)) {
    return url.substring(PWA_DEEPLINK_APP_URL.length);
  } else {
    return null;
  }
};

export default function AppNavigationContainer(props) {
  const store = props.store;
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.profileReducer);
  const { accessDenied = false, isFetchingError } = useSelector(
    state => state.globalInformationsReducer
  );
  const { displayPreview, onClickPreview } = useSelector(
    state => state.templateReducer
  );

  const [screen, setScreen] = useState(null);
  const [params, setParams] = useState(null);

  const routeNameRef = React.useRef();
  const navigationRef = React.useRef();
  const myRef = React.useRef();
  const dropdownAlertRef = React.useRef();

  const onTap = message => {
    myRef?.current?.onTap(message);
  };

  const onClose = message => {
    myRef?.current?.onClose(message);
  };

  React.useEffect(() => {
    if (navigationRef.current) {
      const state = navigationRef.current.getRootState();

      // Save the initial route name
      routeNameRef.current = getActiveRouteName(state);
    }
  }, []);

  // Workaround notification android does not work
  if (isAndroid) {
    const lastNotificationResponse = Notifications.useLastNotificationResponse();
    React.useEffect(() => {
      if (
        lastNotificationResponse?.notification?.request?.content?.data
          ?.screen &&
        lastNotificationResponse.actionIdentifier ===
          Notifications.DEFAULT_ACTION_IDENTIFIER
      ) {
        setScreen(
          lastNotificationResponse.notification.request.content.data.screen
        );
        setParams(
          lastNotificationResponse.notification.request.content.data.params
        );
      }
    }, [lastNotificationResponse]);
  }

  const branchSubscribe = () => {
    let branchUnsubscribe;
    if (Branch) {
      // Increase the session init timeout
      Branch.initSessionTtl = 10000;
      branchUnsubscribe = Branch.subscribe(bundle => {
        if (bundle && bundle.params && !bundle.error) {
          // `bundle.params` contains all the info about the link.
          if (
            bundle.params.$deeplink_path === "discover" ||
            bundle.params.$deeplink_path === "show" ||
            bundle.params["~feature"] === "share"
          ) {
            const { screen, params, id } = bundle.params;
            if (screen) {
              navigationRef?.current?.navigate(
                screen,
                params ? JSON.parse(params) : id ? { id } : null
              );
            }
          }
        }
      });
    }
    return branchUnsubscribe;
  };

  const linking1 = {
    prefixes: [PWA_DEEPLINK_APP_URL, DEEPLINK_APP_URL, DEEPLINK_URL_SCHEME],
    config: {
      ProfileUpdateScreen: "enregistrement"
    }
  };

  const linking2 = {
    prefixes: [
      PWA_DEEPLINK_APP_URL,
      DEEPLINK_APP_URL,
      DEEPLINK_URL_SCHEME
      // DEEPLINK_DEV_URL
    ],
    config: {
      TabScreen: {
        screens: {
          LiveTab: {
            screens: {
              LiveScreen: "actualites",
              SettingsScreen: "parametres"
            }
          },
          ChatTab: {
            screens: {
              ChatOwnScreen: "mes-conversations",
              ChatGroupScreen: "conversations-de-groupe",
              ChatEmployeesScreen: "a-mon-service",
              ChatNeighborsScreen: "mes-voisins"
            }
          },
          GoodDealsTab: {
            screens: {
              GoodDealsScreen: "vie-locale",
              GoodDealsMapScreen: "a-proximite"
            }
          },
          MeteoTab: {
            screens: {
              MeteoScreen: "meteo"
            }
          },
          CommunityDetailsTab: {
            screens: {
              CommunityDetailsScreen: "voisinage",
              AppInfosScreen: "a-propos"
            }
          }
        }
      },
      ProfileUpdateScreen: "mon-profil",
      CameraScreen: "appareil-photo",
      SelectActivityScreen: "instants-partage",
      SelectProposalScreen: "demandes",
      SelectTemplateScreen: "modeles",
      SelectTemplateDateScreen: "parametres-publication",
      PostScreen: "edition-publication",
      EventInterestedUsersScreen: "publication",
      ProfileScreen: "profil",
      GoodDealDetailsScreen: "vie-locale/annonce",
      ChatScreen: "conversation",
      ImageZoomScreen: "zoom-image",
      CGUScreen: "conditions-generales-utilisation",
      PdfWelcomeScreen: "pdf-bienvenue",
      MaintenanceScreen: "maintenance"
    },
    subscribe(listener) {
      const urlListener = ({ url }) => {
        listener(url);
      };

      (async () => {
        if (global.justStart) {
          const initialUrl = await Linking.getInitialURL();
          listener(initialUrl);
          global.justStart = false;
        }

        Linking.addEventListener("url", urlListener);
      })();

      const _handleNotification = response => {
        if (response?.notification?.request?.content?.data?.screen) {
          navigationRef?.current?.navigate(
            response.notification.request.content.data.screen,
            response.notification.request.content.data.params
          );
        }
      };
      const _notificationSubscription = Notifications.addNotificationResponseReceivedListener(
        _handleNotification
      );

      let branchUnsubscribe = branchSubscribe();

      return () => {
        Linking.removeListener("url", urlListener);
        _notificationSubscription.remove();
        if (branchUnsubscribe) {
          branchUnsubscribe();
        }
      };
    }
  };

  const linking3 = {
    prefixes: [PWA_DEEPLINK_APP_URL, DEEPLINK_APP_URL, DEEPLINK_URL_SCHEME],
    config: {
      LandingScreen: "accueil",
      AppInfosScreen: "a-propos",
      CGUScreen: "conditions-generales-utilisation",
      GoodDealDetailsScreen: "vie-locale/annonce",
      SelectTemplateDateScreen: "parametres-publication",
      EventDetailsScreen: "detail-publication",
      MaintenanceScreen: "maintenance"
    },
    subscribe(listener) {
      const urlMatch = url => {
        let matches = url.match(/\/(connexion|rejoindre)\?([^\n]*)/);
        if (matches) {
          let params = matches[2];
          const { cityId, communityId, token, registerkey } = parse(params);
          if (cityId) {
            dispatch(fetchGetCityCommunities(cityId));
            dispatch({
              type: SET_CITY_ID,
              value: cityId
            });
          }
          if (communityId) {
            dispatch({
              type: SET_COMMUNITY_ID,
              value: communityId
            });
          }
          if (token) {
            dispatch({
              type: SET_DEEP_LINK_TOKEN,
              payload: token
            });
          }
          if (registerkey) {
            dispatch({
              type: SET_REGISTER_KEY,
              value: registerkey,
              hideRegisterKey: true
            });
            dispatch({
              type: SET_SELECTED_CATEGORY,
              value: 1
            });
            dispatch({
              type: SHOW_LOGIN_MODAL
            });
          }
        }
      };

      const urlListener = ({ url }) => {
        urlMatch(url);
        listener(url);
      };

      (async () => {
        if (global.justStart) {
          const initialUrl = await Linking.getInitialURL();
          urlMatch(initialUrl);
          global.justStart = false;
        }

        Linking.addEventListener("url", urlListener);
      })();

      let branchUnsubscribe = branchSubscribe();

      return () => {
        Linking.removeEventListener("url", urlListener);
        if (branchUnsubscribe) {
          branchUnsubscribe();
        }
      };
    }
  };

  const linking4 = {
    prefixes: [PWA_DEEPLINK_APP_URL, DEEPLINK_APP_URL, DEEPLINK_URL_SCHEME],
    config: {
      LoadingScreen: "",
      MaintenanceScreen: "maintenance"
    }
  };

  const state = {
    routes: []
  };

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={
        Boolean(user && !isFetchingError)
          ? user.needUpdate
            ? linking1
            : linking2
          : accessDenied || isFetchingError
          ? linking3
          : linking4
      }
      initialState={state}
      onStateChange={state => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = getActiveRouteName(state);

        if (isWeb && getRedirectLink(window.location.href)) {
          AsyncStorage.setItem(
            "REDIRECT_LINK",
            getRedirectLink(window.location.href)
          );
        }

        if (previousRouteName !== currentRouteName) {
          const route = getActiveRoute(state);
          if (route instanceof Object) {
            store.dispatch({
              type: NAVIGATION,
              ...route
            });
          } else {
            store.dispatch({
              type: NAVIGATION,
              name: route
            });
          }
        }

        // Save the current route name for later comparision
        routeNameRef.current = currentRouteName;
      }}
    >
      <ThemeProvider theme={theme}>
        {createRootAppStackNavigator({
          dispatch: store.dispatch,
          user,
          accessDenied,
          navigation: navigationRef.current,
          isFetchingError,
          displayPreview,
          onClickPreview
        })}
      </ThemeProvider>
      {/* <CheckUpdates /> */}
      <ErrorManagement
        ref={myRef}
        navigationRef={navigationRef}
        dropDownHolder={dropdownAlertRef.current}
      />
      <DeepLinkManagement />
      {/* <PushNotifications /> */}
      <DropdownAlert
        ref={dropdownAlertRef}
        containerStyle={{ backgroundColor: GREEN_COLOR }}
        tapToCloseEnabled={true}
        closeInterval={6000}
        onTap={onTap}
        onClose={onClose}
        messageNumOfLines={5}
        updateStatusBar={false}
      />
    </NavigationContainer>
  );
}
