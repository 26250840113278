import React, { Component } from "react";
import { ActivityIndicator, View, Text } from "react-native";
import commonStyles, {
  COLOR5,
  COLOR2,
  SCREEN_WIDTH
} from "../styles/commonStyles";
import LiveHeader from "./LiveHeader";
import LiveWeatherElement from "./LiveWeatherElement";
import { getTranslatedProperty } from "../services/i18n";
// import { captureRef as takeSnapshotAsync } from "react-native-view-shot";
import { withNavigation } from "@react-navigation/compat";
import ActionButton from "../components/actionButton/ActionButton";
import { t } from "../services/i18n";
import { SHARE_WEATHER } from "../actions/types";
import { MaterialCommunityIcons } from "@expo/vector-icons";

class LiveWeather extends Component {
  state = {
    height: SCREEN_WIDTH,
    width: SCREEN_WIDTH,
    isFetching: false
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { date, refresh, photoUri } = this.props;
    const { height, width, isFetching } = this.state;

    if ((nextProps.refresh && !refresh) || (!nextProps.refresh && refresh)) {
      return true;
    }
    if (nextProps.photoUri && (!photoUri || photoUri !== nextProps.photoUri)) {
      return true;
    }
    if (nextProps.date && (!date || date !== nextProps.date)) {
      return true;
    }
    if (
      (nextState.isFetching && !isFetching) ||
      (!nextState.isFetching && isFetching)
    ) {
      return true;
    }
    if (nextState.width !== width || !nextState.height !== height) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, photoUri, event, _id } = this.props;
    if (!prevProps.photoUri && photoUri) {
      this.setState({
        isFetching: false
      });
      dispatch({
        type: SHARE_WEATHER,
        payload: {
          event,
          photoUri,
          _id
        }
      });
    }
  }

  render() {
    let {
      bgWhite,
      font,
      w100p,
      p10,
      shadowGrey,
      mv5,
      row,
      flex1,
      borderBlack,
      borderBottomBlack,
      borderTopBlack,
      borderRightBlack,
      borderLeftBlack,
      mb10,
      darkgrey
    } = commonStyles;
    let { data } = this.props.event;
    let {
      dispatch,
      _id,
      from,
      date,
      groupId,
      datetimeDiff,
      lang,
      navigation,
      refresh
    } = this.props;
    let { pseudo, photoUri } = from;
    const { height, width } = this.state;

    return (
      <View
        ref={ref => (this.meteoView = ref)}
        onLayout={event => {
          const { width, height } = event.nativeEvent.layout;
          if (width > height) {
            this.setState({
              width: SCREEN_WIDTH,
              height: (height / width) * SCREEN_WIDTH
            });
          } else {
            this.setState({
              height: SCREEN_WIDTH,
              width: (width / height) * SCREEN_WIDTH
            });
          }
        }}
        style={[bgWhite, shadowGrey, w100p, p10, mv5]}
      >
        <LiveHeader
          date={date}
          pseudo={pseudo}
          groupId={groupId}
          photoUri={photoUri}
          datetimeDiff={datetimeDiff}
          lang={lang}
          refresh={refresh}
        />

        <View style={[p10]}>
          <Text style={[font, mb10]}>
            {getTranslatedProperty(this.props.event, "description")}
          </Text>

          <View style={[mb10]}>
            <View style={[flex1, row]}>
              <LiveWeatherElement
                data={data}
                hour="9"
                customProps={[borderBlack]}
              />
              <LiveWeatherElement
                data={data}
                hour="15"
                customProps={[
                  borderTopBlack,
                  borderBottomBlack,
                  borderRightBlack
                ]}
              />
            </View>

            <View style={[flex1, row]}>
              <LiveWeatherElement
                data={data}
                hour="12"
                customProps={[
                  borderBottomBlack,
                  borderRightBlack,
                  borderLeftBlack
                ]}
              />
              <LiveWeatherElement
                data={data}
                hour="18"
                customProps={[borderBottomBlack, borderRightBlack]}
              />
            </View>
          </View>

          <View style={[row]}>
            <View style={[flex1]} />
            <Text style={[font, darkgrey]}>
              {getTranslatedProperty(this.props.event, "postMessage")}
            </Text>
          </View>
        </View>
        <ActionButton
          buttonColor={COLOR5}
          renderIcon={() => (
            <MaterialCommunityIcons
              name="dots-horizontal"
              size={40}
              color="white"
            />
          )}
          verticalOrientation="down"
          size={44}
          offsetX={18}
          offsetY={18}
          degrees={90}
          spacing={10}
        >
          <ActionButton.Item
            buttonColor={COLOR2}
            title={t("button:forecasts")}
            onPress={() => navigation.navigate("MeteoScreen")}
            textStyle={font}
            textHeight={24}
          >
            <MaterialCommunityIcons
              name="weather-partly-cloudy"
              size={32}
              color="white"
            />
          </ActionButton.Item>
          {/* <ActionButton.Item
            buttonColor={COLOR2}
            title={t("button:share")}
            onPress={() => {
              this.setState({ isFetching: true }, () =>
                setTimeout(async () => {
                  const base64 = await takeSnapshotAsync(this.meteoView, {
                    result: "tmpfile",
                    height,
                    width,
                    // quality: 0.1,
                    format: "jpg",
                    result: "data-uri"
                  });
                  const files = [];
                  files.push({
                    name: _id,
                    base64: base64.replace(/(\r\n|\n|\r)/gm, "")
                  });
                  dispatch(fetchShareMeteo(files));
                }, 500)
              );
            }}
            textStyle={font}
            textHeight={24}
          >
            {this.state.isFetching ? (
              <ActivityIndicator size="small" color="white" />
            ) : (
              <FontAwesome5 name="share-alt" size={28} color="white" />
            )}
          </ActionButton.Item> */}
        </ActionButton>
      </View>
    );
  }
}

export default withNavigation(LiveWeather);
