import Constants from "expo-constants";
import { schema } from "normalizr";
import { apiAction } from "./api";
import { fetchGetCummunityDetail } from "./community";
import { fetchGetGoodDealsCategories } from "./gooddeal";
import { fetchGetLocations } from "./location";
import { fetchGetHobbies, fetchGetUserAndMyChatUsers } from "./profile";
import {
  fetchGetTemplateDates,
  fetchGetTemplates,
  fetchGetTemplateTimes
} from "./template";
import {
  FETCH_GET_COMMUNITIES,
  FETCH_GET_GLOBAL_INFORMATIONS,
  FETCH_GET_GLOBAL_INFORMATIONS_FROM_LOGIN,
  FETCH_GET_GLOBAL_INFORMATIONS_VERSION,
  SET_COMMUNITIES,
  SET_COMMUNITIES_FAILURE,
  SET_GLOBAL_INFORMATIONS,
  SET_GLOBAL_INFORMATIONS_FAILURE,
  SET_GLOBAL_INFORMATIONS_VERSIONS,
  SET_GLOBAL_INFORMATIONS_VERSIONS_FAILURE,
  FETCH_GET_CITIES,
  SET_CITIES,
  SET_CITIES_FAILURE,
  FETCH_GET_CITY_COMMUNITIES,
  SET_CITY_COMMUNITIES,
  SET_CITY_COMMUNITIES_FAILURE
} from "./types";
import { APP_NAME, COMMUNITIES } from "../config/constants";

const communities = new schema.Entity(
  "communities",
  {},
  {
    idAttribute: "_id"
  }
);

const cities = new schema.Entity(
  "cities",
  {},
  {
    idAttribute: "_id"
  }
);

export function fetchCheckVersionAuthenticated(fromLogin = true) {
  return apiAction({
    url: "/globalinformations/check/version/authenticated",
    method: "POST",
    headers: null,
    data: {
      version: Constants.manifest.version,
      appname: APP_NAME,
      isDev: true
    },
    onSuccess: data => [
      fetchGetUserAndMyChatUsers(),
      fetchGetLocations(),
      fetchGetTemplates(),
      fetchGetTemplateDates(),
      fetchGetTemplateTimes(),
      fetchGetGoodDealsCategories(),
      fetchGetHobbies(),
      fetchGetCummunityDetail(),
      fetchGetCommunities(),
      setGlobalInformations(data)
    ],
    onFailure: setGlobalInformationsFailure,
    label: fromLogin
      ? FETCH_GET_GLOBAL_INFORMATIONS_FROM_LOGIN
      : FETCH_GET_GLOBAL_INFORMATIONS
  });
}

function setGlobalInformationsVersions(data) {
  return {
    type: SET_GLOBAL_INFORMATIONS_VERSIONS,
    payload: data
  };
}

function setGlobalInformations(data) {
  return {
    type: SET_GLOBAL_INFORMATIONS,
    payload: data
  };
}

export function fetchCheckVersion() {
  return apiAction({
    url: "/globalinformations/check/version",
    method: "POST",
    data: {
      version: Constants.manifest.version,
      appname: APP_NAME,
      isDev: true
    },
    onSuccess: setGlobalInformationsVersions,
    onFailure: setGlobalInformationsVersionsFailure,
    label: FETCH_GET_GLOBAL_INFORMATIONS_VERSION
  });
}

function setGlobalInformationsFailure(error) {
  return {
    type: SET_GLOBAL_INFORMATIONS_FAILURE,
    payload: error
  };
}

function setGlobalInformationsVersionsFailure(error) {
  return {
    type: SET_GLOBAL_INFORMATIONS_VERSIONS_FAILURE,
    payload: error
  };
}

export function fetchGetCommunities() {
  return apiAction({
    url: `/${COMMUNITIES}`,
    method: "GET",
    schema: [communities],
    onSuccess: setCommunities,
    onFailure: setCommunitiesFailure,
    label: FETCH_GET_COMMUNITIES
  });
}

function setCommunities(normalized) {
  return {
    type: SET_COMMUNITIES,
    entities: normalized.entities
  };
}

function setCommunitiesFailure(data) {
  return {
    type: SET_COMMUNITIES_FAILURE,
    payload: data
  };
}

export function fetchGetCities() {
  return apiAction({
    url: "/cities",
    method: "GET",
    schema: [cities],
    onSuccess: setCities,
    onFailure: setCitiesFailure,
    label: FETCH_GET_CITIES
  });
}

function setCities(normalized) {
  return {
    type: SET_CITIES,
    entities: normalized.entities
  };
}

function setCitiesFailure(data) {
  return {
    type: SET_CITIES_FAILURE,
    payload: data
  };
}

export function fetchGetCityCommunities(cityId) {
  return apiAction({
    url: `/${COMMUNITIES}/city/${cityId}`,
    method: "GET",
    schema: [communities],
    onSuccess: setCityCommunities,
    onFailure: setCityCommunitiesFailure,
    label: FETCH_GET_CITY_COMMUNITIES
  });
}

function setCityCommunities(normalized) {
  return {
    type: SET_CITY_COMMUNITIES,
    entities: normalized.entities
  };
}

function setCityCommunitiesFailure(data) {
  return {
    type: SET_CITY_COMMUNITIES_FAILURE,
    payload: data
  };
}
