import Constants from "expo-constants";
import React, { Component } from "react";
import {
  ActivityIndicator,
  Animated,
  Easing,
  FlatList,
  LayoutAnimation,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchGetLiveEvents } from "../actions/live";
import LiveWeather from "../components/LiveWeather";
import commonStyles, { COLOR2, COLOR5 } from "../styles/commonStyles";
import LiveEvent from "../components/LiveEvent";
import { t } from "../services/i18n";
import {
  LIVE_ON_TOP,
  SET_FILTER,
  DISPLAY_ALL,
  DISPLAY_ONLY_COMMUNITY_POSTS,
  DISPLAY_ONLY_MY_POSTS,
  SET_ON_CLICK_FILTER,
  SET_DISPLAY_FILTER_OPTION
} from "../actions/types";
import {
  GROUP_ID_WEATHER,
  GROUP_ID_ACTIVITY,
  GROUP_ID_TEMPLATE,
  GROUP_ID_PUBLISH,
  ROLE_WEBMASTER,
  ROLE_EMPLOYEE,
  ROLE_ADMINISTRATOR,
  GROUP_ID_GOODDEAL_ACTIVITY,
  GROUP_ID_PROPOSAL
} from "../config/constants";
import { CheckBox } from "react-native-elements";
import ActionButton from "../components/actionButton/ActionButton";
import {
  FontAwesome,
  FontAwesome5,
  MaterialCommunityIcons,
  Entypo
} from "@expo/vector-icons";
import LinkRedirection from "./LinkRedirection";

class Live extends Component {
  state = {
    bottomPosition: new Animated.Value(-60),
    refresh: false,
    allChecked: true,
    communityChecked: false,
    myChecked: false,
    menuOpen: false
  };

  getLiveEvents = (page, filter) => {
    this.props.fetchGetLiveEvents(page, filter);
  };

  onClickFilter = () => {
    const { displayFilterOptions } = this.props;
    LayoutAnimation.easeInEaseOut();
    this.props.dispatch({
      type: SET_DISPLAY_FILTER_OPTION,
      payload: !displayFilterOptions
    });
  };

  switchCheckbox(filter) {
    this.setState({
      allChecked: !filter || filter === DISPLAY_ALL,
      communityChecked: filter && filter === DISPLAY_ONLY_COMMUNITY_POSTS,
      myChecked: filter && filter === DISPLAY_ONLY_MY_POSTS
    });
    setTimeout(this.hideFilter, 1000);
    this.props.dispatch({
      type: SET_FILTER,
      payload: filter ? filter : DISPLAY_ALL
    });
    this.props.dispatch({
      type: SET_DISPLAY_FILTER_OPTION,
      payload: filter && filter !== DISPLAY_ALL
    });
  }

  async componentDidMount() {
    this.interval = setInterval(() => {
      let { refresh } = this.state;
      this.setState({ refresh: !refresh });
    }, 50000);

    this.props.dispatch({
      type: SET_ON_CLICK_FILTER,
      payload: this.onClickFilter
    });

    let filter = await AsyncStorage.getItem("filter");
    this.switchCheckbox(filter);
    this.getLiveEvents(this.props.page, filter);
  }

  hideFilter = () => {
    LayoutAnimation.easeInEaseOut();
    this.props.dispatch({
      type: SET_DISPLAY_FILTER_OPTION,
      payload: false
    });
  };

  componentWillUnmount() {
    clearTimeout(this.interval);
  }

  componentDidUpdate(prevProps, prevState) {
    const { reload, filter } = this.props;

    if (reload && reload !== 1 && reload > prevProps.reload) {
      this.getLiveEvents(1, filter);
    }
  }

  renderLiveEvents({ item }) {
    const {
      _id,
      groupId,
      eventId,
      templateId,
      goodDealId,
      event,
      userId,
      dateAdded: date,
      interestedUsersIds
    } = item;
    const {
      dispatch,
      locations,
      equipments,
      templateDates,
      templateTimes,
      templates,
      users,
      interestedusers,
      user,
      lang,
      datetimeDiff,
      photoUri,
      shareEventId,
      sharePhotoUri
    } = this.props;
    const { refresh } = this.state;

    const usersanswers = {};
    if (interestedUsersIds) {
      for (const interestedUserId of interestedUsersIds) {
        if (interestedusers[interestedUserId]) {
          usersanswers[interestedUserId] = interestedusers[interestedUserId];
        } else if (interestedUserId === user._id) {
          usersanswers[interestedUserId] = user;
        }
      }
    }
    switch (groupId) {
      case GROUP_ID_WEATHER: {
        const props = {
          datetimeDiff,
          lang,
          dispatch,
          _id,
          photoUri,
          refresh
        };
        const itemProps = {
          groupId,
          event,
          date
        };

        return (
          <LiveWeather
            {...props}
            {...itemProps}
            key={_id}
            from={users[userId]}
          />
        );
      }

      case GROUP_ID_GOODDEAL_ACTIVITY:
      case GROUP_ID_ACTIVITY:
      case GROUP_ID_PROPOSAL:
      case GROUP_ID_TEMPLATE:
      case GROUP_ID_PUBLISH: {
        // If template doesn't exist, we don't display the event to avoid "Cannot read property 'lang' of undefined
        if (templateId && !templates[templateId]) {
          return null;
        }
        const props = {
          dispatch,
          locations,
          equipments,
          templateDates,
          templateTimes,
          templates,
          datetimeDiff,
          lang,
          user,
          sharePhotoUri: shareEventId === _id ? sharePhotoUri : undefined
        };
        const itemProps = {
          _id,
          groupId,
          eventId,
          event,
          date
        };
        return (
          <LiveEvent
            {...props}
            {...itemProps}
            key={_id}
            from={users[userId]}
            template={templates[templateId]}
            usersanswers={usersanswers}
            goodDeal={goodDealId}
            refresh={refresh}
          />
        );
      }
      default:
        return null;
    }
  }

  handleScroll = event => {
    const y = event.nativeEvent.contentOffset.y;
    const { dispatch, liveOnTop } = this.props;
    const { bottomPosition } = this.state;
    if (y > 140) {
      if (bottomPosition.__getValue() === -60) {
        Animated.timing(bottomPosition, {
          toValue: 25,
          duration: 400,
          easing: Easing.linear
        }).start();
        if (liveOnTop) {
          dispatch({
            type: LIVE_ON_TOP,
            value: false
          });
        }
      }
    } else if (bottomPosition.__getValue() !== -60) {
      Animated.timing(bottomPosition, {
        toValue: -60,
        duration: 400,
        easing: Easing.linear
      }).start();
      if (!liveOnTop) {
        dispatch({
          type: LIVE_ON_TOP,
          value: true
        });
      }
    }
  };

  _displayLoading() {
    if (this.props.isFetching) {
      return (
        <View style={styles.loading_container}>
          <ActivityIndicator size="large" color={COLOR2} />
        </View>
      );
    }
  }

  displayFilteredEvents = async filter => {
    await AsyncStorage.setItem("filter", filter);
    this.switchCheckbox(filter);
    this.getLiveEvents(1, filter);
  };

  render() {
    const {
      bgColor1,
      flex1,
      alignItemsCenter,
      justifyContentCenter,
      spaceBetween,
      w100p,
      bgWhite,
      color5,
      fontBold,
      fs16,
      mb10,
      pv10,
      ml10,
      mt5,
      shadowGrey,
      darkgrey,
      positionAbsolute,
      bgColor5,
      ph10
    } = commonStyles;

    let {
      bottomPosition,
      refresh,
      allChecked,
      myChecked,
      menuOpen
    } = this.state;

    let {
      hasNextPage,
      nextPage,
      user,
      navigation,
      community,
      events,
      displayFilterOptions,
      filter
    } = this.props;

    return (
      <Animated.View style={[flex1, spaceBetween, alignItemsCenter, bgColor1]}>
        <LinkRedirection />
        {displayFilterOptions && (
          <View style={[bgWhite, w100p, pv10, shadowGrey]}>
            <Text style={[ml10, mb10, fontBold, darkgrey]}>
              {t("filter:display")}
            </Text>
            <CheckBox
              title={t("filter:all")}
              textStyle={[color5, fontBold]}
              checkedIcon="dot-circle-o"
              uncheckedIcon="circle-o"
              checked={allChecked}
              onPress={() => this.displayFilteredEvents(DISPLAY_ALL)}
            />
            <CheckBox
              title={t("filter:my")}
              textStyle={[color5, fontBold]}
              checkedIcon="dot-circle-o"
              uncheckedIcon="circle-o"
              checked={myChecked}
              onPress={() => this.displayFilteredEvents(DISPLAY_ONLY_MY_POSTS)}
            />
          </View>
        )}
        <FlatList
          showsVerticalScrollIndicator={false}
          style={[mt5, flex1, w100p]}
          contentContainerStyle={{
            paddingBottom: 90 + Constants.statusBarHeight
          }}
          extraData={refresh}
          data={events}
          renderItem={this.renderLiveEvents.bind(this)}
          keyExtractor={event => event._id}
          onScroll={this.handleScroll}
          onEndReachedThreshold={0.5}
          onMomentumScrollBegin={() => {
            this.onEndReachedCalledDuringMomentum = false;
          }}
          onEndReached={() => {
            if (hasNextPage && !this.onEndReachedCalledDuringMomentum) {
              this.onEndReachedCalledDuringMomentum = true;
              this.getLiveEvents(nextPage, filter);
            }
          }}
          ref="listRef"
        />
        {this._displayLoading()}

        <ActionButton
          buttonColor={menuOpen ? COLOR5 : COLOR2}
          renderIcon={() => <FontAwesome name="plus" size={40} color="white" />}
          onPressIn={() => this.setState({ menuOpen: true })}
          onReset={() => this.setState({ menuOpen: false })}
        >
          {user &&
            (user.role === ROLE_ADMINISTRATOR ||
              user.role === ROLE_WEBMASTER ||
              user.role === ROLE_EMPLOYEE) && (
              <ActionButton.Item
                buttonColor={COLOR2}
                title={t("button:posttemplate")}
                onPress={() => navigation.navigate("SelectTemplateScreen")}
                textStyle={[fontBold, fs16]}
              >
                <FontAwesome5 name="newspaper" size={36} color="white" />
              </ActionButton.Item>
            )}
          {((community && community.acceptPosts) ||
            (user &&
              (user.role === ROLE_ADMINISTRATOR ||
                user.role === ROLE_WEBMASTER ||
                user.role === ROLE_EMPLOYEE))) && (
            <ActionButton.Item
              buttonColor={COLOR2}
              title={t("button:createpost")}
              onPress={() => navigation.navigate("PostScreen")}
              textStyle={[fontBold, fs16]}
            >
              <MaterialCommunityIcons
                name="camera-enhance"
                size={34}
                color="white"
              />
            </ActionButton.Item>
          )}
          <ActionButton.Item
            buttonColor={COLOR2}
            title={t("button:needservice")}
            onPress={() => navigation.navigate("SelectProposalScreen")}
            textStyle={[fontBold, fs16]}
          >
            <FontAwesome5
              // style={{ marginBottom: 15 }}
              name="hand-holding-heart"
              size={30}
              color={"white"}
            />
          </ActionButton.Item>
          <ActionButton.Item
            buttonColor={COLOR2}
            title={t("button:instantsharing")}
            onPress={() => navigation.navigate("SelectActivityScreen")}
            textStyle={[fontBold, fs16]}
          >
            <MaterialCommunityIcons
              name="heart-multiple"
              size={36}
              color="white"
            />
          </ActionButton.Item>
        </ActionButton>
        <Animated.View
          style={[
            positionAbsolute,
            {
              alignSelf: "center",
              bottom: bottomPosition
            }
          ]}
        >
          <TouchableOpacity
            onPress={() => {
              this.refs.listRef.scrollToOffset({
                x: 0,
                y: 0,
                animated: true
              });
            }}
            style={[
              bgColor5,
              {
                height: 50,
                width: 50,
                borderRadius: 25
              },
              justifyContentCenter,
              alignItemsCenter,
              ph10
            ]}
          >
            <Entypo name="chevron-up" color="white" size={45} />
          </TouchableOpacity>
        </Animated.View>
      </Animated.View>
    );
  }
}

const styles = StyleSheet.create({
  loading_container: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 100,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center"
  }
});

const mapStateToProps = state => {
  const {
    events,
    isFetching,
    liveOnTop,
    reload,
    page,
    nextPage,
    hasNextPage,
    users,
    interestedusers,
    userId,
    filter,
    sharePhotoUri,
    shareEventId,
    displayFilterOptions
  } = state.liveReducer;

  const { user } = state.profileReducer;
  const { communityDetails } = state.communityReducer;
  const { templates, locations } = state.entitiesReducer;
  const { equipments, templateDates, templateTimes } = state.templateReducer;

  const { lang, datetimeDiff } = state.globalInformationsReducer;
  const { photoUri } = state.meteoReducer;
  return {
    events: Object.values(events),
    isFetching,
    liveOnTop,
    reload,
    page,
    nextPage,
    hasNextPage,
    userId,
    filter,
    users,
    interestedusers,
    templates,
    locations,
    equipments,
    templateDates,
    templateTimes,
    lang,
    datetimeDiff,
    user,
    photoUri,
    shareEventId,
    sharePhotoUri,
    gender: state.profileReducer.user ? state.profileReducer.user.gender : null,
    community: communityDetails,
    displayFilterOptions
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators({ fetchGetLiveEvents }, dispatch);
  return { ...actions, dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(Live);
