import React from "react";
import {
  ActivityIndicator,
  FlatList,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Avatar, Badge, Button } from "react-native-elements";
import { fetchGetChatNeighbors } from "../actions/chat";
import commonStyles, { WHITE_COLOR, COLOR2 } from "../styles/commonStyles";
import { t } from "../services/i18n";
import {
  SET_DROP_DOWN_ALERT_WARN,
  RESET_AND_SUBSCRIBE,
  LOGOUT
} from "../actions/types";
import { ROLE_GUEST, ROLE_WEBMASTER } from "../config/constants";
import { getImage } from "../assets/Images";
import { useSelector, useDispatch } from "react-redux";

export default function ChatNeighbors(props) {
  const dispatch = useDispatch();
  const navigate = props?.navigation?.navigate;

  const { isFetchingDataNeighbors, dataNeighbors } = useSelector(
    state => state.chatReducer
  );

  const { user } = useSelector(state => state.profileReducer);

  React.useEffect(() => {
    if (user && user.role !== ROLE_GUEST) {
      dispatch(fetchGetChatNeighbors());
    }
  }, []);

  const renderUser = record => {
    const {
      bgWhite,
      row,
      w100p,
      font,
      flex1,
      alignItemsCenter,
      justifyContentCenter,
      p5,
      mv10,
      shadowGrey,
      bgLightgrey,
      bgColor2,
      darkgrey,
      rounded30,
      rounded22,
      mv5,
      p10,
      pr10,
      h60,
      mb10,
      h44
    } = commonStyles;
    const { _id, firstname, photoUri, notRead, online, gender } = record;

    return (
      <View style={[w100p, bgWhite, mv10, p10, shadowGrey, mv5]}>
        <View
          style={[bgLightgrey, row, h60, rounded30, alignItemsCenter, pr10]}
        >
          <TouchableOpacity
            onPress={() =>
              navigate("ProfileScreen", {
                userId: _id
              })
            }
          >
            <View>
              <Avatar
                rounded
                size={60}
                source={
                  photoUri
                    ? { uri: photoUri }
                    : gender === "F"
                    ? getImage("avatarF")
                    : getImage("avatar")
                }
                title={firstname.toUpperCase().substr(0, 2)}
                activeOpacity={0.7}
              />
              {Boolean(notRead || (user && user.role === ROLE_WEBMASTER)) && (
                <Badge
                  status={
                    user && user.role === ROLE_WEBMASTER
                      ? online
                        ? "success"
                        : "error"
                      : "error"
                  }
                  value={notRead ? notRead : " "}
                  containerStyle={{ position: "absolute", top: -4, right: -4 }}
                />
              )}
            </View>
          </TouchableOpacity>
          <View style={[justifyContentCenter, flex1, p5]}>
            <TouchableOpacity
              onPress={() => {
                if (user && user.role !== ROLE_GUEST) {
                  navigate("ChatScreen", {
                    to: _id,
                    title: firstname
                  });
                } else {
                  dispatch({
                    type: SET_DROP_DOWN_ALERT_WARN,
                    warn: "guestforbidden"
                  });
                }
              }}
            >
              <Text style={[font, darkgrey, { fontSize: 15 }, mb10]}>
                {firstname}
              </Text>
            </TouchableOpacity>
          </View>
          <Button
            icon={
              <MaterialCommunityIcons
                name={"chat"}
                size={28}
                color={WHITE_COLOR}
              />
            }
            buttonStyle={[bgColor2, h44, rounded22]}
            onPress={() => {
              if (user && user.role !== ROLE_GUEST) {
                navigate("ChatScreen", {
                  to: _id,
                  title: firstname
                });
              } else {
                dispatch({
                  type: SET_DROP_DOWN_ALERT_WARN,
                  warn: "guestforbidden"
                });
              }
            }}
          />
        </View>
      </View>
    );
  };

  const {
    bgColor1,
    flex1,
    alignItemsCenter,
    justifyContentCenter,
    w100p,
    font,
    bgColor2,
    bgWhite,
    rounded5,
    darkgrey,
    mh10,
    p10,
    shadowGrey,
    textCenter,
    h44,
    rounded22,
    white,
    fontBold
  } = commonStyles;

  return (
    <View style={[flex1, bgColor1]}>
      {user && user.role === ROLE_GUEST ? (
        <View style={[flex1, justifyContentCenter, alignItemsCenter]}>
          <View style={[bgWhite, mh10, rounded5, shadowGrey, alignItemsCenter]}>
            <Text style={[font, darkgrey, textCenter, p10]}>
              {t("chat:coneighborguest")}
            </Text>
            <Button
              buttonStyle={[bgColor2, h44, rounded22, { width: 120 }]}
              containerStyle={[p10]}
              activeOpacity={0.8}
              title={t("button:signup")}
              onPress={() =>
                dispatch({
                  type: LOGOUT,
                  value: RESET_AND_SUBSCRIBE
                })
              }
              titleStyle={[white, fontBold, { fontSize: 16 }]}
            />
          </View>
        </View>
      ) : isFetchingDataNeighbors ? (
        <ActivityIndicator
          style={[flex1, justifyContentCenter]}
          size="large"
          color={COLOR2}
        />
      ) : (
        <FlatList
          style={[flex1, w100p]}
          data={dataNeighbors}
          renderItem={({ item }) => renderUser(item)}
          keyExtractor={item => item._id}
        />
      )}
    </View>
  );
}
