import Constants from "expo-constants";
import React, { Component, Fragment } from "react";
import { ActivityIndicator, ScrollView, Text, View } from "react-native";
import { Avatar } from "react-native-elements";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchGetUserProfile,
  fetchBlockUser,
  fetchUnblockUser,
  fetchFlagInappropriateContent
} from "../actions/profile";
import { SET_DROP_DOWN_ALERT_WARN } from "../actions/types";
import {
  ROLE_ADVERTISER,
  ROLE_NEIGHBOR,
  ROLE_ADMINISTRATOR,
  ROLE_WEBMASTER,
  ROLE_EMPLOYEE,
  ROLE_GUEST
} from "../config/constants";
import Hobbies from "../components/Hobbies";
import { t } from "../services/i18n";
import commonStyles, {
  COLOR5,
  COLOR2,
  PINK_COLOR,
  BLUE_COLOR,
  ORANGE_COLOR
} from "../styles/commonStyles";
import ConfirmationModal from "../components/ConfirmationModal";
import { openURL } from "../utils/UrlUtil";
import ActionButton from "../components/actionButton/ActionButton";
import { getImage } from "../assets/Images";
const IMAGE_SIZE = 200;
import { MaterialCommunityIcons, FontAwesome } from "@expo/vector-icons";

class Profile extends Component {
  state = {
    fetched: false,
    description: "",
    buttonConfirm: "",
    showConfirmationModal: false,
    onConfirmation: this.blockUser,
    buttonColor: "red"
  };

  constructor(props) {
    super(props);
    this.onConfirmationModalClosed = this.onConfirmationModalClosed.bind(this);
  }

  showConfirmationModal() {
    this.setState({ showConfirmationModal: true });
  }

  onConfirmationModalClosed() {
    this.setState({ showConfirmationModal: false });
  }

  blockUser = () => {
    this.onConfirmationModalClosed();
    this.props.fetchBlockUser(this.props.route.params.userId);
  };

  unblockUser = () => {
    this.onConfirmationModalClosed();
    this.props.fetchUnblockUser(this.props.route.params.userId);
  };

  flagContent = () => {
    this.onConfirmationModalClosed();
    this.props.fetchFlagInappropriateContent(this.props.route.params.userId);
  };

  componentDidMount() {
    this.props.fetchGetUserProfile(this.props.route?.params?.userId);
    this.setState({ fetched: true });
  }

  render() {
    const {
      mv20,
      bgWhite,
      color5,
      color2,
      bgColor1,
      flex1,
      alignItemsCenter,
      justifyContentCenter,
      fs16,
      fs20,
      mb10,
      mb20,
      font,
      fontBold,
      textCenter,
      row,
      w100p,
      p10,
      shadowGrey,
      mt10,
      alignItemsFlexEnd
    } = commonStyles;

    const {
      dispatch,
      isFetching,
      photoUri,
      pseudo,
      firstname,
      lastname,
      gender,
      floor,
      buildingAccess,
      apartmentNumber,
      job,
      hobbiesIds,
      to,
      role,
      myRole,
      description,
      navigation,
      blockedUsersIds,
      phone,
      company,
      route
    } = this.props;

    const {
      onConfirmation,
      showConfirmationModal,
      buttonConfirm,
      buttonColor,
      fetched
    } = this.state;

    return (
      <View style={[flex1]}>
        <ConfirmationModal
          description={this.state.description}
          onClosed={this.onConfirmationModalClosed}
          title={t("menu:confirmation")}
          showConfirmationModal={showConfirmationModal}
          onConfirmation={onConfirmation}
          isFetching={isFetching}
          buttonConfirm={buttonConfirm}
          buttonColor={buttonColor}
        />
        {isFetching || !fetched ? (
          <ActivityIndicator
            style={[flex1, justifyContentCenter]}
            size="large"
            color={COLOR2}
          />
        ) : (
          <ScrollView
            style={[flex1, bgColor1]}
            contentContainerStyle={{
              paddingBottom: Constants.statusBarHeight + 70
            }}
          >
            <View
              style={[
                w100p,
                p10,
                bgWhite,
                mt10,
                mb20,
                shadowGrey,
                alignItemsCenter
              ]}
            >
              <Text style={[fontBold, color5, fs20, mv20]}>
                {t("profile:profile")}
              </Text>

              <View style={[row, mb20, alignItemsFlexEnd]}>
                <Avatar
                  rounded
                  size={IMAGE_SIZE}
                  title={
                    role === ROLE_ADVERTISER && company && company.length >= 2
                      ? company.toUpperCase().substr(0, 2)
                      : firstname.toUpperCase().substr(0, 2)
                  }
                  source={
                    photoUri
                      ? {
                          uri: photoUri
                        }
                      : company
                      ? undefined
                      : gender === "F"
                      ? getImage("avatarF")
                      : getImage("avatar")
                  }
                />
              </View>

              {role === ROLE_ADVERTISER ? (
                <Text style={[fontBold, color2, fs20, mb10]}>
                  {`${company}`}
                </Text>
              ) : role !== ROLE_NEIGHBOR ||
                myRole === ROLE_EMPLOYEE ||
                myRole === ROLE_ADMINISTRATOR ||
                myRole === ROLE_WEBMASTER ? (
                <Fragment>
                  <Text
                    style={[
                      fontBold,
                      gender === "M"
                        ? { color: BLUE_COLOR }
                        : { color: PINK_COLOR },
                      fs20,
                      mb10
                    ]}
                  >
                    {`${firstname} ${lastname}`}
                  </Text>
                  {pseudo?.indexOf("@") < 0 && (
                    <Text style={[font, mb10]}>[{pseudo}]</Text>
                  )}
                </Fragment>
              ) : (
                <View style={row}>
                  <Text
                    style={[
                      fontBold,
                      gender === "M"
                        ? { color: BLUE_COLOR }
                        : { color: PINK_COLOR },
                      fs20,
                      mb10
                    ]}
                  >{`${firstname} ${lastname}`}</Text>
                </View>
              )}
              {floor && (
                <Text style={[font, mb10, textCenter]}>
                  {role === ROLE_NEIGHBOR && t("profile:lives")}
                  {floor === "0"
                    ? "RC"
                    : floor === "1"
                    ? `1er ${t("profile:floor").toLowerCase()}`
                    : `${floor}${t("profile:eme")} ${t(
                        "profile:floor"
                      ).toLowerCase()}`}
                </Text>
              )}
              {buildingAccess && (
                <Text style={[font, mb10, textCenter]}>
                  {`${floor ? "" : t("profile:lives")}${t(
                    "profile:buildingaccess"
                  )} ${buildingAccess}`}
                </Text>
              )}
              {apartmentNumber && (
                <Text style={[font, mb10, textCenter]}>
                  {`${floor || buildingAccess ? "" : t("profile:lives")}${t(
                    "profile:apartmentnumber"
                  )} ${apartmentNumber}`}
                </Text>
              )}
              {Boolean(job) && (
                <Text style={[font, mb10, textCenter]}>
                  {`${t("profile:job")} ${job}`}
                </Text>
              )}
              {Boolean(description) && (
                <Text style={[fontBold, color2, mb20]}>{description}</Text>
              )}
            </View>

            {role === ROLE_NEIGHBOR && (
              <Fragment>
                {hobbiesIds.length > 0 && (
                  <View
                    style={[
                      w100p,
                      p10,
                      bgWhite,
                      mb20,
                      shadowGrey,
                      alignItemsCenter
                    ]}
                  >
                    <Text style={[fontBold, color5, fs20, mv20]}>
                      {t("profile:hobbies")}
                    </Text>
                    <Hobbies hobbiesIds={new Set(hobbiesIds)} readOnly={true} />
                  </View>
                )}
              </Fragment>
            )}
          </ScrollView>
        )}
        <ActionButton
          buttonColor={COLOR5}
          degrees={90}
          renderIcon={() => (
            <MaterialCommunityIcons
              name="dots-horizontal"
              size={40}
              color="white"
            />
          )}
        >
          {Boolean(
            (!role || role === ROLE_NEIGHBOR) && myRole !== ROLE_GUEST
          ) && (
            <ActionButton.Item
              buttonColor={
                blockedUsersIds.has(route.params.userId) ? PINK_COLOR : "red"
              }
              title={
                blockedUsersIds.has(route.params.userId)
                  ? `${t("button:unblock")} ${firstname}`
                  : `${t("button:block")} ${firstname}`
              }
              onPress={() =>
                this.setState({
                  showConfirmationModal: true,
                  description: blockedUsersIds.has(route.params.userId)
                    ? t("profile:confirmunblockuser")
                    : t("profile:confirmblockuser"),
                  buttonConfirm: blockedUsersIds.has(route.params.userId)
                    ? t("button:unblock")
                    : t("button:block"),
                  onConfirmation: blockedUsersIds.has(route.params.userId)
                    ? this.unblockUser
                    : this.blockUser,
                  buttonColor: blockedUsersIds.has(route.params.userId)
                    ? PINK_COLOR
                    : "red"
                })
              }
              textStyle={[fontBold, fs16]}
            >
              <FontAwesome name="ban" size={34} color="white" />
            </ActionButton.Item>
          )}
          {Boolean(
            (!role || role === ROLE_NEIGHBOR || role === ROLE_ADVERTISER) &&
              myRole !== ROLE_GUEST
          ) && (
            <ActionButton.Item
              buttonColor={ORANGE_COLOR}
              title={t("button:flagcontent")}
              onPress={() =>
                this.setState({
                  showConfirmationModal: true,
                  description: t("profile:confirmflag"),
                  buttonConfirm: t("button:flag"),
                  onConfirmation: this.flagContent,
                  buttonColor: ORANGE_COLOR
                })
              }
              textStyle={[fontBold, fs16]}
            >
              <MaterialCommunityIcons
                name="alert-decagram"
                size={34}
                color="white"
              />
            </ActionButton.Item>
          )}
          {!blockedUsersIds.has(route.params.userId) &&
            role !== ROLE_ADVERTISER && (
              <ActionButton.Item
                buttonColor={COLOR2}
                title={`${t("button:contact")} ${firstname}`}
                onPress={() => {
                  if (myRole !== ROLE_GUEST) {
                    navigation.navigate("ChatScreen", {
                      to,
                      title: pseudo
                    });
                  } else {
                    dispatch({
                      type: SET_DROP_DOWN_ALERT_WARN,
                      warn: "guestforbidden"
                    });
                  }
                }}
                textStyle={[fontBold, fs16]}
              >
                <MaterialCommunityIcons name={"chat"} size={34} color="white" />
              </ActionButton.Item>
            )}
          {Boolean(phone) && (
            <ActionButton.Item
              buttonColor={COLOR2}
              title={`${t("button:call")} ${phone}`}
              onPress={() => openURL(dispatch, "telprompt:" + phone)}
              textStyle={[fontBold, fs16]}
            >
              <MaterialCommunityIcons name="phone" size={40} color="white" />
            </ActionButton.Item>
          )}
        </ActionButton>
      </View>
    );
  }
}

const mapStateToProps = state => {
  const {
    isFetching,
    photoUri,
    hobbiesIds,
    pseudo,
    firstname,
    lastname,
    age,
    gender,
    city,
    floor,
    apartmentNumber,
    job,
    zipCode,
    description,
    countryCode,
    type,
    startDate,
    endDate,
    _id,
    role,
    communityId,
    user,
    phone,
    company,
    buildingAccess
  } = state.profileReducer;

  return {
    isFetching,
    title: role === ROLE_ADVERTISER ? company : firstname,
    photoUri,
    hobbiesIds,
    pseudo,
    firstname,
    lastname,
    age,
    description,
    gender,
    city,
    floor,
    apartmentNumber,
    job,
    zipCode,
    countryCode,
    type,
    startDate,
    endDate,
    to: _id,
    role,
    myRole: user ? user.role : null,
    community: communityId
      ? state.entitiesReducer.communities[communityId]
      : null,
    blockedUsersIds: user ? new Set(user.blockedUsersIds) : new Set(),
    phone,
    company,
    buildingAccess
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    {
      fetchGetUserProfile,
      fetchBlockUser,
      fetchUnblockUser,
      fetchFlagInappropriateContent
    },
    dispatch
  );
  return { ...actions, dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
