import * as ScreenOrientation from "expo-screen-orientation";
import Constants from "expo-constants";
import React, { Component } from "react";
import { Dimensions, Image, Text, View } from "react-native";
import ImageZoom from "react-native-web-image-pan-zoom";
import { t } from "../services/i18n";
import commonStyles, { isWeb } from "../styles/commonStyles";

export default class ImgZoom extends Component {
  _mounted = false;

  state = {
    imageWidth: 0,
    imageHeight: 0,
    portrait: true,
    acceptPortrait: true
  };

  componentDidMount = async () => {
    if (
      !isWeb &&
      (await ScreenOrientation.supportsOrientationLockAsync(
        ScreenOrientation.OrientationLock.ALL
      ))
    ) {
      await ScreenOrientation.unlockAsync(
        ScreenOrientation.OrientationLock.ALL
      );

      this.setState({
        acceptPortrait: await ScreenOrientation.supportsOrientationLockAsync(
          ScreenOrientation.OrientationLock.PORTRAIT
        )
      });
    }

    this._mounted = true;
  };

  componentWillUnmount = async () => {
    if (
      !isWeb &&
      (await ScreenOrientation.supportsOrientationLockAsync(
        ScreenOrientation.OrientationLock.PORTRAIT
      ))
    ) {
      await ScreenOrientation.lockAsync(
        ScreenOrientation.OrientationLock.PORTRAIT
      );
    }
    this._mounted = false;
  };

  onLayout(e) {
    const { uri } = this.props.route.params;

    if (e.nativeEvent.layout.height > e.nativeEvent.layout.width) {
      Image.getSize(uri, (width, height) => {
        let proportion = height / width;
        let imageWidth = Dimensions.get("window").width;
        let imageHeight = Math.ceil(imageWidth * proportion);
        if (this._mounted || this.state.imageWidth === 0) {
          this.setState({ imageWidth, imageHeight, portrait: true });
        }
      });
    } else {
      Image.getSize(uri, (width, height) => {
        let proportion = height / width;
        let imageHeight = Dimensions.get("window").height;
        let imageWidth = Math.ceil(imageHeight / proportion);
        if (this._mounted || this.state.imageWidth === 0) {
          this.setState({ imageWidth, imageHeight, portrait: false });
        }
      });
    }
  }

  render() {
    const { flex1, bgColor1, color5, font } = commonStyles;
    const { uri } = this.props.route?.params;
    const { imageHeight, imageWidth, portrait } = this.state;
    return (
      <View style={[flex1, bgColor1]} onLayout={this.onLayout.bind(this)}>
        {portrait && (
          <Text
            style={[
              font,
              color5,
              { fontSize: 18, marginLeft: 10, marginTop: 15 }
            ]}
          >
            {t("template:zoom")}
          </Text>
        )}
        <ImageZoom
          cropWidth={Dimensions.get("window").width}
          cropHeight={
            Dimensions.get("window").height -
            (portrait ? 56 + 15 + 18 + Constants.statusBarHeight : 0)
          }
          imageWidth={imageWidth}
          imageHeight={imageHeight}
        >
          <Image
            style={{ height: imageHeight, width: imageWidth }}
            source={uri}
          />
        </ImageZoom>
      </View>
    );
  }
}
