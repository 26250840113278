import { Asset } from "expo-asset";
import * as Font from "expo-font";
import * as Localization from "expo-localization";
import AppLoading from "expo-app-loading";
import React, { useState } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  Image,
  Platform,
  StatusBar,
  Text,
  UIManager,
  LogBox
} from "react-native";
import { Provider } from "react-redux";
import { SET_LANG } from "./src/actions/types";
import { Images } from "./src/assets/Images";
import * as config from "./src/config/i18n";
import i18n from "./src/services/i18n";
import configureStore from "./src/store/configure-store";
import { FONT_BOLD, FONT_LIGHT, FONT } from "./src/styles/commonStyles";
import * as Sentry from "sentry-expo";
import { SENTRY_DSN } from "./src/config/constants";
import AppNavigationContainer from "./src/navigation/AppNavigationContainer";
import { initExpoBranch } from "./BranchIO";

if (__DEV__) {
  import("./ReactotronConfig").then(() => console.log("Reactotron Configured"));
}

// Add Google maps api
const script = document.createElement("script");
// script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_NATIVE_APP_GOOGLE_MAP_API_KEY}`;
script.src =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyDoTNc45o-X3V5iSYUqjKgr2tkv4Wh_9sc";
script.async = true;
document.body.appendChild(script);

StatusBar.setBarStyle("light-content");
if (Platform.OS === "android") {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

LogBox.ignoreLogs([
  "VirtualizedLists should never be nested",
  "Warning: componentWillReceiveProps has been renamed",
  "Warning: componentWillMount has been renamed"
]);

const store = configureStore();

function cacheImages(images) {
  return images.map(image => {
    if (typeof image === "string") {
      return Image.prefetch(image);
    } else {
      return Asset.fromModule(image).downloadAsync();
    }
  });
}

function cacheFonts(fonts) {
  return fonts.map(font => Font.loadAsync(font));
}

Sentry.init({
  dsn: SENTRY_DSN,
  enableInExpoDevelopment: false,
  debug: false,
  enableNative: false
});

async function _loadAssetsAsync() {
  await i18n.init();

  // Disable font scaling for Android
  Text.defaultProps = Text.defaultProps || {};
  Text.defaultProps.allowFontScaling = false;

  // Branch IO init
  await initExpoBranch();

  const lng = await Localization.getLocalizationAsync();
  store.dispatch({
    type: SET_LANG,
    lang: config.supportedLocales[i18n.locale] ? i18n.locale : config.fallback,
    country: lng && lng.region ? lng.region : "FR"
  });

  const imageAssets = cacheImages(Object.values(Images));

  const fontAssets = cacheFonts([
    {
      fontBold: FONT_BOLD,
      fontLight: FONT_LIGHT,
      font: FONT
    }
  ]);
  await Promise.all([...imageAssets, ...fontAssets]);
}

export default function App() {
  const [isReady, setReady] = useState(false);
  if (!isReady) {
    return (
      <AppLoading
        startAsync={_loadAssetsAsync}
        onFinish={() => setReady(true)}
        onError={console.warn}
        autoHideSplash={false}
      />
    );
  }

  return (
    <SafeAreaProvider>
      <Provider store={store}>
        <AppNavigationContainer store={store} />
      </Provider>
    </SafeAreaProvider>
  );
}
