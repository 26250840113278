import {
  API_START,
  API_END,
  WS_EVENT,
  CHAT,
  OFFLINE,
  ONLINE,
  WS_CONNECTED,
  FETCH_GET_EVENT_INTERESTED_USERS,
  SET_EVENT_INTERESTED_USERS_RESULT,
  SHARE_POST,
  CLEAR_SHARE_POST,
  LIVE_INTERESTED,
  LIVE_UNINTERESTED,
  NAVIGATION
} from "../actions/types";

const initialState = {
  isFetching: false,
  event: null,
  users: {},
  reload: 0,
  inChatWith: null,
  share: false
};

export default function eventInterestedUsersReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_GET_EVENT_INTERESTED_USERS) {
        return {
          ...state,
          isFetching: true,
          event: null,
          interestedUsersIds: {},
          share: false
        };
      }
      break;

    case WS_CONNECTED:
      if (state.data) {
        return {
          ...state,
          reload: ++state.reload
        };
      }
      break;

    case CLEAR_SHARE_POST:
      if (state.event && state.event.userId) {
        return {
          ...state,
          share: false
        };
      }
      return {
        ...state,
        share: false,
        event: null
      };

    case SHARE_POST:
      if (action.event) {
        return {
          ...state,
          share: true,
          event: action.event
        };
      }
      return {
        ...state,
        share: true
      };

    case SET_EVENT_INTERESTED_USERS_RESULT:
      if (
        action.entities &&
        action.entities.event &&
        Object.values(action.entities.event).length
      ) {
        return {
          ...state,
          event: { ...Object.values(action.entities.event)[0] },
          users: { ...action.entities.users }
        };
      }
      break;

    case NAVIGATION:
      if (state.users && action.name === "ChatScreen") {
        // Reset notRead because we enter chatMessage view
        if (state.users[action.params.to]) {
          const users = { ...state.users };
          users[action.params.to] = {
            ...state.users[action.params.to],
            notRead: 0
          };

          return {
            ...state,
            users,
            inChatWith: action.params.to
          };
        } else {
          return {
            ...state,
            inChatWith: action.params.to
          };
        }
      }
      if (action.name === "ChatUsersScreen") {
        return {
          ...state,
          inChatWith: null
        };
      }
      break;

    case WS_EVENT:
      if (action.payload.type === CHAT) {
        // We receive a new message update notRead only if we are not in the ChatScreen
        if (!state.inChatWith || state.inChatWith !== action.payload.from._id) {
          if (state.users[action.payload.from._id]) {
            const users = { ...state.users };
            users[action.payload.from._id] = {
              ...state.users[action.payload.from._id],
              notRead: ++users[action.payload.from._id].notRead
            };
            return {
              ...state,
              users
            };
          } else {
            // new user: we refresh
            return {
              ...state,
              reload: ++state.reload
            };
          }
        }
      } else if (
        action.payload.type === OFFLINE ||
        action.payload.type === ONLINE
      ) {
        if (state.users[action.payload.userId]) {
          const users = { ...state.users };
          users[action.payload.userId] = {
            ...state.users[action.payload.userId],
            online: action.payload.type === ONLINE ? true : false
          };
          return {
            ...state,
            users
          };
        }
      } else if (
        action.payload.type === LIVE_INTERESTED ||
        action.payload.type === LIVE_UNINTERESTED
      ) {
        return {
          ...state,
          reload: ++state.reload
        };
      }
      break;

    case API_END:
      if (action.payload === FETCH_GET_EVENT_INTERESTED_USERS) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;

    default:
      return state;
  }
  return state;
}
