import React, { useEffect, useState, useRef } from "react";
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import * as Location from "expo-location";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Camera } from "expo-camera";
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Keyboard,
  KeyboardAvoidingView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Avatar } from "react-native-elements";
import {
  Actions,
  Bubble,
  Day,
  GiftedChat,
  InputToolbar,
  LoadEarlier,
  Send,
  Time,
  MessageText,
  utils
} from "react-native-gifted-chat";
import { useDispatch, useSelector } from "react-redux";
import { fetchExitChat, fetchGetChatMessages } from "../actions/chat";
import {
  CHAT,
  SET_DROP_DOWN_ALERT_INFO,
  SET_CHAT_PHOTO_DATA,
  CLEAR_CHAT_PHOTO_DATA,
  CLEAR_CHAT_MAP_DATA,
  SET_CHAT_CURRENT_LOCATION
} from "../actions/types";
import { getImage, getImageUri } from "../assets/Images";
import { t } from "../services/i18n";
import commonStyles, { COLOR2, isiOS, isWeb } from "../styles/commonStyles";
import "dayjs/locale/fr";
import MapView from "react-native-maps";
import { openURL } from "../utils/UrlUtil";
const { isSameUser, isSameDay } = utils;

const styles = {
  left: StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "flex-start"
    },
    wrapper: {
      borderRadius: 15,
      backgroundColor: "#f0f0f0",
      marginRight: 60,
      minHeight: 20,
      justifyContent: "flex-end"
    },
    containerToNext: {
      borderBottomLeftRadius: 3
    },
    containerToPrevious: {
      borderTopLeftRadius: 3
    },
    bottom: {
      flexDirection: "row",
      justifyContent: "flex-start"
    }
  }),
  right: StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "flex-end"
    },
    wrapper: {
      borderRadius: 15,
      backgroundColor: COLOR2,
      marginLeft: 60,
      minHeight: 20,
      justifyContent: "flex-end"
    },
    containerToNext: {
      borderBottomRightRadius: 3
    },
    containerToPrevious: {
      borderTopRightRadius: 3
    },
    bottom: {
      flexDirection: "row",
      justifyContent: "flex-end"
    }
  }),
  content: StyleSheet.create({
    tick: {
      fontSize: 10,
      backgroundColor: "transparent",
      color: "#fff"
    },
    tickView: {
      flexDirection: "row",
      marginRight: 10
    },
    username: {
      top: -3,
      left: 0,
      fontSize: 12,
      backgroundColor: "transparent",
      color: "#aaa"
    },
    usernameView: {
      flexDirection: "row",
      marginHorizontal: 10
    }
  })
};

const Chat = ({ route, navigation }) => {
  const dispatch = useDispatch();

  const giftedChat = useRef();

  const { user } = useSelector(state => state.profileReducer);

  let _id, pseudo, firstname, photoUri, gender;
  if (user) {
    ({ _id, pseudo, firstname, photoUri, gender } = user);
  }

  const { lang } = useSelector(state => state.globalInformationsReducer);

  let {
    messages,
    isFetching,
    hasNextPage,
    nextPage,
    page,
    image,
    files,
    location
  } = useSelector(state => state.chatReducer);

  const [isVisible, setVisible] = useState(false);
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [title, setTitle] = useState(0);

  if (messages) {
    messages = Object.values(messages);
  }

  const { to, eventId } = route.params;

  const {
    color2,
    color5,
    bgColor1,
    bgColor2,
    bgColor5,
    lightgrey,
    bgWhite,
    white,
    darkgrey,
    flex1,
    justifyContentCenter,
    alignItemsCenter,
    w100p,
    cover,
    font,
    w150,
    h100,
    m10,
    rounded10,
    h44
  } = commonStyles;

  useEffect(() => {
    dispatch(fetchGetChatMessages(1, to, eventId));
    return () => dispatch(fetchExitChat(to, eventId));
  }, []);

  useEffect(() => {
    giftedChat.current?.focusTextInput();
    if (isiOS || (!image && !location)) {
      setTimeout(() => Keyboard.dismiss(), 20);
    }
  }, [image, location]);

  const sendMessage = message => {
    let computedLocation;
    if (location) {
      computedLocation = {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude
      };
    }
    const msg = {
      to,
      eventId,
      text: message.text,
      image,
      files,
      location: computedLocation
    };

    msg.meta = {
      user: {
        _id,
        name: pseudo.indexOf("@") < 0 ? pseudo : firstname,
        avatar: photoUri,
        gender
      },
      websocket: true
    };
    msg.type = CHAT;

    dispatch(msg);
  };

  const renderSend = ({ onSend, text, sendButtonProps, ...props }) => {
    return (
      <Send
        {...props}
        textStyle={Boolean(!text && !image && !location) ? color5 : color2}
        disabled={Boolean(!text && !image && !location)}
        label={t("chat:send")}
        sendButtonProps={{
          ...sendButtonProps,
          onPress: () => onSend({ text: text.trim() }, true)
        }}
      />
    );
  };

  const renderAvatar = props => {
    const { avatar, name, gender } = props.currentMessage?.user;
    return (
      <Avatar
        rounded
        size={40}
        source={
          avatar
            ? { uri: avatar }
            : gender === "F"
            ? getImage("avatarF")
            : getImage("avatar")
        }
        title={name?.toUpperCase().substr(0, 2)}
        activeOpacity={0.7}
      />
    );
  };

  const LocationView = ({ location, user }) => {
    return (
      <MapView
        style={[w150, h100, rounded10, m10]}
        initialRegion={{
          latitude: location.latitude,
          longitude: location.longitude,
          latitudeDelta: 0.0043,
          longitudeDelta: 0.0034
        }}
        disabled={true}
        onPress={() => {
          openURL(
            dispatch,
            `http://maps.google.com/?q=${location.latitude},${location.longitude}&title=${user?.name}`
          );
        }}
      >
        <MapView.Marker
          coordinate={{
            latitude: location.latitude,
            longitude: location.longitude
          }}
        />
      </MapView>
    );
  };

  const renderTicks = props => {
    const { currentMessage, renderTicks, user } = props;
    if (renderTicks && currentMessage) {
      return renderTicks(currentMessage);
    }
    if (
      currentMessage &&
      user &&
      currentMessage.user &&
      currentMessage.user._id !== user._id
    ) {
      return null;
    }
    if (
      currentMessage &&
      (currentMessage.sent || currentMessage.received || currentMessage.pending)
    ) {
      return (
        <View style={styles.content.tickView}>
          {!!currentMessage.sent && (
            <Text style={[styles.content.tick, props.tickStyle]}>✓</Text>
          )}
          {!!currentMessage.received && (
            <Text style={[styles.content.tick, props.tickStyle]}>✓</Text>
          )}
          {!!currentMessage.pending && (
            <Text style={[styles.content.tick, props.tickStyle]}>🕓</Text>
          )}
        </View>
      );
    }
    return null;
  };

  const renderCustomTime = props => {
    if (props.currentMessage && props.currentMessage.createdAt) {
      const { containerStyle, wrapperStyle, textStyle, ...timeProps } = props;
      if (props.renderTime) {
        return props.renderTime(timeProps);
      }
      return <Time {...timeProps} />;
    }
    return null;
  };

  const renderUsername = props => {
    const {
      currentMessage,
      user,
      renderUsernameOnMessage,
      usernameStyle
    } = props;
    if (renderUsernameOnMessage && currentMessage) {
      if (user && currentMessage.user._id === user._id) {
        return null;
      }
      return (
        <View style={styles.content.usernameView}>
          <Text style={[styles.content.username, usernameStyle]}>
            ~ {currentMessage.user.name}
          </Text>
        </View>
      );
    }
    return null;
  };

  const renderMessageText = props => {
    if (props.currentMessage && props.currentMessage.text) {
      const {
        containerStyle,
        wrapperStyle,
        optionTitles,
        ...messageTextProps
      } = props;
      if (props.renderMessageText) {
        return props.renderMessageText(messageTextProps);
      }
      return <MessageText {...messageTextProps} />;
    }
    return null;
  };

  const styledBubbleToNext = props => {
    const {
      currentMessage,
      nextMessage,
      position,
      containerToNextStyle
    } = props;
    if (
      currentMessage &&
      nextMessage &&
      position &&
      isSameUser(currentMessage, nextMessage) &&
      isSameDay(currentMessage, nextMessage)
    ) {
      return [
        styles[position].containerToNext,
        containerToNextStyle && containerToNextStyle[position]
      ];
    }
    return null;
  };

  const styledBubbleToPrevious = props => {
    const {
      currentMessage,
      previousMessage,
      position,
      containerToPreviousStyle
    } = props;
    if (
      currentMessage &&
      previousMessage &&
      position &&
      isSameUser(currentMessage, previousMessage) &&
      isSameDay(currentMessage, previousMessage)
    ) {
      return [
        styles[position].containerToPrevious,
        containerToPreviousStyle && containerToPreviousStyle[position]
      ];
    }
    return null;
  };

  const renderBubble = props => {
    const {
      currentMessage,
      position,
      containerStyle,
      wrapperStyle,
      bottomContainerStyle
    } = props;
    if (currentMessage.location) {
      return (
        <View
          style={[
            styles[position].container,
            containerStyle && containerStyle[position]
          ]}
        >
          <View
            style={[
              styles[position].wrapper,
              styledBubbleToNext(props),
              styledBubbleToPrevious(props),
              wrapperStyle && wrapperStyle[position]
            ]}
          >
            <View>
              <LocationView
                location={currentMessage.location}
                user={currentMessage.user}
              />
              {renderMessageText(props)}
              <View
                style={[
                  styles[position].bottom,
                  bottomContainerStyle && bottomContainerStyle[position]
                ]}
              >
                {renderUsername(props)}
                {renderCustomTime(props)}
                {renderTicks(props)}
              </View>
            </View>
          </View>
        </View>
      );
    }
    return (
      <Bubble
        {...props}
        wrapperStyle={{
          left: bgColor5,
          right: bgColor2
        }}
        textProps={{
          style: white
        }}
        textStyle={{
          left: [white, font],
          right: [white, font]
        }}
        usernameStyle={white}
      />
    );
  };

  const renderDay = props => {
    return <Day {...props} textStyle={darkgrey} />;
  };

  const renderMessageImage = props => {
    return (
      <TouchableOpacity
        onPress={() =>
          navigation.navigate("ImageZoomScreen", {
            uri: props.currentMessage.image
          })
        }
      >
        <Image
          style={[w150, h100, rounded10, cover, m10]}
          source={{ uri: props.currentMessage.image }}
        />
      </TouchableOpacity>
    );
  };

  const renderLoadEarlier = props => {
    return (
      hasNextPage && <LoadEarlier {...props} label={t("chat:loadearlier")} />
    );
  };

  const _pickImage = async () => {
    if (!isWeb) {
      const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
      if (status !== "granted") {
        dispatch({
          type: SET_DROP_DOWN_ALERT_INFO,
          info: "photosinfo"
        });
        return;
      }
    }
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: "Images",
      quality: 0.1,
      base64: true
    });

    if (!result.cancelled) {
      const files = [];
      const name = "" + Date.now();
      files.push({
        name,
        base64: result.uri
      });
      dispatch({
        type: SET_CHAT_PHOTO_DATA,
        value: result.uri,
        files
      });
    }
  };

  const renderInputToolbar = props => {
    return (
      <View style={[flex1, bgWhite]}>
        {location && (
          <View style={{ minHeight: 60 }}>
            <MapView
              style={[w150, h100, rounded10, m10]}
              initialRegion={{
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
                latitudeDelta: 0.0043,
                longitudeDelta: 0.0034
              }}
              disabled={true}
            >
              <MapView.Marker
                coordinate={{
                  latitude: location.coords.latitude,
                  longitude: location.coords.longitude
                }}
              />
              <MaterialCommunityIcons
                style={{ position: "absolute", top: 10, right: 10 }}
                name="delete-forever"
                color="red"
                size={20}
                onPress={() =>
                  dispatch({
                    type: CLEAR_CHAT_MAP_DATA
                  })
                }
              />
            </MapView>
          </View>
        )}
        {image && (
          <View style={{ minHeight: 60 }}>
            <Avatar
              containerStyle={[w150, h100, rounded10, m10]}
              placeholderStyle={[rounded10]}
              source={{
                uri: image
              }}
              overlayContainerStyle={[rounded10]}
              onPress={() => {
                dispatch({
                  type: CLEAR_CHAT_PHOTO_DATA
                });
              }}
            >
              <MaterialCommunityIcons
                style={{ position: "absolute", top: 10, right: 10 }}
                name="delete-forever"
                color="red"
                size={20}
              />
            </Avatar>
          </View>
        )}
        <InputToolbar {...props} />
      </View>
    );
  };

  const _currentLocation = async () => {
    let { status } = await Location.requestPermissionsAsync();
    if (status !== "granted") {
      dispatch({
        type: SET_DROP_DOWN_ALERT_INFO,
        info: "locationinfo"
      });
      return;
    }

    const location = await Location.getCurrentPositionAsync({});
    dispatch({
      type: SET_CHAT_CURRENT_LOCATION,
      location
    });
  };

  const renderActions = props => {
    return (
      <Actions
        {...props}
        containerStyle={[
          h44,
          alignItemsCenter,
          justifyContentCenter,
          { marginHorizontal: 4, marginBottom: 0 }
        ]}
        icon={() => (
          <Image style={{ width: 32, height: 32 }} source={getImage("plus")} />
        )}
        options={{
          [t("button:shareposition")]: _currentLocation,
          [t("button:camera")]: () => {
            navigation.navigate("CameraScreen", {
              showGridParam: false,
              type: SET_CHAT_PHOTO_DATA,
              showZoom: true,
              cameraTypeParam: Camera.Constants.Type.back
            });
          },
          [t("button:albums")]: _pickImage,
          [t("button:cancel")]: () => {}
        }}
        optionTintColor={COLOR2}
      />
    );
  };

  const renderTime = props => {
    return (
      <Time
        {...props}
        timeTextStyle={{
          left: {
            color: "white"
          },
          right: {
            color: "white"
          }
        }}
      />
    );
  };

  return (
    <KeyboardAvoidingView style={[flex1, bgColor1]}>
      <ImageBackground
        source={getImageUri("chat")}
        style={[w100p, flex1, cover]}
      >
        <View style={[flex1, { backgroundColor: "rgba(255,255,255,0.5)" }]}>
          {isFetching && page === 1 ? (
            <ActivityIndicator
              style={[flex1, justifyContentCenter]}
              size="large"
              color={COLOR2}
            />
          ) : (
            <GiftedChat
              ref={giftedChat}
              disabled={!to && !eventId}
              messages={messages}
              onSend={messages => sendMessage(messages[0])}
              user={{
                _id,
                name: pseudo,
                avatar: photoUri,
                gender
              }}
              placeholder={t("chat:placeholder")}
              renderSend={renderSend}
              showUserAvatar={true}
              renderUsernameOnMessage={Boolean(eventId)}
              showAvatarForEveryMessage={true}
              renderAvatar={renderAvatar}
              renderBubble={renderBubble}
              renderDay={renderDay}
              renderMessageImage={renderMessageImage}
              locale={lang}
              infiniteScroll={true}
              onLoadEarlier={() =>
                hasNextPage &&
                dispatch(fetchGetChatMessages(nextPage, to, eventId))
              }
              isLoadingEarlier={isFetching}
              loadEarlier={true}
              renderLoadEarlier={renderLoadEarlier}
              renderInputToolbar={renderInputToolbar}
              renderSend={renderSend}
              minInputToolbarHeight={Boolean(image || location) ? 170 : 44}
              alwaysShowSend={true}
              renderActions={renderActions}
              renderTime={renderTime}
            />
          )}
        </View>
      </ImageBackground>
    </KeyboardAvoidingView>
  );
};

export default Chat;
