import React, { Component, Fragment } from "react";
// Workaround use react-native Image, react-native-expo-image-cache crashed in case of ios
import {
  ActivityIndicator,
  Image,
  LayoutAnimation,
  // ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
// import { captureRef as takeSnapshotAsync } from "react-native-view-shot";
import { t, getTranslatedProperty } from "../services/i18n";
import { withNavigation } from "@react-navigation/compat";
import commonStyles, {
  COLOR5,
  COLOR2,
  SCREEN_WIDTH,
  SCREEN_HEIGHT,
  ORANGE_COLOR,
  DARKGREY_COLOR
} from "../styles/commonStyles";
import LiveHeader from "./LiveHeader";
import {
  SET_DROP_DOWN_ALERT_WARN,
  SHARE_POST,
  SHARE_TEMPLATE_POST
} from "../actions/types";
import {
  ROLE_GUEST,
  GROUP_ID_ACTIVITY,
  GROUP_ID_TEMPLATE,
  GROUP_ID_PUBLISH,
  GROUP_ID_WEATHER,
  GROUP_ID_PROPOSAL,
  GROUP_ID_GOODDEAL_ACTIVITY
} from "../config/constants";

import { Avatar, Button } from "react-native-elements";

import ActionButton from "../components/actionButton/ActionButton";
import {
  fetchDeleteLiveEvent,
  fetchInterested,
  fetchUninterested,
  fetchFlagInappropriateContent
  // fetchShareTemplatePost
} from "../actions/live";
import { formatDate } from "../utils/DateUtils";
import {
  FontAwesome5,
  MaterialCommunityIcons,
  Ionicons,
  AntDesign
} from "@expo/vector-icons";
import ConfirmationModal from "../components/ConfirmationModal";
import { getImage } from "../assets/Images";
import { openBrowser } from "../utils/UrlUtil";
import { ScrollView } from "react-native-gesture-handler";

class LiveEvent extends Component {
  _isMounted = false;

  state = {
    deletingEvent: false,
    fetchingUpdate: false,
    fetchingShareTemplate: false,
    fetchingFlagContent: false,
    showConfirmationModal: false,
    description: "",
    buttonConfirm: "",
    onConfirmation: this.flagContent,
    buttonColor: COLOR2,
    height: SCREEN_WIDTH,
    width: SCREEN_WIDTH
  };

  componentDidMount() {
    const { isDetails, groupId, goodDeal } = this.props;
    let { photoUri } = this.getData();
    if (groupId === GROUP_ID_GOODDEAL_ACTIVITY && goodDeal) {
      photoUri = goodDeal.photoUri;
    }

    this._isMounted = true;
    if (isDetails && photoUri && !this.state.imageHeight) {
      this.getImageSize(photoUri);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { usersanswers, _id, refresh } = this.props;
    const {
      imageHeight,
      deletingEvent,
      fetchingUpdate,
      fetchingFlagContent,
      showConfirmationModal,
      buttonColor,
      height,
      width
    } = this.state;

    if (
      !nextProps._id ||
      (nextProps.refresh && !refresh) ||
      (!nextProps.refresh && refresh)
    ) {
      return true;
    }
    if (nextProps._id !== _id) {
      return false;
    }
    if (
      nextState.description ||
      nextState.buttonConfirm ||
      nextState.buttonColor !== buttonColor ||
      (nextState.imageHeight && !imageHeight) ||
      (nextState.deletingEvent && !deletingEvent) ||
      (!nextState.deletingEvent && deletingEvent) ||
      (nextState.fetchingUpdate && !fetchingUpdate) ||
      (!nextState.fetchingUpdate && fetchingUpdate) ||
      (nextState.fetchingFlagContent && !fetchingFlagContent) ||
      (!nextState.fetchingFlagContent && fetchingFlagContent) ||
      (nextState.showConfirmationModal && !showConfirmationModal) ||
      (!nextState.showConfirmationModal && showConfirmationModal)
    ) {
      return true;
    }
    if (
      (nextProps.usersanswers && !usersanswers) ||
      (usersanswers &&
        Object.values(nextProps.usersanswers).length !==
          Object.values(usersanswers).length)
    ) {
      return true;
    }
    if (nextState.width !== width || !nextState.height !== height) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidUpdate(prevProps, prevState) {
    const { isDetails, usersanswers } = this.props;
    const { photoUri } = this.getData();
    if (
      prevProps.usersanswers &&
      Object.values(prevProps.usersanswers).length !==
        Object.values(usersanswers).length &&
      this._isMounted
    ) {
      this.setState({
        fetchingUpdate: false
      });
    }
    if (isDetails && photoUri && !this.state.imageHeight) {
      this.getImageSize(photoUri);
    }
  }

  getTitle = () => {
    const { usersanswers, groupId, user } = this.props;
    const isInterested = Boolean(
      usersanswers && user && usersanswers[user._id]
    );

    let title = "";
    if (isInterested) {
      if (
        groupId === GROUP_ID_ACTIVITY ||
        groupId === GROUP_ID_PROPOSAL ||
        groupId === GROUP_ID_GOODDEAL_ACTIVITY
      ) {
        title = t("button:removeanswer");
      } else if (groupId === GROUP_ID_PUBLISH) {
        title = t("button:dislike");
      } else if (user && user.gender === "M") {
        title = t("button:notinterested_M");
      } else {
        title = t("button:notinterested_F");
      }
    } else {
      if (
        groupId === GROUP_ID_ACTIVITY ||
        groupId === GROUP_ID_PROPOSAL ||
        groupId === GROUP_ID_GOODDEAL_ACTIVITY
      ) {
        title = t("button:answerme");
      } else if (groupId === GROUP_ID_PUBLISH) {
        title = t("button:like");
      } else if (user && user.gender === "M") {
        title = t("button:interested_M");
      } else {
        title = t("button:interested_F");
      }
    }
    return title;
  };

  showConfirmationModal = () => {
    this.setState({ showConfirmationModal: true });
  };

  onConfirmationModalClosed = () => {
    this.setState({ showConfirmationModal: false }, this.onReset);
  };

  flagContent = () => {
    let { dispatch, eventId } = this.props;
    if (eventId) {
      this.onConfirmationModalClosed();
      dispatch(fetchFlagInappropriateContent(eventId._id));
    }
  };

  getData = () => {
    const { template, event, eventId } = this.props;

    const {
      startDate,
      photoUri,
      picto,
      url,
      type,
      goodDealId,
      description,
      lang,
      title
    } = (template ? template : eventId ? eventId : event) || {};
    return {
      startDate,
      photoUri,
      picto,
      url,
      type,
      goodDealId,
      description,
      lang,
      title
    };
  };

  renderInterestedUsers = () => {
    const { ml10 } = commonStyles;
    const { usersanswers, navigation, user } = this.props;

    const components = [];
    for (const interestedUser of Object.values(usersanswers)) {
      components.push(
        <TouchableOpacity
          key={interestedUser._id}
          onPress={() =>
            navigation.navigate("ProfileScreen", {
              userId: interestedUser._id
            })
          }
          disabled={!user || user._id === interestedUser._id}
        >
          <Avatar
            rounded
            size={30}
            source={
              interestedUser.photoUri
                ? { uri: interestedUser.photoUri }
                : interestedUser.gender === "F"
                ? getImage("avatarF")
                : getImage("avatar")
            }
            title={interestedUser.firstname.toUpperCase().substr(0, 2)}
            activeOpacity={0.7}
            containerStyle={{ marginLeft: 10 }}
          />
        </TouchableOpacity>
      );
    }
    return components;
  };

  renderButtons = () => {
    const { font } = commonStyles;
    let {
      dispatch,
      navigation,
      _id: eventId,
      groupId,
      from,
      usersanswers,
      user,
      isDetails = false,
      date,
      goodDeal,
      template
    } = this.props;

    let {
      url,
      goodDealId,
      title,
      photoUri,
      description,
      lang
    } = this.getData();
    const isInterested = Boolean(
      usersanswers && user && usersanswers[user._id]
    );
    let { _id } = from || {};
    const fromId = user && user._id === _id ? "" : _id;

    if (groupId === GROUP_ID_GOODDEAL_ACTIVITY && goodDeal) {
      goodDealId = goodDeal._id;
    }

    // construct event to share data
    const event = {
      isEvent: Boolean(this.props.event),
      eventId,
      date,
      title,
      photoUri,
      description,
      lang
    };

    let componentButton = null;
    switch (groupId) {
      case GROUP_ID_WEATHER:
        componentButton = (
          <Fragment>
            <ActionButton
              buttonColor={COLOR5}
              renderIcon={() => (
                <MaterialCommunityIcons
                  name="dots-horizontal"
                  size={40}
                  color="white"
                />
              )}
              verticalOrientation="down"
              size={44}
              offsetX={18}
              offsetY={18}
              degrees={90}
              spacing={10}
            >
              <ActionButton.Item
                buttonColor={COLOR2}
                title={t("button:forecast")}
                onPress={() => navigation.navigate("MeteoScreen")}
                textStyle={font}
              >
                <MaterialCommunityIcons
                  name="weather-partly-cloudy"
                  size={34}
                  color="white"
                />
              </ActionButton.Item>
            </ActionButton>
          </Fragment>
        );
        break;

      case GROUP_ID_PUBLISH:
        componentButton = (
          <Fragment>
            <ActionButton
              buttonColor={COLOR5}
              renderIcon={() => (
                <MaterialCommunityIcons
                  name="dots-horizontal"
                  size={40}
                  color="white"
                />
              )}
              verticalOrientation="down"
              size={44}
              offsetX={18}
              offsetY={18}
              degrees={90}
              spacing={10}
              // autoInactive={false}
            >
              {Boolean(url) && url.trim().length > 0 && (
                <ActionButton.Item
                  buttonColor={COLOR2}
                  title={t("button:more")}
                  onPress={() => openBrowser(dispatch, url)}
                  textStyle={font}
                >
                  <FontAwesome5 name="map-marker-alt" size={30} color="white" />
                </ActionButton.Item>
              )}
              {Boolean(goodDealId) && (
                <ActionButton.Item
                  buttonColor={COLOR2}
                  title={t("button:more")}
                  onPress={() =>
                    navigation.navigate("GoodDealDetailsScreen", {
                      id: goodDealId,
                      title: t("menu:localLife")
                    })
                  }
                  textStyle={font}
                  textHeight={24}
                >
                  <MaterialCommunityIcons
                    name="dots-horizontal"
                    type="material-community"
                    size={34}
                    color="white"
                  />
                </ActionButton.Item>
              )}
              <ActionButton.Item
                buttonColor={COLOR2}
                title={t("button:share")}
                onPress={() =>
                  dispatch({
                    type: SHARE_POST,
                    event
                  })
                }
                textStyle={font}
                textHeight={24}
              >
                <FontAwesome5 name="share-alt" size={30} color="white" />
              </ActionButton.Item>
              {Boolean(fromId)
                ? Boolean(isDetails && user && user.role !== ROLE_GUEST) && (
                    <ActionButton.Item
                      buttonColor={ORANGE_COLOR}
                      title={t("button:flagcontent")}
                      onPress={() => {
                        LayoutAnimation.easeInEaseOut();
                        this.setState({
                          showConfirmationModal: true,
                          description: t("profile:confirmflag"),
                          buttonConfirm: t("button:flag"),
                          onConfirmation: this.flagContent,
                          onClosed: this.onConfirmationModalClosed,
                          buttonColor: ORANGE_COLOR
                        });
                      }}
                      textStyle={font}
                      textHeight={24}
                    >
                      <MaterialCommunityIcons
                        name="alert-decagram"
                        size={34}
                        color="white"
                      />
                    </ActionButton.Item>
                  )
                : Boolean(user && user.role) && (
                    <ActionButton.Item
                      buttonColor="red"
                      title={t("button:delete")}
                      onPress={() => {
                        this._isMounted &&
                          this.setState(
                            {
                              deletingEvent: true
                            },
                            () => {
                              dispatch(fetchDeleteLiveEvent(eventId));
                              if (isDetails) {
                                navigation.goBack();
                              }
                            }
                          );
                      }}
                      textStyle={font}
                      textHeight={24}
                    >
                      <MaterialCommunityIcons
                        name={"delete-forever"}
                        size={34}
                        color="white"
                      />
                    </ActionButton.Item>
                  )}
            </ActionButton>
          </Fragment>
        );
        break;

      case GROUP_ID_PROPOSAL:
      case GROUP_ID_ACTIVITY:
      case GROUP_ID_GOODDEAL_ACTIVITY:
      case GROUP_ID_TEMPLATE:
        {
          componentButton = (
            <Fragment>
              <ActionButton
                buttonColor={COLOR5}
                renderIcon={() => (
                  <MaterialCommunityIcons
                    name="dots-horizontal"
                    size={40}
                    color="white"
                  />
                )}
                verticalOrientation="down"
                size={44}
                offsetX={18}
                offsetY={18}
                degrees={90}
                spacing={10}
                autoInactive={false}
              >
                {Boolean(goodDealId) && (
                  <ActionButton.Item
                    buttonColor={COLOR2}
                    title={t("button:more")}
                    onPress={() =>
                      navigation.navigate("GoodDealDetailsScreen", {
                        id: goodDealId,
                        title: t("menu:localLife")
                      })
                    }
                    textStyle={font}
                    textHeight={24}
                  >
                    <MaterialCommunityIcons
                      name="dots-horizontal"
                      type="material-community"
                      size={34}
                      color="white"
                    />
                  </ActionButton.Item>
                )}
                {Boolean(fromId) && (
                  <ActionButton.Item
                    buttonColor={COLOR2}
                    title={this.getTitle()}
                    onPress={() => {
                      if (user && user.role !== ROLE_GUEST) {
                        this._isMounted &&
                          this.setState(
                            {
                              fetchingUpdate: true
                            },
                            () => {
                              dispatch(
                                isInterested
                                  ? fetchUninterested(eventId)
                                  : fetchInterested(eventId)
                              );
                            }
                          );
                      } else {
                        dispatch({
                          type: SET_DROP_DOWN_ALERT_WARN,
                          warn: "guestforbidden"
                        });
                      }
                    }}
                    textStyle={font}
                    textHeight={24}
                  >
                    {this.state.fetchingUpdate ? (
                      <ActivityIndicator size="small" color="white" />
                    ) : (
                      <MaterialCommunityIcons
                        name={isInterested ? "star-outline" : "star-face"}
                        size={34}
                        color="white"
                      />
                    )}
                  </ActionButton.Item>
                )}
                {Boolean(!fromId || (fromId && isInterested)) && (
                  <ActionButton.Item
                    buttonColor={COLOR2}
                    title={t("button:groupchat")}
                    onPress={() => {
                      if (user && user.role !== ROLE_GUEST) {
                        navigation.navigate("ChatScreen", {
                          title: template
                            ? getTranslatedProperty(template, "name")
                            : null,
                          eventId
                        });
                      } else {
                        dispatch({
                          type: SET_DROP_DOWN_ALERT_WARN,
                          warn: "guestforbidden"
                        });
                      }
                    }}
                    textStyle={font}
                    textHeight={24}
                  >
                    <Ionicons
                      name={"ios-chatbubbles"}
                      size={32}
                      color="white"
                    />
                  </ActionButton.Item>
                )}
                {!fromId && (
                  <ActionButton.Item
                    buttonColor="red"
                    title={t("button:delete")}
                    onPress={() => {
                      this.setState(
                        {
                          deletingEvent: true
                        },
                        () => {
                          dispatch(fetchDeleteLiveEvent(eventId));
                          if (isDetails) {
                            navigation.goBack();
                          }
                        }
                      );
                    }}
                    textStyle={font}
                    textHeight={24}
                  >
                    <MaterialCommunityIcons
                      name={"delete-forever"}
                      size={34}
                      color="white"
                    />
                  </ActionButton.Item>
                )}
              </ActionButton>
            </Fragment>
          );
        }
        break;
    }
    return componentButton;
  };

  getImageSize(photoUri) {
    Image.getSize(photoUri, (width, height) => {
      let proportion = height / width;
      let imageWidth = SCREEN_WIDTH - 40;
      let imageHeight = Math.ceil(imageWidth * proportion);
      if (this._isMounted) {
        this.setState({ imageWidth, imageHeight });
      }
    });
  }

  render() {
    let {
      bgWhite,
      red,
      fontBold,
      font,
      fs12,
      p10,
      ph10,
      pt10,
      mt5,
      mt10,
      shadowGrey,
      mv5,
      mb5,
      row,
      flex1,
      mb10,
      darkgrey,
      color2,
      rounded5,
      uppercase,
      opacity0,
      contain,
      rowReverse,
      alignItemsCenter,
      borderBottomColor5,
      borderRightColor5
    } = commonStyles;

    // Get props from event item
    let {
      dispatch,
      _id,
      groupId,
      template,
      goodDeal,
      event,
      date
    } = this.props;

    // Get props from parent props
    let {
      locations,
      equipments,
      templateDates,
      templateTimes,
      eventId,
      datetimeDiff,
      lang,
      from,
      usersanswers,
      user,
      isDetails = false,
      navigation,
      refresh
    } = this.props;

    let { startDate, photoUri: postPhotoUri, picto } = this.getData();

    let { _id: userId, photoUri, firstname, company, gender } = from || {};
    const {
      imageWidth,
      imageHeight,
      description: modalDescription,
      showConfirmationModal,
      onConfirmation,
      buttonConfirm,
      buttonColor,
      fetchingFlagContent,
      fetchingUpdate
    } = this.state;

    let when,
      where,
      title,
      description,
      postDescription = null;
    const isInterested = Boolean(
      usersanswers && user && usersanswers[user._id]
    );
    const fromId = user && user._id === userId ? "" : userId;

    if (event) {
      const { dateId, timeId, locationId, equipmentId, choiceId } = event;
      when = dateId
        ? getTranslatedProperty(templateDates[dateId], "name") +
          (timeId
            ? " - " + getTranslatedProperty(templateTimes[timeId], "name")
            : "")
        : null;
      where = locationId
        ? getTranslatedProperty(locations[locationId], "name")
        : equipmentId
        ? getTranslatedProperty(equipments[equipmentId], "name")
        : null;
      if (choiceId) {
        if (groupId === GROUP_ID_GOODDEAL_ACTIVITY && goodDeal?.choices) {
          description = getTranslatedProperty(
            goodDeal.choices[choiceId],
            "description"
          );
          postDescription = getTranslatedProperty(
            goodDeal.choices[choiceId],
            "postDescription"
          );
        } else if (template.choices) {
          description = getTranslatedProperty(
            template.choices[choiceId],
            "description"
          );
          postDescription = getTranslatedProperty(
            template.choices[choiceId],
            "postDescription"
          );
        }
      } else {
        if (groupId === GROUP_ID_GOODDEAL_ACTIVITY) {
          description = goodDeal
            ? getTranslatedProperty(goodDeal, "activityDescription")
            : getTranslatedProperty(event, "activityDescription");
          postDescription = goodDeal
            ? getTranslatedProperty(goodDeal, "activityPostDescription")
            : getTranslatedProperty(event, "activityPostDescription");
        } else {
          description = template
            ? getTranslatedProperty(template, "description")
            : getTranslatedProperty(event, "description");
          postDescription = template
            ? getTranslatedProperty(template, "postDescription")
            : getTranslatedProperty(event, "postDescription");
        }
      }
    }
    if (eventId) {
      title = getTranslatedProperty(eventId, "title");
      description = getTranslatedProperty(eventId, "description");
      postDescription = getTranslatedProperty(eventId, "postDescription");
    }

    if (groupId === GROUP_ID_GOODDEAL_ACTIVITY && goodDeal) {
      postPhotoUri = goodDeal.photoUri;
    }

    const displayPhotoUri = Boolean(
      groupId !== GROUP_ID_ACTIVITY &&
        groupId !== GROUP_ID_PROPOSAL &&
        postPhotoUri
    );

    return (
      <View style={[bgWhite, shadowGrey, _id ? mv5 : mb5]}>
        <View style={[ph10, pt10, _id ? borderBottomColor5 : null]}>
          {_id && (
            <LiveHeader
              date={date}
              pseudo={company ? company : firstname}
              fromId={user && user._id === userId ? "" : userId}
              groupId={groupId}
              photoUri={photoUri}
              gender={gender}
              datetimeDiff={datetimeDiff}
              lang={lang}
              refresh={refresh}
            />
          )}
          <ConfirmationModal
            description={modalDescription}
            onClosed={this.onConfirmationModalClosed}
            title={t("menu:confirmation")}
            showConfirmationModal={showConfirmationModal}
            onConfirmation={onConfirmation}
            isFetching={fetchingFlagContent}
            buttonConfirm={buttonConfirm}
            buttonColor={buttonColor}
          />
          {isDetails ? (
            <View
              style={[p10, groupId === GROUP_ID_PUBLISH && { minHeight: 250 }]}
            >
              {Boolean(title) && (
                <Text style={[fontBold, uppercase]}>{title}</Text>
              )}

              {groupId === GROUP_ID_ACTIVITY ||
              groupId === GROUP_ID_PROPOSAL ? (
                <View style={[flex1, row, mb10]}>
                  <View style={styles.buttonActivity}>
                    <Image
                      style={[
                        {
                          width: 50,
                          height: 50,
                          position: "absolute",
                          bottom: 5,
                          tintColor: DARKGREY_COLOR
                        }
                      ]}
                      source={getImage(picto)}
                    />
                    <Image
                      style={[
                        {
                          width: 50,
                          height: 50,
                          position: "absolute",
                          bottom: 5
                        },
                        opacity0
                      ]}
                      source={getImage(picto)}
                    />
                  </View>
                  <Text style={[flex1, mt10, font]}>{description}</Text>
                </View>
              ) : (
                <Text style={[font, mb10]}>{description}</Text>
              )}

              {displayPhotoUri && (
                <Image
                  style={[
                    {
                      width: imageWidth,
                      height: imageHeight
                    },
                    mb10,
                    rounded5
                  ]}
                  source={{ uri: postPhotoUri }}
                />
              )}

              {Boolean(postDescription) && (
                <Text style={[font, mb10]}>{postDescription}</Text>
              )}
              {Boolean(where) && (
                <View style={[row]}>
                  <View style={[flex1]} />
                  <Text style={[font, darkgrey]}>{t("live:where")}</Text>
                  <Text style={[fontBold]}>{where}</Text>
                </View>
              )}
              {Boolean(startDate || when) && (
                <View style={[row]}>
                  <View style={[flex1]} />
                  <Text style={[font, darkgrey]}>{t("live:when")}</Text>
                  <Text style={[fontBold]}>
                    {startDate ? `${formatDate(startDate)}` : when}
                  </Text>
                </View>
              )}
              {Boolean(usersanswers && Object.values(usersanswers).length) && (
                <ScrollView
                  nestedScrollEnabled={true}
                  horizontal={true}
                  style={[flex1, mt5, rowReverse]}
                  contentContainerStyle={alignItemsCenter}
                >
                  <Text style={[font, darkgrey]}>
                    {groupId === GROUP_ID_PUBLISH
                      ? "👍 :"
                      : Object.values(usersanswers).length > 1
                      ? t("live:answers")
                      : t("live:answer")}
                  </Text>
                  {this.renderInterestedUsers()}
                </ScrollView>
              )}
            </View>
          ) : (
            <View style={[p10]}>
              {Boolean(title) && (
                <Text style={[fontBold, uppercase]}>{title}</Text>
              )}
              {Boolean(
                groupId === GROUP_ID_ACTIVITY || groupId === GROUP_ID_PROPOSAL
              ) ? (
                <View style={[row, mb10]}>
                  <TouchableOpacity
                    style={styles.buttonActivity}
                    onPress={() =>
                      _id &&
                      navigation.navigate("EventInterestedUsersScreen", {
                        id: _id
                      })
                    }
                    disabled={!Boolean(_id)}
                  >
                    <Image
                      style={{
                        width: 50,
                        height: 50,
                        position: "absolute",
                        bottom: 5,
                        tintColor: DARKGREY_COLOR
                      }}
                      source={getImage(picto)}
                    />
                    <Image
                      style={{
                        width: 50,
                        height: 50,
                        bottom: 5,
                        position: "absolute",
                        opacity: 0
                      }}
                      source={getImage(picto)}
                    />
                  </TouchableOpacity>
                  <Text style={[flex1, mt10, font]}>{description}</Text>
                </View>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    _id &&
                      navigation.navigate("EventInterestedUsersScreen", {
                        id: _id
                      });
                  }}
                >
                  <Text style={[font, mb10]} numberOfLines={title ? 2 : 3}>
                    {description}
                  </Text>
                </TouchableOpacity>
              )}
              {displayPhotoUri && (
                <TouchableOpacity
                  onPress={() => {
                    _id &&
                      navigation.navigate("EventInterestedUsersScreen", {
                        id: _id
                      });
                  }}
                >
                  <Image
                    style={[
                      {
                        display:
                          postPhotoUri && postPhotoUri.length > 0
                            ? "flex"
                            : "none",
                        height: SCREEN_HEIGHT / 4
                      },
                      contain,
                      mb10,
                      rounded5
                    ]}
                    source={{ uri: postPhotoUri }}
                  />
                </TouchableOpacity>
              )}
              {Boolean(postDescription) && (
                <Text style={[font, mb10]}>{postDescription}</Text>
              )}
              {Boolean(where) && (
                <View style={[row]}>
                  <View style={[flex1]} />
                  <Text style={[font, darkgrey]}>{t("live:where")}</Text>
                  <Text style={[fontBold, darkgrey]}>{where}</Text>
                </View>
              )}
              {Boolean(startDate || when) && (
                <View style={[row]}>
                  <View style={[flex1]} />
                  <Text style={[font, darkgrey]}>{t("live:when")}</Text>
                  <Text style={[fontBold, darkgrey]}>
                    {startDate ? `${formatDate(startDate)}` : when}
                  </Text>
                </View>
              )}
              {Boolean(usersanswers && Object.values(usersanswers).length) && (
                <View style={[flex1, mt5, alignItemsCenter, rowReverse]}>
                  {this.renderInterestedUsers()}
                  <Text style={[font, darkgrey]}>
                    {groupId === GROUP_ID_PUBLISH
                      ? "👍 :"
                      : Object.values(usersanswers).length > 1
                      ? t("live:answers")
                      : t("live:answer")}
                  </Text>
                </View>
              )}
            </View>
          )}
          {_id && this.renderButtons()}
        </View>
        {Boolean(_id) && (
          <View style={[flex1, row]}>
            <View style={[flex1, borderRightColor5]}>
              {Boolean(groupId !== GROUP_ID_PUBLISH) && (
                <Button
                  type="clear"
                  icon={
                    <Ionicons
                      name={"ios-chatbubbles"}
                      size={15}
                      color={COLOR2}
                    />
                  }
                  titleStyle={[fontBold, color2, fs12, { paddingLeft: 5 }]}
                  containerStyle={[{ alignSelf: "flex-start" }]}
                  title={t("button:groupchat")}
                  onPress={() => {
                    navigation.navigate("ChatScreen", {
                      title: template
                        ? getTranslatedProperty(template, "name")
                        : null,
                      eventId: _id
                    });
                  }}
                />
              )}
            </View>
            <View style={[flex1]}>
              {Boolean(fromId) && (
                <Button
                  type="clear"
                  titleStyle={[
                    fontBold,
                    isInterested ? red : color2,
                    fs12,
                    { paddingLeft: 5 }
                  ]}
                  icon={
                    groupId === GROUP_ID_PUBLISH ? (
                      <AntDesign
                        name={isInterested ? "dislike1" : "like1"}
                        size={15}
                        color={isInterested ? "red" : COLOR2}
                      />
                    ) : (
                      <MaterialCommunityIcons
                        name={isInterested ? "star-outline" : "star-face"}
                        size={18}
                        color={isInterested ? "red" : COLOR2}
                      />
                    )
                  }
                  containerStyle={[{ alignSelf: "flex-end", paddingRight: 5 }]}
                  title={
                    isInterested
                      ? groupId === GROUP_ID_PUBLISH
                        ? t("button:dislike")
                        : t("button:removeanswer")
                      : groupId === GROUP_ID_PUBLISH
                      ? t("button:like")
                      : t("button:answerme")
                  }
                  loading={fetchingUpdate}
                  onPress={() => {
                    if (user && user.role !== ROLE_GUEST) {
                      this._isMounted &&
                        this.setState(
                          {
                            fetchingUpdate: true
                          },
                          () => {
                            dispatch(
                              isInterested
                                ? fetchUninterested(_id)
                                : fetchInterested(_id)
                            );
                          }
                        );
                    } else {
                      dispatch({
                        type: SET_DROP_DOWN_ALERT_WARN,
                        warn: "guestforbidden"
                      });
                    }
                  }}
                />
              )}
            </View>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  buttonActivity: {
    height: 60,
    width: 60,
    borderColor: "black",
    borderRadius: 3,
    backgroundColor: "white",
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "space-evenly",
    margin: 5
  }
});
export default withNavigation(LiveEvent);
