import {
  FETCH_GET_CHAT_MESSAGES,
  SET_CHAT_MESSAGES,
  FETCH_GET_MY_CHAT_USERS,
  SET_MY_CHAT_USERS,
  SET_MY_CHAT_USERS_FAILURE,
  FETCH_EXIT_CHAT,
  SET_CHAT_EXIT_RESULT,
  FETCH_GET_CHAT_NEIGHBORS,
  SET_CHAT_NEIGHBORS,
  SET_CHAT_NEIGHBORS_FAILURE,
  FETCH_GET_CHAT_EMPLOYEES,
  SET_CHAT_EMPLOYEES,
  SET_CHAT_EMPLOYEES_FAILURE,
  SET_EVENT_INTERESTED_USERS_RESULT,
  FETCH_GET_EVENT_INTERESTED_USERS,
  SET_EVENT_INTERESTED_USERS_FAILURE,
  FETCH_FAILURE,
  FETCH_GET_CHAT_EVENTS,
  SET_CHAT_EVENTS_RESULT
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";

const user = new schema.Entity("users", {}, { idAttribute: "_id" });
export const event = new schema.Entity(
  "event",
  {
    interestedUsersIds: [user]
  },
  { idAttribute: "_id" }
);

const chattemplate = new schema.Entity(
  "livetemplates",
  {},
  { idAttribute: "_id" }
);
const chatevent = new schema.Entity(
  "liveevents",
  {
    templateId: chattemplate
  },
  { idAttribute: "_id" }
);
const chatevents = new schema.Entity(
  "chatevents",
  {
    eventId: chatevent
  },
  { idAttribute: "_id" }
);

const chatmessage = new schema.Entity(
  "chatmessages",
  {
    from: user
  },
  { idAttribute: "_id" }
);

const infospage = new schema.Entity("infospages", {
  docs: [chatmessage]
});

export function fetchGetMyChatUsers() {
  return apiAction({
    url: "/messages/users",
    method: "GET",
    onSuccess: setMyChatUsers,
    onFailure: setMyChatUsersFailure,
    label: FETCH_GET_MY_CHAT_USERS
  });
}

function setMyChatUsers(data) {
  return {
    type: SET_MY_CHAT_USERS,
    payload: data
  };
}

function setMyChatUsersFailure(error) {
  return {
    type: SET_MY_CHAT_USERS_FAILURE,
    payload: error
  };
}

export function fetchGetChatNeighbors() {
  return apiAction({
    url: "/messages/neighbors",
    method: "GET",
    onSuccess: setChatNeighbors,
    onFailure: setChatNeighborsFailure,
    label: FETCH_GET_CHAT_NEIGHBORS
  });
}

function setChatNeighbors(data) {
  return {
    type: SET_CHAT_NEIGHBORS,
    payload: data
  };
}

function setChatNeighborsFailure(error) {
  return {
    type: SET_CHAT_NEIGHBORS_FAILURE,
    payload: error
  };
}

export function fetchGetChatEmployees() {
  return apiAction({
    url: "/messages/employees",
    method: "GET",
    onSuccess: setChatEmployees,
    onFailure: setChatEmployeesFailure,
    label: FETCH_GET_CHAT_EMPLOYEES
  });
}

function setChatEmployees(data) {
  return {
    type: SET_CHAT_EMPLOYEES,
    payload: data
  };
}

function setChatEmployeesFailure(error) {
  return {
    type: SET_CHAT_EMPLOYEES_FAILURE,
    payload: error
  };
}

export function fetchGetChatEvents() {
  return apiAction({
    url: "/messages/events",
    method: "GET",
    schema: [chatevents],
    onSuccess: setChatEvents,
    onFailure: setFetchFailure,
    label: FETCH_GET_CHAT_EVENTS
  });
}

function setChatEvents(normalized) {
  return {
    type: SET_CHAT_EVENTS_RESULT,
    entities: normalized.entities
  };
}

export function fetchGetChatMessages(page, userId, eventId) {
  return apiAction({
    url: "/messages",
    data: eventId ? { eventId, page } : { userId, page },
    method: "GET",
    schema: infospage,
    onSuccess: setChatMessages,
    onFailure: setFetchFailure,
    label: FETCH_GET_CHAT_MESSAGES
  });
}

function setChatMessages(normalized) {
  return {
    type: SET_CHAT_MESSAGES,
    entities: normalized.entities
  };
}

export function fetchExitChat(userId, eventId) {
  return apiAction({
    url: "/messages/exitchat",
    data: {
      userId,
      eventId
    },
    method: "POST",
    onSuccess: setExitChatResult,
    onFailure: setFetchFailure,
    label: FETCH_EXIT_CHAT
  });
}

function setExitChatResult(data) {
  return {
    type: SET_CHAT_EXIT_RESULT,
    payload: data
  };
}

export function fetchGetEventInterestedUsers(eventId) {
  return apiAction({
    url: "/messages/users/" + eventId,
    method: "GET",
    schema: event,
    onSuccess: setEventInterestedUsersResult,
    onFailure: setUsersFailure,
    label: FETCH_GET_EVENT_INTERESTED_USERS
  });
}

function setEventInterestedUsersResult(normalized) {
  return {
    type: SET_EVENT_INTERESTED_USERS_RESULT,
    entities: normalized.entities
  };
}

function setUsersFailure(error) {
  return {
    type: SET_EVENT_INTERESTED_USERS_FAILURE,
    payload: error
  };
}

function setFetchFailure(error) {
  return {
    type: FETCH_FAILURE,
    payload: error
  };
}
