import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { Camera } from "expo-camera";
import * as Permissions from "expo-permissions";
import { BarCodeScanner } from "expo-barcode-scanner";
import React, { useState, useEffect } from "react";
import { BackHandler, StyleSheet, Text, View } from "react-native";
import SafeAreaView from "react-native-safe-area-view";
import { t } from "../services/i18n";
import commonStyles, {
  DARKGREY_COLOR,
  COLOR2,
  COLOR5
} from "../styles/commonStyles";
import ActionButton from "../components/actionButton/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-native-modalbox";
import {
  SET_DROP_DOWN_ALERT_INFO,
  SET_SCANNED_DATA,
  CLEAR_SCAN,
  HIDE_QR_CODE_SCANNER_MODAL
} from "../actions/types";

export default function QrCodeScannerModal() {
  const {
    justifyContentCenter,
    alignItemsCenter,
    flex1,
    spaceBetween,
    w100p,
    fontBold,
    fs18,
    white,
    red,
    fs16
  } = commonStyles;

  const dispatch = useDispatch();

  const {
    scanned,
    isUnknownDeepLink,
    communityId,
    showQrCodeScannerModal
  } = useSelector(state => state.urlscanReducer);

  const [hasPermission, setHasPermission] = useState(null);
  const [showSwitchCamera, setShowSwitchCamera] = useState(true);
  const [isReady, setReady] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [cameraType, setCameraType] = useState(Camera.Constants.Type.back);
  const [isOpen, setOpen] = useState(false);

  const onBackPress = () => {
    setOpen(false);
    return true;
  };

  useEffect(() => {
    if (showQrCodeScannerModal) {
      (async () => {
        if (!isReady) {
          const ready = await Camera.isAvailableAsync();
          if (ready) {
            const types = await Camera.getAvailableCameraTypesAsync();
            // types are empty on iphone
            // if (types.length === 0) {
            //   setHasPermission(false);
            //   setShowSwitchCamera(false);
            // } else
            if (types.length === 1) {
              switch (types[0]) {
                case Camera.Constants.Type.front:
                  // Workaround: strange on macos, if front camera type should be set to back
                  setCameraType(Camera.Constants.Type.back);
                  setShowSwitchCamera(false);
                  setReady(true);
                  break;
                case Camera.Constants.Type.back:
                  setCameraType(Camera.Constants.Type.front);
                  setShowSwitchCamera(false);
                  setReady(true);
                  break;
              }
            } else {
              setReady(true);
            }
          } else {
            setHasPermission(false);
            setReady(false);
          }
        }
        BackHandler.addEventListener("hardwareBackPress", onBackPress);
        setOpen(true);
      })();
    } else {
      BackHandler.removeEventListener("hardwareBackPress", onBackPress);
      setOpen(false);
    }
  }, [showQrCodeScannerModal]);

  useEffect(() => {
    if (isUnknownDeepLink) {
      dispatch({
        type: SET_DROP_DOWN_ALERT_INFO,
        info: "unknownurlinfo"
      });
    }
  }, [isUnknownDeepLink]);

  useEffect(() => {
    if (communityId) {
      BackHandler.removeEventListener("hardwareBackPress", onBackPress);
      setOpen(false);
    }
  }, [communityId]);

  useEffect(() => {
    if (isReady) {
      (async () => {
        const { status } = await Permissions.askAsync(Permissions.CAMERA);
        setHasPermission(status === "granted");
      })();
    } else {
      setHasPermission(false);
    }
  }, [isReady]);

  const handleBarCodeScanned = scannedData => {
    dispatch({
      type: SET_SCANNED_DATA,
      payload: scannedData
    });
  };

  return (
    <Modal
      backdrop={true}
      backdropOpacity={0.7}
      backdropColor="black"
      position="center"
      style={flex1}
      backdropPressToClose={false}
      swipeToClose={false}
      isOpen={isOpen}
    >
      {hasPermission === null ? (
        <View />
      ) : !hasPermission ? (
        <View style={[flex1, alignItemsCenter, justifyContentCenter]}>
          <Text style={[fontBold, red, fs18]}>{t("login:nocameraaccess")}</Text>
        </View>
      ) : (
        <Camera
          type={cameraType}
          autoFocus={Camera.Constants.AutoFocus.on}
          flashMode={Camera.Constants.FlashMode.off}
          zoom={0}
          onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
          barCodeScannerSettings={{
            barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr]
          }}
        >
          <SafeAreaView style={[flex1, w100p, spaceBetween]}>
            <View
              style={[
                w100p,
                alignItemsCenter,
                justifyContentCenter,
                { marginTop: 30 }
              ]}
            >
              <Text style={[fontBold, white, fs18]}>
                {t("login:scanqrcode")}
              </Text>
            </View>

            <View style={[styles.container]}>
              <View style={[styles.finder, { width: 280, height: 280 }]}>
                <View
                  style={[
                    { borderColor: DARKGREY_COLOR },
                    styles.topLeftEdge,
                    {
                      borderLeftWidth: 3,
                      borderTopWidth: 3
                    }
                  ]}
                />
                <View
                  style={[
                    { borderColor: DARKGREY_COLOR },
                    styles.topRightEdge,
                    {
                      borderRightWidth: 3,
                      borderTopWidth: 3
                    }
                  ]}
                />
                <View
                  style={[
                    { borderColor: DARKGREY_COLOR },
                    styles.bottomLeftEdge,
                    {
                      borderLeftWidth: 3,
                      borderBottomWidth: 3
                    }
                  ]}
                />
                <View
                  style={[
                    { borderColor: DARKGREY_COLOR },
                    styles.bottomRightEdge,
                    {
                      borderRightWidth: 3,
                      borderBottomWidth: 3
                    }
                  ]}
                />
              </View>
            </View>
          </SafeAreaView>
        </Camera>
      )}
      <ActionButton
        buttonColor={isMenuOpen ? COLOR5 : COLOR2}
        degrees={90}
        renderIcon={() => (
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={40}
            color="white"
          />
        )}
        onPressIn={() => setMenuOpen(true)}
        onReset={() => setMenuOpen(false)}
        // disabled={isFetching}
      >
        <ActionButton.Item
          buttonColor={"red"}
          title={t("button:cancel")}
          onPress={() =>
            dispatch({
              type: HIDE_QR_CODE_SCANNER_MODAL
            })
          }
          textStyle={[{ minHeight: 50 }, fontBold, fs16]}
        >
          <MaterialCommunityIcons name="cancel" size={36} color="white" />
        </ActionButton.Item>
        {showSwitchCamera && (
          <ActionButton.Item
            buttonColor={COLOR2}
            title={
              cameraType === Camera.Constants.Type.front
                ? t("button:backcamera")
                : t("button:frontcamera")
            }
            onPress={() =>
              setCameraType(
                cameraType === Camera.Constants.Type.front
                  ? Camera.Constants.Type.back
                  : Camera.Constants.Type.front
              )
            }
            textStyle={[{ minHeight: 50 }, fontBold, fs16]}
            disabled={!isReady}
          >
            <Ionicons name="camera-reverse" size={36} color="white" />
          </ActionButton.Item>
        )}
        {isUnknownDeepLink && (
          <ActionButton.Item
            buttonColor={COLOR2}
            title={t("button:rescan")}
            onPress={() =>
              dispatch({
                type: CLEAR_SCAN
              })
            }
            textStyle={[{ minHeight: 50 }, fontBold, fs16]}
            disabled={!isReady}
          >
            <MaterialCommunityIcons name="reload" size={36} color="white" />
          </ActionButton.Item>
        )}
      </ActionButton>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    // backgroundColor: "rgba(0,0,0,1.0)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  finder: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255,255,255,0.1)"
  },
  topLeftEdge: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 40,
    height: 20
  },
  topRightEdge: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 40,
    height: 20
  },
  bottomLeftEdge: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: 40,
    height: 20
  },
  bottomRightEdge: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: 40,
    height: 20
  }
});
