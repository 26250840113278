import Constants from "expo-constants";
import { Platform } from "react-native";

const ENV = {
  dev: {
    // apiUrl: "https://server.digihapi.hapicolibri.fr",
    // wsUrl: "wss://server.digihapi.hapicolibri.fr"
    // apiUrl: "http://192.168.144.3:3001",
    // wsUrl: "ws://192.168.144.3:3001"
    // apiUrl: "http://localhost:3001",
    // wsUrl: "ws://localhost:3001"
    // apiUrl: "https://www.digihapi.com",
    // wsUrl: "wss://www.digihapi.com"
    // apiUrl: "https://www.digihapi.fr",
    // wsUrl: "wss://www.digihapi.fr"
    apiUrl: "https://digihapi-staging.herokuapp.com",
    wsUrl: "wss://digihapi-staging.herokuapp.com"
  },
  prod: {
    // apiUrl: "https://server.digihapi.hapicolibri.fr",
    // wsUrl: "wss://server.digihapi.hapicolibri.fr"
    apiUrl: "https://digihapi-staging.herokuapp.com",
    wsUrl: "wss://digihapi-staging.herokuapp.com"
  }
};

function getEnvVars(env = "") {
  if (Platform.OS === "web") {
    if (__DEV__) {
      return ENV.dev;
    } else {
      return ENV.prod;
    }
  }
  if (env === null || env === undefined || env === "") return ENV.dev;
  if (env.indexOf("dev") !== -1) return ENV.dev;
  if (env.indexOf("staging") !== -1) return ENV.staging;
  if (env.indexOf("prod") !== -1) return ENV.prod;
}

export default getEnvVars(Constants.manifest.releaseChannel);
