import React, { useState, useEffect } from "react";
import { ActivityIndicator, View } from "react-native";
import * as Location from "expo-location";
import MapView from "react-native-maps";

import { useNavigation } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";

import commonStyles, { COLOR2 } from "../styles/commonStyles";
import {
  SET_DROP_DOWN_ALERT_ERROR,
  SET_DROP_DOWN_ALERT_INFO
} from "../actions/types";
import { getTranslatedProperty } from "../services/i18n";
import { fetchGetGoodDealsLocation } from "../actions/gooddeal";

const DEFAULT_LATITUDE_DELTA = 0.00722;
const DEFAULT_LONGITUDE_DELTA = 0.0021;

const DEFAULT_LATITUDE = 45.898698;
const DEFAULT_LONGITUDE = 6.12856;

export default function GoodDealsMap() {
  const { communityDetails: community } = useSelector(
    state => state.communityReducer
  );
  const { gooddealsLocations: gooddeals = [] } = useSelector(
    state => state.gooddealReducer
  );
  const { goodDealsCategories: categories } = useSelector(
    state => state.entitiesReducer
  );

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [initialRegion, setInitialRegion] = useState(null);
  const [showsUserLocation, setShowsUserLocation] = useState(false);

  const setCommunityInitialRegion = () => {
    if (community) {
      const { latitude, longitude } = community;
      setInitialRegion({
        latitudeDelta: DEFAULT_LATITUDE_DELTA,
        longitudeDelta: DEFAULT_LONGITUDE_DELTA,
        latitude,
        longitude
      });
    } else {
      setInitialRegion({
        latitudeDelta: DEFAULT_LATITUDE_DELTA,
        longitudeDelta: DEFAULT_LONGITUDE_DELTA,
        latitude: DEFAULT_LATITUDE,
        longitude: DEFAULT_LONGITUDE
      });
    }
  };

  const _getLocationAsync = async () => {
    let { status } = await Location.requestPermissionsAsync();
    if (status !== "granted") {
      dispatch({
        type: SET_DROP_DOWN_ALERT_INFO,
        info: "locationinfo"
      });
      setCommunityInitialRegion();
    } else {
      try {
        let location = await Location.getCurrentPositionAsync({});
        setShowsUserLocation(true);
        setInitialRegion({
          latitudeDelta: DEFAULT_LATITUDE_DELTA,
          longitudeDelta: DEFAULT_LONGITUDE_DELTA,
          latitude: location.coords.latitude,
          longitude: location.coords.longitude
        });
      } catch ({ message }) {
        dispatch({
          type: SET_DROP_DOWN_ALERT_ERROR,
          error: "locationerror",
          message
        });
        setCommunityInitialRegion();
      }
    }
  };

  useEffect(() => {
    dispatch(fetchGetGoodDealsLocation(1, null));
    _getLocationAsync();
  }, []);

  const { flex1, alignItemsCenter, justifyContentCenter } = commonStyles;

  return initialRegion ? (
    <MapView
      style={[flex1]}
      initialRegion={initialRegion}
      showsUserLocation={showsUserLocation}
    >
      {gooddeals &&
        gooddeals.map(gooddeal => {
          const { _id, latitude, longitude } = gooddeal;
          if (latitude && longitude) {
            return (
              <MapView.Marker
                key={_id}
                coordinate={{
                  latitude,
                  longitude
                }}
                title={getTranslatedProperty(gooddeal, "title")}
                onPress={() =>
                  navigation.navigate("GoodDealDetailsScreen", {
                    id: _id,
                    title: getTranslatedProperty(
                      categories[gooddeal.categoryId],
                      "name"
                    )
                  })
                }
              />
            );
          }
        })}
      {community && (
        <MapView.Marker
          coordinate={{
            latitude: community.latitude,
            longitude: community.longitude
          }}
          title={community.name}
          onPress={() => navigation.navigate("CommunityDetailsScreen")}
        />
      )}
    </MapView>
  ) : (
    <View style={[flex1, alignItemsCenter, justifyContentCenter]}>
      <ActivityIndicator size="large" color={COLOR2} />
    </View>
  );
}
