import React, { Component } from "react";
import {
  ActivityIndicator,
  FlatList,
  ScrollView,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Avatar, Badge, Button } from "react-native-elements";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchGetEventInterestedUsers } from "../actions/chat";
import commonStyles, { COLOR2, WHITE_COLOR } from "../styles/commonStyles";
import { t } from "../services/i18n";
import LiveEvent from "../components/LiveEvent";
import { SET_DROP_DOWN_ALERT_WARN } from "../actions/types";
import {
  GROUP_ID_ACTIVITY,
  GROUP_ID_TEMPLATE,
  ROLE_GUEST,
  ROLE_WEBMASTER,
  GROUP_ID_PROPOSAL,
  GROUP_ID_GOODDEAL_ACTIVITY
} from "../config/constants";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { getImage } from "../assets/Images";

class EventInterestedUsers extends Component {
  getUsers = async () => {
    const { fetchGetEventInterestedUsers, route } = this.props;
    await fetchGetEventInterestedUsers(route.params.id);
  };

  componentDidMount() {
    this.getUsers();
  }

  componentDidUpdate(prevProps, prevState) {
    const { reload } = this.props;

    if (reload && reload !== prevProps.reload) {
      this.getUsers();
    }
  }

  renderUser = record => {
    const {
      row,
      font,
      flex1,
      alignItemsCenter,
      justifyContentCenter,
      p5,
      m10,
      // V  New  v //
      bgLightgrey,
      bgColor2,
      darkgrey,
      rounded30,
      rounded22,
      h60,
      mb10,
      h44
    } = commonStyles;
    const { navigate } = this.props.navigation;
    const { _id, firstname, photoUri, gender, notRead, online } = record;
    const { dispatch, user } = this.props;

    return (
      <View
        style={[
          bgLightgrey,
          row,
          h60,
          rounded30,
          alignItemsCenter,
          m10,
          { paddingRight: 10 }
        ]}
      >
        <TouchableOpacity
          onPress={() => {
            navigate("ProfileScreen", {
              userId: _id
            });
          }}
          disabled={_id === user._id}
        >
          <View>
            <Avatar
              rounded
              size={60}
              source={
                photoUri
                  ? { uri: photoUri }
                  : gender === "F"
                  ? getImage("avatarF")
                  : getImage("avatar")
              }
              title={firstname.toUpperCase().substr(0, 2)}
              activeOpacity={0.7}
            />
            {user &&
              ((_id !== user._id && Boolean(notRead)) ||
                user.role === ROLE_WEBMASTER) && (
                <Badge
                  status={
                    user.role === ROLE_WEBMASTER
                      ? online
                        ? "success"
                        : "error"
                      : "error"
                  }
                  value={notRead ? notRead : " "}
                  containerStyle={{
                    position: "absolute",
                    top: -4,
                    right: -4
                  }}
                />
              )}
          </View>
        </TouchableOpacity>
        <View style={[justifyContentCenter, flex1, p5]}>
          <TouchableOpacity
            onPress={() => {
              if (user && user.role !== ROLE_GUEST) {
                navigate("ChatScreen", {
                  to: _id,
                  title: firstname
                });
              } else {
                dispatch({
                  type: SET_DROP_DOWN_ALERT_WARN,
                  warn: "guestforbidden"
                });
              }
            }}
            disabled={_id === user._id}
          >
            <Text style={[font, darkgrey, { fontSize: 15 }, mb10]}>
              {firstname}
            </Text>
          </TouchableOpacity>
        </View>
        <Button
          icon={
            <MaterialCommunityIcons
              name={"chat"}
              size={28}
              color={WHITE_COLOR}
            />
          }
          buttonStyle={[bgColor2, h44, rounded22]}
          onPress={() => {
            if (user && user.role !== ROLE_GUEST) {
              navigate("ChatScreen", {
                to: _id,
                title: pseudo
              });
            } else {
              dispatch({
                type: SET_DROP_DOWN_ALERT_WARN,
                warn: "guestforbidden"
              });
            }
          }}
        />
      </View>
    );
  };

  render() {
    const {
      pv20,
      bgWhite,
      mt5,
      shadowGrey,
      bgColor1,
      flex1,
      color5,
      justifyContentCenter,
      w100p,
      font,
      textCenter,
      mb10
    } = commonStyles;

    const {
      isFetching,
      item,
      user,
      users,
      interestedusers,
      dispatch,
      locations,
      equipments,
      templateDates,
      templateTimes,
      templates,
      datetimeDiff,
      lang
    } = this.props;

    const {
      templateId,
      userId,
      groupId,
      goodDealId,
      dateAdded: date,
      interestedUsersIds
    } = item || {};

    if (templateId && !templates[templateId]) {
      return null;
    }

    const props = {
      dispatch,
      locations,
      equipments,
      templateDates,
      templateTimes,
      templates,
      datetimeDiff,
      lang,
      user
    };
    const itemProps = {
      ...item,
      date
    };

    const usersanswers = {};
    if (interestedUsersIds) {
      for (const interestedUserId of interestedUsersIds) {
        if (interestedusers[interestedUserId]) {
          usersanswers[interestedUserId] = interestedusers[interestedUserId];
        } else if (interestedUserId === user._id) {
          usersanswers[interestedUserId] = user;
        }
      }
    }

    return (
      <View style={[flex1, bgColor1]}>
        {isFetching ? (
          <ActivityIndicator
            style={[flex1, justifyContentCenter]}
            size="large"
            color={COLOR2}
          />
        ) : (
          <ScrollView nestedScrollEnabled={true}>
            <LiveEvent
              {...props}
              {...itemProps}
              usersanswers={usersanswers}
              template={templates[templateId]}
              goodDeal={goodDealId}
              from={userId}
              isDetails={true}
            />
            {Object.values(users).length > 0 &&
              user &&
              userId &&
              user._id === userId._id && (
                <View style={[flex1, pv20, bgWhite, mt5, shadowGrey]}>
                  <Text
                    style={[font, color5, textCenter, mb10, { fontSize: 17 }]}
                  >
                    {groupId === GROUP_ID_ACTIVITY ||
                    groupId === GROUP_ID_PROPOSAL ||
                    groupId === GROUP_ID_GOODDEAL_ACTIVITY
                      ? t("button:answers")
                      : groupId === GROUP_ID_TEMPLATE
                      ? t("button:interested")
                      : t("button:answerslike")}
                  </Text>
                  <FlatList
                    style={[flex1, w100p]}
                    data={Object.values(users)}
                    renderItem={({ item }) => this.renderUser(item)}
                    keyExtractor={(item, index) => item._id}
                  />
                </View>
              )}
          </ScrollView>
        )}
      </View>
    );
  }
}

const mapStateToProps = state => {
  const {
    isFetching,
    reload,
    event: item,
    users
  } = state.eventInterestedUsersReducer;
  const { interestedusers } = state.liveReducer;
  const { templates, locations } = state.entitiesReducer;
  const { equipments, templateDates, templateTimes } = state.templateReducer;
  const { lang, datetimeDiff } = state.globalInformationsReducer;
  const { user } = state.profileReducer;

  return {
    isFetching,
    reload,
    item,
    users,
    interestedusers,
    templates,
    locations,
    equipments,
    templateDates,
    templateTimes,
    lang,
    datetimeDiff,
    user
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators({ fetchGetEventInterestedUsers }, dispatch);
  return { ...actions, dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventInterestedUsers);
