export const Images = {
  // buttons
  back: require("./images/Back.png"),
  home: require("./images/HomeLogo.png"),

  // default avatar
  avatar: require("./images/default-avatar.png"),
  avatarF: require("./images/default-avatarF.png"),

  splash: require("./splash.png"),
  logo: require("./images/logo.png"),

  icon: require("./images/icon_notif.png"),

  chat:
    "https://storage.googleapis.com/digihapi/HapiColibri/Home/photo-1466248597128-17a2d5f14664.jpeg",

  // Swiper images
  building:
    "https://storage.googleapis.com/digihapi/HapiColibri/Home/home_bg.jpeg",
  sharelife:
    "https://storage.googleapis.com/digihapi/InnoVilleAge/Home/bruce-mars-4Vx3suOu0wo-unsplash.jpeg",
  help:
    "https://storage.googleapis.com/digihapi/HapiColibri/Home/annie-spratt-9VpI3gQ1iUo-unsplash.jpeg",
  vegetables:
    "https://storage.googleapis.com/digihapi/HapiColibri/Home/chantal-garnier-910GanwBoew-unsplash.jpeg",
  tools:
    "https://storage.googleapis.com/digihapi/HapiColibri/Home/Visseuse.jpeg",
  jogging:
    "https://storage.googleapis.com/digihapi/HapiColibri/Home/endurance-courir.jpeg",

  // Maintenance
  maintenance: require("./images/maintenance.png"),

  plus: require("./images/ButtonPlus.png"),

  // Meteo
  "01d": require("./images/01d.png"),
  "01n": require("./images/01n.png"),
  "02d": require("./images/02d.png"),
  "02n": require("./images/02n.png"),
  "03d": require("./images/03d.png"),
  "03n": require("./images/03n.png"),
  "04d": require("./images/04d.png"),
  "04n": require("./images/04n.png"),
  "09d": require("./images/09d.png"),
  "09n": require("./images/09n.png"),
  "10d": require("./images/10d.png"),
  "10n": require("./images/10n.png"),
  "11d": require("./images/11d.png"),
  "11n": require("./images/11n.png"),
  "13d": require("./images/13d.png"),
  "13n": require("./images/13n.png"),
  "50d": require("./images/50d.png"),
  "50n": require("./images/50n.png"),

  FR:
    "https://res.cloudinary.com/ocamping/image/upload/v1554371185/OCamping/Global/Flags/FR.png",
  GB:
    "https://res.cloudinary.com/ocamping/image/upload/v1554371185/OCamping/Global/Flags/GB.png",

  // Activities
  support: require("./images/activities/miscellaneous.png"),
  academic: require("./images/activities/academic.png"),
  dairy: require("./images/activities/dairy.png"),
  city: require("./images/activities/cityscape.png"),
  settings: require("./images/activities/settings.png"),
  technology: require("./images/activities/technology.png"),
  foodprocessor: require("./images/activities/foodprocessor.png"),
  garment: require("./images/activities/garment.png"),
  elegant: require("./images/activities/elegant.png"),
  monitor: require("./images/activities/monitor.png"),
  food: require("./images/activities/food.png"),
  trains: require("./images/activities/trains.png"),
  dishes: require("./images/activities/dishes.png"),
  fruit: require("./images/activities/fruit.png"),
  education: require("./images/activities/education.png"),
  buildings: require("./images/activities/buildings.png"),
  book: require("./images/activities/book.png"),

  cheers: require("./images/activities/cheers.png"),
  restaurant: require("./images/activities/restaurant.png"),
  bbq: require("./images/activities/bbq.png"),
  disco: require("./images/activities/disco.png"),
  picnic: require("./images/activities/picnic-basket.png"),
  icecream: require("./images/activities/ice-cream.png"),
  coffee: require("./images/activities/coffee.png"),

  tennis: require("./images/activities/tennis.png"),
  badminton: require("./images/activities/badminton.png"),
  tabletennis: require("./images/activities/table-tennis.png"),
  petanque: require("./images/activities/petanque.png"),
  golf: require("./images/activities/golf.png"),
  lotus: require("./images/activities/lotus-flower.png"),
  running: require("./images/activities/running.png"),
  hiking: require("./images/activities/hiking.png"),
  climbingrope: require("./images/activities/climbing-with-rope.png"),
  foot: require("./images/activities/soccer-ball-variant.png"),
  basketball: require("./images/activities/basket-ball.png"),
  volley: require("./images/activities/volley-ball.png"),
  roller: require("./images/activities/roller-skate.png"),
  bike: require("./images/activities/bike.png"),
  swim: require("./images/activities/swim.png"),

  boardinggame: require("./images/activities/boarding-game.png"),
  cards: require("./images/activities/cards.png"),
  chess: require("./images/activities/chess.png"),
  gamepad: require("./images/activities/gamepad.png"),
  billiard: require("./images/activities/billiard.png"),
  babyfoot: require("./images/activities/baby-foot.png"),

  beach: require("./images/activities/beach.png"),
  field: require("./images/activities/field.png"),
  basket: require("./images/activities/basket.png"),
  cinema: require("./images/activities/cinema.png"),
  canoe: require("./images/activities/man-in-canoe.png"),
  waterski: require("./images/activities/water-ski.png"),
  paddle: require("./images/activities/paddle.png"),
  pedalo: require("./images/activities/pedalo.png"),
  bowling: require("./images/activities/bowling.png"),
  parachute: require("./images/activities/parachute.png"),
  climbing: require("./images/activities/climbing.png"),
  casino: require("./images/activities/casino.png"),
  museum: require("./images/activities/museum.png"),

  playground: require("./images/activities/playground.png"),
  inflatable: require("./images/activities/inflatable-castle.png"),
  trampoline: require("./images/activities/trampoline.png"),
  swimmingpool: require("./images/activities/swimming-pool.png"),
  kids: require("./images/activities/kids.png"),
  children: require("./images/activities/children.png"),

  // Home view
  profile: require("./images/Profil.png"),
  homesettings: require("./images/Settings.png"),

  // gooddeals
  carousel: require("./images/activities/carousel.png"),
  cart: require("./images/activities/cart.png"),
  bag: require("./images/activities/shopping-bag.png"),
  cap: require("./images/activities/cap.png"),
  camera: require("./images/activities/camera.png"),
  discovery: require("./images/activities/discovery.png"),
  car: require("./images/activities/car.png"),
  jacuzzi: require("./images/activities/jacuzzi.png")
};

export const getImage = name => {
  return Images[name];
};

export const getImageUri = name => {
  return { uri: Images[name] };
};
