import React, { Component } from "react";
import { Image, Platform, Text, TouchableOpacity, View } from "react-native";
import { Image as ImageCache } from "react-native-expo-image-cache";
import { Avatar } from "react-native-elements";
import { withNavigation } from "@react-navigation/compat";
import commonStyles from "../styles/commonStyles";
import { GROUP_ID_ANSWERS } from "../config/constants";
import { formatDate, formatDateIntl } from "../utils/DateUtils";
import { getImage } from "../assets/Images";

class LiveHeader extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { date, refresh } = this.props;
    if ((nextProps.refresh && !refresh) || (!nextProps.refresh && refresh)) {
      return true;
    }
    if (nextProps.date && (!date || date !== nextProps.date)) {
      return true;
    }
    return false;
  }

  render() {
    const {
      color4,
      darkgrey,
      bgLightgrey,
      row,
      flex1,
      alignItemsCenter,
      pr10,
      font,
      fontBold,
      rounded30,
      h60,
      ml5
    } = commonStyles;

    const {
      date,
      pseudo,
      fromId,
      photoUri,
      groupId,
      datetimeDiff,
      lang,
      // countryUri,
      navigation,
      refresh,
      gender
    } = this.props;
    const dateStr =
      groupId === GROUP_ID_ANSWERS
        ? formatDate(date)
        : formatDateIntl(lang, date, datetimeDiff);

    return (
      <View style={[bgLightgrey, row, h60, rounded30, alignItemsCenter, pr10]}>
        {fromId ? (
          <TouchableOpacity
            onPress={() =>
              navigation.navigate("ProfileScreen", {
                userId: fromId
              })
            }
          >
            <Avatar
              rounded
              size={60}
              source={
                photoUri
                  ? { uri: photoUri }
                  : gender === "F"
                  ? getImage("avatarF")
                  : getImage("avatar")
              }
              title={pseudo ? pseudo.toUpperCase().substr(0, 2) : "??"}
              activeOpacity={0.7}
            />
            {/* {Platform.OS === "ios" ? (
              <Image
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  height: 20,
                  width: 20
                }}
                source={{ uri: countryUri }}
              />
            ) : (
              <ImageCache
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  height: 20,
                  width: 20
                }}
                resizeMode="contain"
                {...{ uri: countryUri }}
              />
            )} */}
          </TouchableOpacity>
        ) : (
          <View>
            <Avatar
              rounded
              size={60}
              source={
                photoUri
                  ? { uri: photoUri }
                  : gender === "F"
                  ? getImage("avatarF")
                  : getImage("avatar")
              }
              title={pseudo ? pseudo.toUpperCase().substr(0, 2) : "??"}
              activeOpacity={0.7}
            />
            {/* {Platform.OS === "ios" ? (
              <Image
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  height: 20,
                  width: 20,
                  borderRadius: 10
                }}
                source={{ uri: countryUri }}
              />
            ) : (
              <ImageCache
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  height: 20,
                  width: 20,
                  borderRadius: 10
                }}
                resizeMode="contain"
                {...{ uri: countryUri }}
              />
            )} */}
          </View>
        )}
        <View style={[ml5, flex1]}>
          {fromId ? (
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("ProfileScreen", {
                  userId: fromId
                })
              }
            >
              <Text style={[darkgrey, fontBold]}>{pseudo}</Text>
            </TouchableOpacity>
          ) : (
            <Text style={[darkgrey, font]}>{pseudo}</Text>
          )}

          <Text refresh={refresh} style={[darkgrey, font]}>
            {dateStr}
          </Text>
        </View>
      </View>
    );
  }
}

export default withNavigation(LiveHeader);
