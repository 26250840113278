import Constants from "expo-constants";
import React, { useEffect } from "react";
import { View } from "react-native";
import {
  CLEAR_SHARE,
  CLEAR_SHARE_POST,
  CLEAR_SHARE_WEATHER
} from "../actions/types";
import { t, getTranslatedProperty } from "./i18n";
import { formatDate } from "../utils/DateUtils";
import { BUSINESS_NAME } from "../config/constants";
import { useDispatch, useSelector } from "react-redux";
import { Branch } from "../../BranchIO";

export default function DeepLinkManagement() {
  const dispatch = useDispatch();

  const { goodDeal, share } = useSelector(
    state => state.goodDealDetailsReducer
  );
  const { event, share: sharePost } = useSelector(
    state => state.eventInterestedUsersReducer
  );
  const { shareWeather } = useSelector(state => state.meteoReducer);
  const { communityDetails: community } = useSelector(
    state => state.communityReducer
  );
  const { goodDealsCategories: categories } = useSelector(
    state => state.entitiesReducer
  );

  useEffect(() => {
    async function createGoodDealBranchUniversalObject() {
      try {
        if (Branch) {
          const branchUniversalObject = await Branch.createBranchUniversalObject(
            `gooddeal_${goodDeal._id}`,
            {
              title: getTranslatedProperty(goodDeal, "title"),
              contentImageUrl: goodDeal.photoUri,
              contentDescription: getTranslatedProperty(
                goodDeal,
                "description"
              ),
              // This metadata can be used to easily navigate back to this screen
              // when implementing deep linking with `Branch.subscribe`.
              contentMetadata: {
                customMetadata: {
                  screen: "GoodDealDetailsScreen",
                  params: JSON.stringify({
                    id: goodDeal._id,
                    title: getTranslatedProperty(
                      categories[goodDeal.categoryId],
                      "name"
                    )
                  })
                }
              }
            }
          );
          const shareOptions = {
            messageHeader: getTranslatedProperty(goodDeal, "title"),
            messageBody: t("infos:sharemsg")
          };
          await branchUniversalObject.showShareSheet(shareOptions);
        }
      } catch (e) {
        console.error(e);
      }
    }
    if (share) {
      if (Constants.appOwnership === "standalone") {
        createGoodDealBranchUniversalObject();
      }
      dispatch({
        type: CLEAR_SHARE
      });
    }
  }, [share]);

  useEffect(() => {
    async function createWeatherBranchUniversalObject(shareWeather) {
      const { event, photoUri, _id } = shareWeather;
      const { name, postMessage } = event;

      try {
        if (Branch) {
          let title = `${t("menu:weather")} ${name} - ${BUSINESS_NAME} ${
            community.name
          }`;
          const branchUniversalObject = await Branch.createBranchUniversalObject(
            `weather_${_id}`,
            {
              title,
              contentImageUrl: photoUri,
              contentDescription: `${postMessage}`,
              // This metadata can be used to easily navigate back to this screen
              // when implementing deep linking with `Branch.subscribe`.
              contentMetadata: {
                customMetadata: {
                  screen: "MeteoScreen"
                }
              }
            }
          );
          const shareOptions = {
            messageHeader: title,
            messageBody: t("infos:shareweather")
          };
          await branchUniversalObject.showShareSheet(shareOptions);
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (shareWeather) {
      if (Constants.appOwnership === "standalone") {
        createWeatherBranchUniversalObject(shareWeather);
      }
      dispatch({
        type: CLEAR_SHARE_WEATHER
      });
    }
  }, [shareWeather]);

  useEffect(() => {
    async function createPostBranchUniversalObject() {
      const {
        isEvent = false,
        eventId,
        date,
        title: title_fr,
        photoUri
      } = event;

      try {
        if (Branch && eventId) {
          let title = t("live:postof") + formatDate(date);
          if (title_fr) {
            title = getTranslatedProperty(event, "title");
          }
          const branchUniversalObject = await Branch.createBranchUniversalObject(
            `post_${eventId}`,
            {
              title,
              contentImageUrl: photoUri,
              contentDescription: getTranslatedProperty(event, "description"),
              // This metadata can be used to easily navigate back to this screen
              // when implementing deep linking with `Branch.subscribe`.
              contentMetadata: {
                customMetadata: {
                  screen: isEvent
                    ? "EventDetailsScreen"
                    : "EventInterestedUsersScreen",
                  params: JSON.stringify({ id: eventId })
                }
              }
            }
          );
          const shareOptions = {
            messageHeader: title,
            messageBody: t("infos:sharepost")
          };
          await branchUniversalObject.showShareSheet(shareOptions);
        }
      } catch (e) {
        console.error(e);
      }
    }
    if (sharePost) {
      if (Constants.appOwnership === "standalone") {
        createPostBranchUniversalObject();
      }
      dispatch({
        type: CLEAR_SHARE_POST
      });
    }
  }, [sharePost]);

  // Empty view
  return <View />;
}
