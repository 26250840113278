import moment from "moment";
import React, { Component } from "react";
import {
  ActivityIndicator,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  View
} from "react-native";
import { connect } from "react-redux";
import { fetchGetCurrentMeteo, fetchGetForecastMeteo } from "../actions/meteo";
import { getImage } from "../assets/Images";
import { getTranslatedProperty, t } from "../services/i18n";
import commonStyles, { COLOR2 } from "../styles/commonStyles";

class Meteo extends Component {
  getMeteo = () => {
    this.props.fetchGetForecastMeteo();
    this.props.fetchGetCurrentMeteo();
  };

  componentDidUpdate(prevProps, prevState) {
    const { reload } = this.props;

    if (reload != prevProps.reload) {
      this.getMeteo();
    }
  }

  render() {
    const {
      bgColor1,
      row,
      justifyContentCenter,
      alignItemsCenter,
      spaceBetween,
      spaceAround,
      fs20,
      mb20,
      flex1,
      borderBottomBlack,
      mb10,
      mv20,
      mb5,
      p20,
      pv20,
      textCenter,
      font,
      fontBold,
      wrap,
      bgWhite,
      shadowGrey
    } = commonStyles;
    const { temperature } = styles;
    let myMeteoNow = [];
    let myMeteo = [];
    let myMeteoForecast = [];
    const { meteo, meteoNow, isFetching, isFetchingNow } = this.props;

    if (meteoNow) {
      let imageSource = getImage(meteoNow.weather[0].icon);
      myMeteoNow.push(
        <View key="now" style={alignItemsCenter}>
          <Text style={[fs20, fontBold, mb5]}>{t("weather:now")}</Text>
          <Text style={[mb10, temperature]}>
            {Math.round(meteoNow.main.temp) + "ºC"}
          </Text>
          <Image source={imageSource} style={[styles.imageSize1]} />
          <Text style={[font, wrap, { fontSize: 16 }, textCenter, mb20]}>
            {getTranslatedProperty(meteoNow.weather[0], "description")}
          </Text>
        </View>
      );
    }

    if (meteo && meteo.length >= 5) {
      for (let i = 0; i < 5; i++) {
        let imageSource = getImage(meteo[i].date[0].icon12h);

        if (i === 0) {
          myMeteo.push(
            <View key="meteo9" style={[flex1, alignItemsCenter]}>
              <Text style={temperature}>9h</Text>
              <Text style={mb10}>{meteo[i].date[0].tempMain09 + "ºC"}</Text>
              <Image
                source={getImage(meteo[i].date[0].icon09h)}
                style={styles.imageSize2}
              />
              <Text
                style={[font, wrap, { fontSize: 11 }, textCenter]}
                numberOfLines={2}
              >
                {getTranslatedProperty(meteo[i].date[0], "description09h")}
              </Text>
            </View>
          );
          myMeteo.push(
            <View key="meteo15" style={[flex1, alignItemsCenter]}>
              <Text style={temperature}>15h</Text>
              <Text style={mb10}>{meteo[i].date[0].tempMain15 + "ºC"}</Text>
              <Image
                source={getImage(meteo[i].date[0].icon15h)}
                style={styles.imageSize2}
              />
              <Text
                style={[font, wrap, { fontSize: 11 }, textCenter]}
                numberOfLines={2}
              >
                {getTranslatedProperty(meteo[i].date[0], "description15h")}
              </Text>
            </View>
          );
          myMeteo.push(
            <View key="meteo18" style={[flex1, alignItemsCenter]}>
              <Text style={temperature}>18h</Text>
              <Text style={mb10}>{meteo[i].date[0].tempMain18 + "ºC"}</Text>
              <Image
                source={getImage(meteo[i].date[0].icon18h)}
                style={styles.imageSize2}
              />
              <Text
                style={[font, wrap, { fontSize: 11 }, textCenter]}
                numberOfLines={2}
              >
                {getTranslatedProperty(meteo[i].date[0], "description18h")}
              </Text>
            </View>
          );
        } else {
          myMeteoForecast.push(
            <View key={i} style={alignItemsCenter}>
              <View style={row}>
                <Text style={styles.fontSizeForecast}>
                  {meteo[i].date[0].tempMin + " - "}
                </Text>
                <Text style={styles.fontSizeForecast}>
                  {meteo[i].date[0].tempMax}
                </Text>
              </View>
              <Image
                source={imageSource}
                style={[styles.imageSize3, styles.mt10]}
              />
              <Text>{moment(meteo[i].date[0].date).format("dd")}</Text>
            </View>
          );
        }
      }
    }

    return (
      <View style={[bgColor1, flex1, spaceBetween]}>
        <ScrollView>
          <View style={[bgWhite, shadowGrey, mv20, pv20]}>
            <View style={[alignItemsCenter, borderBottomBlack]}>
              {isFetchingNow ? (
                <ActivityIndicator
                  style={[flex1, justifyContentCenter]}
                  size="small"
                  color={COLOR2}
                />
              ) : (
                myMeteoNow
              )}
            </View>
            <View
              style={[row, spaceAround, styles.h150, bgWhite, alignItemsCenter]}
            >
              {isFetching ? (
                <ActivityIndicator
                  style={[flex1, justifyContentCenter]}
                  size="small"
                  color={COLOR2}
                />
              ) : (
                myMeteo
              )}
            </View>
          </View>
          <View style={[bgWhite, shadowGrey, mb20, p20]}>
            <Text
              style={[
                font,
                styles.fontSize,
                styles.mt10,
                styles.ph10,
                textCenter,
                mb10
              ]}
            >
              {t("weather:forecasts")}
            </Text>
            <View
              style={[row, alignItemsCenter, spaceAround, styles.imageHeight3]}
            >
              {isFetching ? (
                <ActivityIndicator
                  style={[flex1, justifyContentCenter]}
                  size="small"
                  color={COLOR2}
                />
              ) : (
                myMeteoForecast
              )}
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = state => {
  const {
    meteo,
    meteoNow,
    isFetching,
    isFetchingNow,
    reload
  } = state.meteoReducer;

  return {
    meteo,
    meteoNow,
    reload,
    isFetching,
    isFetchingNow
  };
};

export default connect(mapStateToProps, {
  fetchGetForecastMeteo,
  fetchGetCurrentMeteo
})(Meteo);

const styles = StyleSheet.create({
  imageSize1: {
    width: 140,
    height: 140
  },
  imageSize2: {
    width: 60,
    height: 60
  },
  imageSize3: {
    width: 28,
    height: 28
  },
  imageHeight3: {
    height: 80
  },
  fontSizeForecast: {
    fontSize: 12
  },
  fontSize: {
    fontSize: 16
  },
  temperature: {
    fontSize: 16
  },
  h150: {
    height: 150
  },
  mt10: {
    marginTop: 10
  },
  ph10: {
    paddingHorizontal: 10
  }
});
