import {
  API_START,
  API_END,
  FETCH_PUBLISH_ACTIVITY_REQUEST,
  SET_PUBLISH_RESULT,
  SET_COMMUNITY_DETAIL,
  SET_TEMPLATE_TIMES,
  SET_TEMPLATE_DATES,
  SET_PUBLISH_TEMPLATE,
  SET_PUBLISH_GOODDEAL_TEMPLATE,
  RESET_DATA,
  SET_PUBLISH_DATE,
  SET_PUBLISH_TIME,
  SET_PUBLISH_EQUIPMENT,
  SET_PUBLISH_CHOICE,
  SET_PROFILE_DETAILS,
  CLEAR_PUBLISH,
  SET_ON_CLICK_PREVIEW,
  TOGGLE_DISPLAY_PREVIEW
} from "../actions/types";
import { GROUP_ID_GOODDEAL_ACTIVITY } from "../config/constants";

const initialState = {
  isFetching: false,
  data: null,
  template: null,
  dateId: null,
  timeId: null,
  equipmentId: null,
  locationId: null,
  choiceId: null,
  groupId: null,
  goodDealId: null,
  templateDates: {},
  templateTimes: {},
  equipments: {},
  displayTimes: false,
  user: null,
  displayPreview: true,
  onClickPreview: null
};

function keepState(state) {
  return {
    ...initialState,
    templateDates: state.templateDates,
    templateTimes: state.templateTimes,
    equipments: state.equipments,
    user: state.user
  };
}

export default function templateReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_PUBLISH_ACTIVITY_REQUEST) {
        return {
          ...state,
          isFetching: true,
          data: null
        };
      }
      break;

    case RESET_DATA:
      return {
        ...initialState
      };

    case CLEAR_PUBLISH:
      return keepState(state);

    case SET_ON_CLICK_PREVIEW:
      return {
        ...state,
        onClickPreview: action.payload
      };

    case TOGGLE_DISPLAY_PREVIEW:
      return {
        ...state,
        displayPreview: !state.displayPreview
      };

    case SET_PROFILE_DETAILS:
      return {
        ...state,
        user: action.payload
      };

    case SET_PUBLISH_DATE: {
      const displayTimes = Boolean(
        action.payload &&
          state.templateDates[action.payload].name !== "Maintenant" &&
          state.templateDates[action.payload].name !== "À définir ensemble"
      );
      return {
        ...state,
        dateId: action.payload,
        timeId: displayTimes
          ? state.timeId
            ? state.timeId
            : Object.keys(state.templateTimes)[0]
          : null,
        displayTimes
      };
    }

    case SET_PUBLISH_TIME:
      return {
        ...state,
        timeId: action.payload
      };

    case SET_PUBLISH_EQUIPMENT:
      let locationId = null;
      if (
        action.payload &&
        state.equipments[action.payload].name === "À mon emplacement" &&
        state.user.lastBooking
      ) {
        locationId = state.user.lastBooking.locationId;
      }
      return {
        ...state,
        equipmentId: action.payload,
        locationId
      };

    case SET_PUBLISH_CHOICE:
      return {
        ...state,
        choiceId: action.payload
      };

    case SET_TEMPLATE_DATES:
      return {
        ...state,
        templateDates: action.entities.templateDates
      };

    case SET_TEMPLATE_TIMES:
      return {
        ...state,
        templateTimes: action.entities.templateTimes
      };

    case SET_COMMUNITY_DETAIL:
      return {
        ...state,
        equipments: action.entities.equipments
      };

    case SET_PUBLISH_GOODDEAL_TEMPLATE: {
      if (action.template) {
        const { template, choiceId, goodDealId } = action;
        const { dateId, timeId, equipmentId } = template;
        const displayTimes = Boolean(
          dateId &&
            state.templateDates[dateId].name !== "Maintenant" &&
            state.templateDates[dateId].name !== "À définir ensemble"
        );
        let locationId = null;
        if (
          equipmentId &&
          state.equipments[equipmentId].name === "À mon emplacement" &&
          state.user.lastBooking
        ) {
          locationId = state.user.lastBooking.locationId;
        }
        return {
          ...state,
          template,
          groupId: GROUP_ID_GOODDEAL_ACTIVITY,
          dateId,
          timeId,
          equipmentId,
          displayTimes,
          locationId,
          choiceId,
          goodDealId
        };
      }
      break;
    }

    case SET_PUBLISH_TEMPLATE: {
      if (action.payload) {
        const template = action.payload;
        const { dateId, timeId, equipmentId, choices } = template;
        const displayTimes = Boolean(
          dateId &&
            state.templateDates[dateId].name !== "Maintenant" &&
            state.templateDates[dateId].name !== "À définir ensemble"
        );
        let locationId = null;
        if (
          equipmentId &&
          state.equipments[equipmentId] &&
          state.equipments[equipmentId].name === "À mon emplacement" &&
          state.user.lastBooking
        ) {
          locationId = state.user.lastBooking.locationId;
        }
        let choiceId = null;
        if (choices) {
          choiceId = Object.keys(choices)[0];
        }
        return {
          ...state,
          template,
          groupId: template.type.toLowerCase(),
          dateId,
          timeId,
          equipmentId,
          displayTimes,
          locationId,
          choiceId
        };
      }
      break;
    }

    case SET_PUBLISH_RESULT:
      return {
        ...keepState(state),
        data: action.payload
      };

    case API_END:
      if (action.payload === FETCH_PUBLISH_ACTIVITY_REQUEST) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
}
