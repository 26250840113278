import {
  SET_COMMUNITY_DETAIL,
  SET_COMMUNITY_DETAIL_FAILURE,
  FETCH_GET_COMMUNITY_DETAIL
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";
import { COMMUNITIES, COMMUNITY } from "../config/constants";

const equipment = new schema.Entity(
  "equipments",
  {},
  {
    idAttribute: "_id"
  }
);

export const community = new schema.Entity(
  "community",
  {
    equipmentsIds: [equipment]
  },
  {
    idAttribute: "_id"
  }
);

export function fetchGetCummunityDetail() {
  return apiAction({
    url: `/${COMMUNITIES}/${COMMUNITY}`,
    method: "GET",
    schema: community,
    onSuccess: setCommunityDetail,
    onFailure: setCommunityDetailFailure,
    label: FETCH_GET_COMMUNITY_DETAIL
  });
}

function setCommunityDetail(normalized) {
  return {
    type: SET_COMMUNITY_DETAIL,
    // payload: data
    entities: normalized.entities
  };
}

function setCommunityDetailFailure(error) {
  return {
    type: SET_COMMUNITY_DETAIL_FAILURE,
    payload: error
  };
}
