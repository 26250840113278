import React, { useEffect } from "react";
import { Image } from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import { fetchCheckVersionAuthenticated } from "../actions";
import { SET_COMMUNITY_ID, SET_CITY_ID } from "../actions/types";
import { useDispatch, useSelector } from "react-redux";
import { getImage } from "../assets/Images";
import commonStyles from "../styles/commonStyles";

export default function LoadingScreen(props) {
  const dispatch = useDispatch();

  const { serverVersion } = useSelector(
    state => state.globalInformationsReducer
  );

  useEffect(() => {
    AsyncStorage.getItem("cityId").then(cityId => {
      if (cityId) {
        dispatch({
          type: SET_CITY_ID,
          value: cityId
        });
      }
    });
    AsyncStorage.getItem("communityId").then(communityId => {
      if (communityId) {
        dispatch({
          type: SET_COMMUNITY_ID,
          value: communityId
        });
      }
    });
    if (serverVersion) {
      // Workaround: wait a while before checking authentication
      // if we logout and call fetchCheckVersionAuthenticated quickly, it will reuse the session
      setTimeout(() => dispatch(fetchCheckVersionAuthenticated(false)), 500);
    } else {
      dispatch(fetchCheckVersionAuthenticated(false));
    }
  }, []);

  const { cover, w100p, h100p } = commonStyles;
  return <Image style={[cover, w100p, h100p]} source={getImage("splash")} />;
}
