import Constants from "expo-constants";
import React, { Component } from "react";
// Workaround use react-native Image, react-native-expo-image-cache crashed in case of ios
import {
  View,
  Text,
  Image,
  StyleSheet,
  ScrollView,
  ActivityIndicator
} from "react-native";
import commonStyles, {
  COLOR2,
  SCREEN_WIDTH,
  ORANGE_COLOR,
  COLOR5
} from "../styles/commonStyles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchGetGoodDealDetails } from "../actions/gooddeal";
import { t, getTranslatedProperty } from "../services/i18n";
import MapRoute from "./MapRoute";
import ActionButton from "../components/actionButton/ActionButton";
import {
  FontAwesome5,
  MaterialCommunityIcons,
  MaterialIcons
} from "@expo/vector-icons";
import {
  SHARE,
  SET_PUBLISH_GOODDEAL_TEMPLATE,
  TYPE_GOODDEAL_ACTIVITY
} from "../actions/types";
import { openBrowser, openURL } from "../utils/UrlUtil";
import { getActivities } from "../selectors";
import { Button } from "react-native-elements";

class GoodDealDetails extends Component {
  state = {
    imageWidth: 0,
    imageHeight: 0,
    goodDeal: null,
    path: null,
    menuOpen: false
  };
  componentDidMount() {
    if (this.props.route.params) {
      this.props.fetchGetGoodDealDetails(this.props.route.params.id);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { goodDeal } = this.props;

    if (goodDeal && prevProps.goodDeal === null) {
      Image.getSize(goodDeal.photoUri, (width, height) => {
        let proportion = height / width;
        let imageWidth = SCREEN_WIDTH;
        let imageHeight = Math.ceil(imageWidth * proportion);
        this.setState({ imageWidth, imageHeight });
      });
    }
  }

  instantSharing(templates, goodDeal, dispatch, navigation) {
    const template = Object.assign({}, templates[goodDeal.templateId]);
    if (template && Object.keys(template).length) {
      template.choices = goodDeal.choices;
      template.type = TYPE_GOODDEAL_ACTIVITY;
      template.photoUri = goodDeal.photoUri;
      template.name = goodDeal.title;
      template.description = goodDeal.activityDescription;
      template.postDescription = goodDeal.activityPostDescription;
      dispatch({
        type: SET_PUBLISH_GOODDEAL_TEMPLATE,
        template,
        goodDealId: goodDeal._id,
        choiceId:
          template.choices && Object.keys(template.choices).length > 0
            ? Object.keys(template.choices)[0]
            : null
      });
      navigation.navigate("SelectTemplateDateScreen", {
        id: template._id,
        name: getTranslatedProperty(template, "name")
      });
    }
  }

  render() {
    const {
      bgColor1,
      bgColor2,
      row,
      rowReverse,
      flex0,
      flex1,
      white,
      justifyContentCenter,
      alignItemsCenter,
      mb20,
      mt20,
      mv20,
      p20,
      ph20,
      ph10,
      h100,
      font,
      fontBold,
      h44,
      rounded22,
      fs16
    } = commonStyles;

    const { imageWidth, imageHeight, menuOpen } = this.state;
    const {
      dispatch,
      navigation,
      goodDeal,
      lang,
      isFetching,
      templates
    } = this.props;

    return (
      <View style={[flex1, bgColor1]}>
        {isFetching || !imageWidth ? (
          <ActivityIndicator
            style={[flex1, justifyContentCenter]}
            size="large"
            color={COLOR2}
          />
        ) : (
          <ScrollView
            style={[flex1, bgColor1]}
            contentContainerStyle={{
              paddingBottom: Constants.statusBarHeight + 70
            }}
            // stickyHeaderIndices={[0]}
            showsVerticalScrollIndicator={false}
          >
            <Image
              source={{ uri: goodDeal.photoUri }}
              style={[
                {
                  width: imageWidth,
                  height: imageHeight,
                  marginTop: 5
                }
              ]}
            />
            <View style={styles.titleView}>
              <View style={styles.promotionAndTitle}>
                <Text style={[styles.title, font]}>
                  {getTranslatedProperty(goodDeal, "title")}
                </Text>
                {Boolean(goodDeal.promotion) && (
                  <View style={rowReverse}>
                    <View style={[styles.promotionView]}>
                      <Text style={[styles.promotionText, font]}>
                        {getTranslatedProperty(goodDeal, "promotion")}
                      </Text>
                      <View style={[flex1, bgColor1]} />
                    </View>
                  </View>
                )}
              </View>
            </View>
            <Text style={[styles.description, font, mb20]}>
              {getTranslatedProperty(goodDeal, "description")}
            </Text>
            <View style={[flex1, row, p20]}>
              <View style={[bgColor2, { width: 30, borderRadius: 15 }]} />
              <View
                style={[ph10, { paddingTop: 10, paddingBottom: 30 }, flex1]}
              >
                {Boolean(
                  goodDeal &&
                    goodDeal.openingTime &&
                    goodDeal.openingTime.trim().length > 0
                ) && (
                  <View style={[mt20]}>
                    <View style={[{ position: "absolute", left: -35 }]}>
                      <MaterialCommunityIcons
                        name="calendar-clock"
                        size={20}
                        color="white"
                      />
                    </View>
                    <Text style={[font]}>{goodDeal.openingTime}</Text>
                  </View>
                )}
                {Boolean(
                  goodDeal && goodDeal.price && goodDeal.price.trim().length > 0
                ) && (
                  <View style={[mt20]}>
                    <View style={[{ position: "absolute", left: -35 }]}>
                      <MaterialIcons
                        name="euro-symbol"
                        size={20}
                        color="white"
                      />
                    </View>
                    <Text style={[font]}>{goodDeal.price}</Text>
                  </View>
                )}
                {Boolean(
                  goodDeal &&
                    (goodDeal.address || goodDeal.zipCode || goodDeal.city)
                ) && (
                  <View style={[mt20]}>
                    <View style={[{ position: "absolute", left: -35 }]}>
                      <MaterialIcons name="place" size={20} color="white" />
                    </View>
                    {Boolean(goodDeal.address) && (
                      <Text
                        style={[font]}
                        onPress={() =>
                          goodDeal.latitude &&
                          goodDeal.longitude &&
                          this.refs.mapRoute.setVisibleTrue()
                        }
                      >
                        {goodDeal.address}
                      </Text>
                    )}
                    <Text
                      style={[font]}
                      onPress={() =>
                        goodDeal.latitude &&
                        goodDeal.longitude &&
                        this.refs.mapRoute.setVisibleTrue()
                      }
                    >
                      {goodDeal.zipCode} {goodDeal.city}
                    </Text>
                  </View>
                )}
                {Boolean(goodDeal && goodDeal.phone) && (
                  <View style={[mt20]}>
                    <View style={[{ position: "absolute", left: -35 }]}>
                      <MaterialCommunityIcons
                        name="phone"
                        size={20}
                        color="white"
                      />
                    </View>
                    <Text
                      style={[font]}
                      onPress={() =>
                        openURL(dispatch, "telprompt:" + goodDeal.phone)
                      }
                    >
                      {goodDeal.phone}
                    </Text>
                  </View>
                )}
                {Boolean(
                  goodDeal &&
                    goodDeal.webSiteUrl &&
                    goodDeal.webSiteUrl.trim().length > 0
                ) && (
                  <View style={[mt20]}>
                    <View style={[{ position: "absolute", left: -35 }]}>
                      <MaterialCommunityIcons
                        name="web"
                        size={20}
                        color="white"
                      />
                    </View>
                    <Text
                      style={[font]}
                      onPress={() => openBrowser(dispatch, goodDeal.webSiteUrl)}
                    >
                      {goodDeal.webSiteUrl}
                    </Text>
                  </View>
                )}
                {Boolean(
                  goodDeal && goodDeal.fbUrl && goodDeal.fbUrl.trim().length > 0
                ) && (
                  <View style={[mt20]}>
                    <View style={[{ position: "absolute", left: -30 }]}>
                      <FontAwesome5 name="facebook-f" size={20} color="white" />
                    </View>
                    <Text
                      style={[font]}
                      onPress={() => openBrowser(dispatch, goodDeal.fbUrl)}
                    >
                      {goodDeal.fbUrl}
                    </Text>
                  </View>
                )}
                {Boolean(
                  goodDeal && goodDeal.email && goodDeal.email.trim().length > 0
                ) && (
                  <View style={[mt20]}>
                    <View style={[{ position: "absolute", left: -35 }]}>
                      <FontAwesome5 name="at" size={20} color="white" />
                    </View>
                    <Text
                      style={[font]}
                      onPress={() =>
                        openURL(dispatch, "mailto:" + goodDeal.email)
                      }
                    >
                      {goodDeal.email}
                    </Text>
                  </View>
                )}
              </View>
            </View>
            {goodDeal.templateId && (
              <Button
                icon={
                  <MaterialCommunityIcons
                    name="heart-multiple"
                    size={24}
                    color="white"
                  />
                }
                buttonStyle={[bgColor2, h44, rounded22, ph20]}
                containerStyle={[mv20, alignItemsCenter]}
                activeOpacity={0.8}
                title={t("button:instantsharing")}
                onPress={() => {
                  this.instantSharing(
                    templates,
                    goodDeal,
                    dispatch,
                    navigation
                  );
                }}
                titleStyle={[white, fontBold, { paddingLeft: 5, fontSize: 16 }]}
              />
            )}
            {Boolean(goodDeal.latitude && goodDeal.longitude) && (
              <MapRoute
                ref={"mapRoute"}
                latitude={goodDeal.latitude}
                longitude={goodDeal.longitude}
                title={getTranslatedProperty(goodDeal, "title")}
                description={getTranslatedProperty(goodDeal, "description")}
                lang={lang}
              />
            )}
          </ScrollView>
        )}
        <ActionButton
          buttonColor={menuOpen ? COLOR5 : COLOR2}
          degrees={90}
          renderIcon={() => (
            <MaterialCommunityIcons name="more" size={40} color="white" />
          )}
          onPressIn={() => this.setState({ menuOpen: true })}
          onReset={() => this.setState({ menuOpen: false })}
        >
          {Boolean(goodDeal && goodDeal.templateId) && (
            <ActionButton.Item
              buttonColor={COLOR2}
              title={t("button:instantsharing")}
              onPress={() => {
                this.instantSharing(templates, goodDeal, dispatch, navigation);
              }}
              textStyle={[fontBold, fs16]}
            >
              <MaterialCommunityIcons
                name="heart-multiple"
                size={34}
                color="white"
              />
            </ActionButton.Item>
          )}
          {Boolean(goodDeal && goodDeal.latitude && goodDeal.longitude) && (
            <ActionButton.Item
              buttonColor={COLOR2}
              title={t("mapRoute:dialogTitle")}
              onPress={() => this.refs.mapRoute.setVisibleTrue()}
              textStyle={[fontBold, fs16]}
            >
              <FontAwesome5 name="route" size={34} color="white" />
            </ActionButton.Item>
          )}
          {Boolean(
            goodDeal && goodDeal.email && goodDeal.email.trim().length > 0
          ) && (
            <ActionButton.Item
              buttonColor={COLOR2}
              title={t("button:mailto")}
              onPress={() => openURL(dispatch, "mailto:" + goodDeal.email)}
              textStyle={[fontBold, fs16]}
            >
              <FontAwesome5 name="at" size={34} color="white" />
            </ActionButton.Item>
          )}
          {Boolean(
            goodDeal && goodDeal.fbUrl && goodDeal.fbUrl.trim().length > 0
          ) && (
            <ActionButton.Item
              buttonColor={COLOR2}
              title={t("button:seefb")}
              onPress={() => openBrowser(dispatch, goodDeal.fbUrl)}
              textStyle={[fontBold, fs16]}
            >
              <FontAwesome5 name="facebook-f" size={34} color="white" />
            </ActionButton.Item>
          )}
          {Boolean(
            goodDeal &&
              goodDeal.webSiteUrl &&
              goodDeal.webSiteUrl.trim().length > 0
          ) && (
            <ActionButton.Item
              buttonColor={COLOR2}
              title={t("button:seeweb")}
              onPress={() => openBrowser(dispatch, goodDeal.webSiteUrl)}
              textStyle={[fontBold, fs16]}
            >
              <MaterialCommunityIcons name="web" size={34} color="white" />
            </ActionButton.Item>
          )}
          {Boolean(goodDeal && goodDeal.phone) && (
            <ActionButton.Item
              buttonColor={COLOR2}
              title={`${t("button:call")} ${goodDeal.phone}`}
              onPress={() => openURL(dispatch, "telprompt:" + goodDeal.phone)}
              textStyle={[fontBold, fs16]}
            >
              <MaterialCommunityIcons name="phone" size={40} color="white" />
            </ActionButton.Item>
          )}
        </ActionButton>
      </View>
    );
  }
}

const mapStateToProps = state => {
  const { goodDeal, isFetching } = state.goodDealDetailsReducer;

  return {
    goodDeal,
    isFetching,
    templates: getActivities(state),
    lang: state.globalInformationsReducer.lang
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators({ fetchGetGoodDealDetails }, dispatch);
  return { ...actions, dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoodDealDetails);

const styles = StyleSheet.create({
  title: {
    color: "#444041",
    fontSize: 25
  },
  titleView: {
    width: SCREEN_WIDTH - 40,
    marginRight: "auto",
    marginLeft: "auto"
  },
  promotionAndTitle: {
    marginTop: 10
  },
  promotionView: {
    backgroundColor: ORANGE_COLOR,
    marginTop: 10,
    height: 30,
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10
  },
  promotionText: {
    marginTop: 5,
    color: "#444041",
    fontSize: 16
  },
  description: {
    width: SCREEN_WIDTH - 40,
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 12
  },
  bottomLine: {
    height: 1,
    backgroundColor: "black",
    width: SCREEN_WIDTH - 40,
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 20
  },
  phoneText: {
    width: SCREEN_WIDTH - 40,
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 15
  },
  underlined: {
    textDecorationLine: "underline"
  },
  buttonText: {
    color: "white"
  }
});
